.gridjs {
    padding: 0px !important;
    .gridjs-wrapper {
        border-radius: 0;
        box-shadow: none;
        border: 1px solid var(--td-border-color);
        table.gridjs-table {
            width: 100%;
            table-layout:auto;
            //width: -webkit-fill-available;
            .gridjs-thead {
                th.gridjs-th {
                    //border: 1px solid var(--menu-border-color);
                    padding: 10px 5px;
                    line-height: 17px;
                    position: relative;
                    //border: 1px solid var(--td-border-color);
                    //background-color: transparent;
                    background-color: var(--white-bg);
                    .gridjs-th-content {
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 17px;
                        //letter-spacing: 0.5px;
                        text-transform: capitalize;
                        color: var(--input-label-color);
                        overflow: visible;
                    }

                    &.gridjs-th-sort {
                        padding-right: 19px;
                        .gridjs-th-content {
                            width: calc(100% - 4px);
                        }
                    }

                    button.gridjs-sort {
                        background-color: transparent;
                        background-position-x: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        border: none;
                        cursor: pointer;
                        float: right;
                        height: 24px;
                        margin: 0;
                        outline: none;
                        padding: 0;
                        width: 13px;
                        background-size: 10px;
                        background-position-y: 35%;
                        position: absolute;
                        line-height: 0;
                        right: 3px;

                        &.gridjs-sort-neutral {
                            background-image: url("../../assets/img/sorting-arrow.svg");
                            opacity: 0.7 !important;
                        }

                        &.gridjs-sort-asc {
                            background-image: url("../../assets/img/sorting-arrow.svg");
                            opacity: 1;
                        }
                        &.gridjs-sort-desc {
                            background-image: url("../../assets/img/sorting-arrow-dsc.svg");
                            opacity: 1;
                            transform: rotate(180deg);
                            top: 5px;
                        }
                    }
                }
            }
            td.gridjs-td {
                font-size: 12px;
                padding: 5px 5px;
                color: var(--input-label-color);
                background-color: var(--white-bg);
                border-width: .8px;
                //border: 1px solid var(--td-border-color);
                .text-danger {
                    color: var(--text-danger) !important;
                    font-weight: 600;
                }
                .text-success {
                    color: var(--text-success) !important;
                    font-weight: 600;
                }
                .edit-link {
                    color: var(--primary-link-color);
                    text-decoration: none;
                    cursor: pointer;
                    font-size: 12px;
                    &:hover {
                        text-decoration: underline;
                    }
                    &.text-danger {
                        color: var(--text-danger) !important;
                        font-weight: 600;
                    }
                    &.text-success {
                        color: var(--text-success) !important;
                        font-weight: 600;
                    }
                }
                .dropdown {
                    // &.show {
                    //     .btn-primary {
                    //         border: 0;
                    //         background-color: var(--primary-bg-color);
                    //     }
                    // }
                    .dropdown-menu {
                        background-color: var(--white-bg);
                        border: 1px solid var(--menu-border-color) !important;
                        background-clip: padding-box;
                        border-radius: 3px !important;
                        //box-shadow: 0 16px 18px #8787b61a!important;
                        box-shadow: -1px 1px 10px 0px #e4e6e9;
                        padding: 0;
                        a {
                            position: relative;
                            padding: 7px 10px;
                            display: flex;
                            align-items: center;
                            font-size: 12.5px;
                            text-decoration: none;
                            width: 100%;
                            color: var(--input-label-color);
                            border-top: 1px solid var(--menu-border-color) !important;
                            border-radius: 0;
                            margin: auto !important;
                            &:first-of-type {
                                border-top: 0 !important;
                            }
                            .menu-icon {
                                font-size: 17px;
                                margin-right: 10px;
                                width: 24px;
                                text-align: center;
                                color: var(--input-label-color);
                            }
                            &:hover {
                                background-color: transparent;
                                .menu-icon {
                                    color: var(--primary-bg-color);
                                }
                            }
                        }
                    }
                    .btn {
                        padding: 4px 9px;
                        font-size: 12px;
                        min-width: 60px;
                        border: 0;
                        // &::after {
                        //     content: none !important;
                        // }
                        &:hover,
                        &:focus {
                            outline: none !important;
                            border: 0;
                            box-shadow: none;
                        }
                        svg {
                            fill: var(--primary-bg-color);
                            color: var(--primary-bg-color);
                        }
                    }
                    &.show > .btn-success.dropdown-toggle:focus {
                        outline: none !important;
                        border: none !important;
                        box-shadow: none;
                    }
                }

                .action-with-icon {
                    text-align: center;
                    .btn {
                        background-color: var(--white-bg);
                        color: var(--action-btn-color);
                        min-width: 6px;
                        padding: 0px 3px;
                        border: 1px solid var(--action-btn-border);
                        &:hover {
                            border: 1px solid var(--action-btn-border);
                        }
                        &:focus {
                            background-color: transparent;
                            outline: none;
                            box-shadow: none;
                            border: 1px solid var(--action-btn-border);
                        }
                        &::after {
                            content: none;
                        }
                        svg {
                            fill: var(--action-btn-color);
                            color: var(--action-btn-color);
                        }
                    }
                    &.disabled-action-dropdown {
                        .btn {
                            background-color: var(--border-color-c) !important;
                            color: var(--grey-text-color) !important;
                            border-color: var(--border-color-c) !important;
                            cursor: not-allowed;

                            &:hover {
                                border: 1px solid var(--border-color-c);
                            }
                            &:focus {
                                background-color: var(--border-color-c) !important;
                                color: var(--grey-text-color) !important;
                                border: 1px solid var(--border-color-c);
                            }
                            svg {
                                fill: var(--grey-text-color) !important;
                                color: var(--grey-text-color) !important;
                            }
                            &::after {
                                content: none;
                            }
                        }
                    }
                    &.downlaod-icon {
                        .btn {
                            padding: 0 5px;
                        }
                    }
                }
            }
        }

        // .last-note-tr {
        //     position: relative;
        //     .last-note-td{
        //         &:last-child {
        //             position: absolute;
        //             left: 0;
        //             bottom: 0;
        //             width: 100%;
        //         }
        //     }
        // }
        .last-note-tr {
            position: relative;
            .last-note-td {
                padding-bottom: 36px !important;
                position: static !important;
                .notes-wrap {
                    font-size: 12px;
                    font-style: italic;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    background-color: #f1f1f1;
                    padding: 6px 5px;
                    border-top: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                }

                .notes-wrap-workorder {
                    font-size: 12px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    background-color: var(--note-table-wrapper);
                    padding: 6px 5px;
                    // border-top: 1px solid #ccc;
                    // border-bottom: 1px solid #ccc;
                    .notes-wrap-item {
                        display: flex;
                        column-gap: 20px;
                        margin-top: 5px;

                        span {
                            //background-color: #ccc;
                            padding: 0px;
                            font-style: italic;
                            flex: 0 0 auto;
                            strong {
                                font-style: normal;
                                color: var(--text-dark-color);
                                font-weight: 500;
                            }
                            &.note-span1 {
                                width: auto;
                            }
                            &.note-span2 {
                                //width: 5%;
                                width: auto;
                            }
                            &.note-span3 {
                                // width: 5%;
                                width: auto;
                            }
                            &.note-span4 {
                                flex: 0 1 auto;
                                //flex: 1;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                        }
                        .edit-link {
                            color: var(--primary-link-color) !important;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .rc-time-picker {
            width: 75px;
            .rc-time-picker-input {
                padding: 2px;
                font-size: 12px;
                line-height: 1;
                &[readonly] {
                    background-color: #ccc;
                }
            }
            .rc-time-picker-clear {
                top: 1px;
                right: 0;
            }
            .rc-time-picker-panel-inner {
                top: 27px;
            }
            &.rc-time-picker-not-access-input { 
                .rc-time-picker-input {
                    padding: 2px;
                    font-size: 12px;
                    line-height: 1;
                    background: #fff;
                    color:white!important;
                    border-color: white!important;
                    cursor: pointer;
                    &[readonly] {
                        background-color: #ccc;
                    }
                }
                .rc-time-picker-clear {
                    .rc-time-picker-clear-icon:after {
                        color:white!important;
                    }
                    
                }
            }
            &.rc-time-picker-same-input { 
                .rc-time-picker-input {
                    padding: 2px;
                    font-size: 12px;
                    line-height: 1;
                    background: #ccc;
                    color:#000!important;
                    border-color: #ccc!important;
                    cursor: pointer;
                    &[readonly] {
                        background-color: #ccc;
                    }
                }
            }
            &.rc-time-picker-not-same-input { 
                .rc-time-picker-input {
                    padding: 2px;
                    font-size: 12px;
                    line-height: 1;
                    background: #87c6a7!important;
                    color:#000 !important;
                    border-color: #87c6a7!important;
                    &[readonly] {
                        background-color: #87c6a7!important;
                        color:#000 !important;
                    }
                    &[disabled] {
                        color:#000 !important;
                    }
                }
            }
        }
    }
    .gridjs-footer {
        background-color: transparent;
        border-radius: 0;
        border: 0;
        box-shadow: none;
        padding: 20px 10px 10px;
        .gridjs-pagination {
            font-size: 13px;
            color: var(--input-label-color);

            .gridjs-pages {
                button {
                    padding: 0.5rem 0.75rem;
                    background-color: var(--pagination-bg);
                    border: 1px solid var(--menu-border-color);
                    margin-right: 4px;
                    border-radius: 4px;
                    position: relative;
                    line-height: 1.25;
                    font-weight: 500;
                    width: 45px;
                    height: 35px;
                    color: var(--pagination-color);
                    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
                        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    &:hover {
                        background-color: var(--primary-bg-color);
                        color: var(--text-white);
                    }
                    &[aria-label="Previous"],
                    &[aria-label="Next"] {
                        width: 75px;
                        padding: 8px 5px;
                    }

                    &[disabled] {
                        background-color: transparent;
                        color: var(--grey-text-color) !important;
                        font-weight: 400;
                    }
                    &.gridjs-currentPage {
                        background-color: var(--primary-bg-color);
                        color: var(--text-white);
                    }
                }
            }
        }
    }
}
.custom-table-header {
    display: flex;
    align-items: center;
    padding: 15px 10px;
}

.show-entries {
    display: flex;
    align-items: center;
    label {
        font-size: 13px;
    }
    .form-select {
        padding: 5px 33px 5px 10px;
        font-size: 13px;
        margin: 0 5px;
        display: inline-block;
        width: auto;
        background-color: var(--white-bg);
        color: var(--text-dark-color);
    }
    &.show-sort {
        margin-bottom: 10px;
        .form-select {
            margin-right: 0 !important;
            background-color: var(--white-bg);
            color: var(--text-dark-color);
        }
    }
}

@media only screen and (max-width: 767px) {
    .grid-content {
        background-color: transparent;
        .gridjs {
            .gridjs-wrapper {
                border: 0;
                .gridjs-tbody,
                td.gridjs-td {
                    background-color: transparent;
                }
            }
            .gridjs-footer {
                background-color: var(--white-bg);
                border-top: 1px solid var(--menu-border-color);
                padding: 10px 0;
                .gridjs-pagination {
                    .gridjs-summary {
                        width: 100%;
                        text-align: center;
                        margin-bottom: 10px;
                        margin-top: 0;
                    }
                    .gridjs-pages {
                        width: 100%;
                        text-align: center;
                    }
                }
                .invoice-total-row {
                    padding: 0;
                    border: 0;
                    border: 1px solid #ccc;
                    .cost-col {
                        padding: 0 !important;
                        flex: 1;
                        span {
                            display: block;
                        }
                        &.title {
                            width: 50px;
                            padding: 2px !important;
                            flex: 0 0 auto;
                        }
                    }
                }
            }
        }
    }
    /* Force table to not be like tables anymore */
    #no-more-tables table,
    #no-more-tables thead,
    #no-more-tables tbody,
    #no-more-tables tfoot,
    #no-more-tables th,
    #no-more-tables td,
    #no-more-tables tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    #no-more-tables thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    #no-more-tables tr {
        border: 1px solid #afafaf;
        margin-bottom: 10px;
        border-radius: 3px;
        background-color: var(--white-bg);
        //box-shadow: 1px 1px 7px #9a9acc1a;
        padding: 0px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    #no-more-tables td {
        /* Behave  like a "row" */
        border: none;
        //border-bottom: 1px solid var(--menu-border-color);
        position: relative;
        padding: 7px 10px 0;
        //padding-top: 27px;
        padding-left: 10px;
        //padding-left: 40%;
        white-space: normal;
        text-align: left;
        word-break: break-all;
    }

    #no-more-tables td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 10px;
        left: 10px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: 500;
        font-size: 13px;
    }



    /*
	Label the data
	*/
    #no-more-tables td:before {
        content: attr(data-title);
        text-transform: capitalize;
        display: block;
        width: 100%;
     
    }

    #no-more-tables tfoot th {
        border: none;
    position: relative;
    padding: 7px 10px 0;
    padding-left: 10px;
    white-space: normal;
    text-align: left;
    word-break: break-all;
    }
    #no-more-tables tfoot th:before {
        /* Now like a table header */
        position: absolute;
        
        /* Top/left values mimic padding */
        top: 10px;
        left: 10px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: 500;
        font-size: 13px;
    }

    #no-more-tables tfoot th:before {
        content: attr(data-title);
        text-transform: capitalize;
        display: block;
        width: 100%;
        position: unset;
    }

    .appointment-list.cl-appointment-list {
        .gridjs-wrapper .gridjs-tbody .gridjs-tr .gridjs-td {
            
            &[data-column-id="column-8"] {
                word-break: break-all !important;
                width: 100% !important;
            }
        }
    }
}

// .services-listing {
//     .gridjs-wrapper {
//         .gridjs-tbody {
//             .gridjs-tr {
//                 &:nth-child(1) {
//                     td {
//                         background-color: #50e3c2 !important;
//                     }
//                 }
//                 &:nth-child(2) {
//                     td {
//                         background-color: #4a90e2 !important;
//                     }
//                 }
//                 &:nth-child(3) {
//                     td {
//                         background-color: #f5a623 !important;
//                     }
//                 }
//                 &:nth-child(4) {
//                     td {
//                         background-color: #f8e71c !important;
//                     }
//                 }
//             }
//         }
//     }
// }

@media only screen and (max-width: 441px) {
    .cleaners-table {
        .custom-table-header {
            .filter {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
            .device-table-buttons {
                display: flex;
                justify-content: space-between;
                width: 100%;
                .btn.ms-3 {
                    margin-left: 0 !important;
                    margin-right: 0px !important;
                    flex: 0 0 auto;
                    width: 49%;
                }
            }
            .d-email {
                margin-left: 0 !important;
                width: 100%;
                margin-top: 13px;
                margin-bottom: 13px;
                .form-control {
                    margin-left: 0 !important;
                }
            }
        }
    }

    .gridjs-wrapper {
        .gridjs-tbody {
            .gridjs-tr {
                position: relative;
                .gridjs-td:first-child {
                    padding-right: 47px !important;
                }
                td.gridjs-td:last-child {
                    position: absolute !important;
                    top: 0px;
                    right: 0;
                    padding-top: 20px;
                    &::before {
                        content: none !important;
                    }
                }
            }
        }
    }

    .no-action-column {
        .gridjs-tbody {
            .gridjs-tr {
                td.gridjs-td:last-child {
                    position: relative !important;
                    padding-bottom: 10px !important;
                    &::before {
                        content: attr(data-title) !important;
                    }
                }
            }
        }
    }
}

.data-grid_without_td_bg {
    tr.gridjs-tr {
        td.gridjs-td {
            background-color: inherit !important;
        }
    }
}

@-moz-document url-prefix() {
    .gridjs-table {
        width: 100%;
        .gridjs-td {
            word-break: break-all;
        }
    }
    .inspection-list {
        .gridjs-wrapper {
            th[data-column-id="column-16"] {
                word-break: normal !important;
                width: 7% !important;
                        }
            .gridjs-tbody .gridjs-tr  {
               
                td[data-column-id="column-16"] {
                    word-break: normal !important;
                    width: 7%;
                            }
            }
        }
    
    }

    .appointment-list.cl-appointment-list {
        .gridjs-wrapper .gridjs-thead .gridjs-tr .gridjs-th {
            
            &[data-column-id="column-28"], &[data-column-id="column-29"], &[data-column-id="column-30"] {
                width: 100px !important;
            }
            &[data-column-id="column-31"] {
                width: 35px !important;

            }
        }
    }
}

.appointment-list-icons {
    .tooltip-arrow {
        display: none !important;
    }
    span {
        width: 22px;
        height: 20px;
        border: 1px solid#bfbfbf; //8f8f8f
        background-color: #bfbfbf;
        color: #ffffff;
        align-items: center;
        justify-content: center;
        display: inline-flex;
        border-radius: 4px;
        cursor: pointer;
        margin: 5px 5px 2px 0;
        &.blue {
            border: 1px solid #277DA2; //6852ff
            color:var(--text-white) ;
            background-color:  #277DA2;
         }

        &.red {
            border: 1px solid #F94045; //e54a50
            color: var(--text-white);
            background-color: #F94045;
        }
        &.green {
            border: 1px solid #198754;
            color: #fff;
            background-color: #198754;
        }
        &.light-green {
            border: 1px solid #43AA8C; //#00acac //#359d73
            color: #fff;
            background-color: #43AA8C;
        }
        &.dark-vip-yellow {
            border: 1px solid #ffdb51; //#00acac //#359d73
            color: #fff;
            background-color: #ffdb51;
        }
        &.danger {
            border: 1px solid #F94045; //red
            color: #F94045;
        }
        &.dark-blue {
            border: 1px solid #277DA2; //001ae5
            color: #ffffff;
            background-color: #277DA2;
        }
        &.yellow {
            border: 1px solid #f99719; //dcd455
            color: #ffffff;
            background-color: #f99719;
        }
        &.is-icon {
        background-color: var(--white-bg);
        border:1px solid var(--border-color-c);
        overflow: hidden;
        img {
            max-width: 74%;
        }
        }
    }

    
}

.appointment-list {
    .gridjs.sub-table {
        .gridjs-wrapper {
            table.gridjs-table {
                td.gridjs-td {
                    .edit-link {
                        color: var(--text-dark);
                    }
                }
            }
            .gridjs-th-content {
                overflow: visible;
            }
        }
    }

    .no-default-pagination {
        .gridjs-footer {
            .gridjs-pagination {
                display: none !important;
            }
        }
    }
    .user-id {
        font-size: 12px;
        font-style: italic;
    }

    .apt-icon-btn {
        span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            border-radius: 4px;
            border: 1px solid var(--primary-bg-color);
            color: var(--primary-bg-color);
            font-size: 13px;
            cursor: pointer;
            &:hover {
                background-color: var(--primary-bg-color);
                color: var(--text-white);
            }
        }
    }

    .time-save-btn {
        display: flex;
        align-items: center;
        .btn {
            min-width: 10px;
            padding: 1px 5px !important;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            position: relative;
            left: -3px;
        }
        .rc-time-picker {
            width: 75px;
        }
        .rc-time-picker-input {
            // border-top-right-radius: 0 !important;
            // border-bottom-right-radius: 0 !important;
            font-size: 11px !important;
        }
        .rc-time-picker-clear {
            top: 0 !important;
        }
        .readonly {
            .rc-time-picker-input {
                background-color: #ccc;
            }
        }
    }
}
.appointment-list.cl-appointment-list {
    .gridjs-wrapper .gridjs-tbody .gridjs-tr .gridjs-td {
        padding-left: 5px !important;

        &:first-child {
            position: static !important;
        }
        &[data-column-id="column-8"] {
word-break: break-all;
width: 10%;
        }
    }
}

.cleaner-side-apt-list {
    .appointment-list.cl-appointment-list {
        .gridjs-wrapper .gridjs-tbody .gridjs-tr .gridjs-td {
            padding-left: 5px !important;
        }
    }
    .appointment-list.workorders-list {
        .gridjs.sub-table .gridjs-wrapper .gridjs-tbody .gridjs-tr {
            padding-left: 0px !important;
            .gridjs-td {
                padding-left: 10px !important;
            }
        }
    }
}

.manager-side-apt-list {
    .appointment-list.cl-appointment-list {
        .gridjs-wrapper {
            .gridjs-thead {
                .gridjs-th:first-child {
                    padding: 0px !important;
                }
            }
            .gridjs-tbody .gridjs-tr {
                .gridjs-td:first-child {
                    padding: 0px !important;
                }
            }
        }
    }
}

.appointment-info-modal {
    .appointment-list.responsive-list {
        padding: 0;
    }
}
.appointment-list.manager-appointment-list .gridjs-wrapper .gridjs-tbody .internal-services {
    height: 100%;
}
.characters {
    margin: 0;
    padding: 0;
}
.characters li {
    display: flex;
    align-items: center;
    margin: 0;
}

.inspection-list {
    .content {
        background-color: var(--body-bg-color);
        padding: 0px !important;
        .gridjs .gridjs-wrapper table.gridjs-table .gridjs-thead th.gridjs-th {
            background-color: var(--white-bg);
        }
    }
    .count {
        display: inline-flex !important;
        padding: 4px !important;
        font-size: 11px;
        z-index: 9999;
        border-radius: 50px;
        line-height: 10px;
        text-align: center;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        background-color: #dc3545;
        color: #fff;
        font-weight: 400;
        //height: 37px;
        min-width: 18px;
        margin-left: 5px;
    }
    h6 {
        //background: #d5dde5;
        background-color: var(--white-bg);
        margin: 0;
        .count {
            background-color: #dc3545;
        }
    }
    .insp-btn {
        background-color: #ccc;
        padding: 1px 2px;
        border-radius: 2px;
        margin-left: 5px;
        font-size: 11px;
    }
    .sm-text {
        font-size: 10px;
    }
    .top-section-appointment {
        font-size: 13px;
        background-color: var(--white-bg);
        margin-bottom: 10px;
        padding: 7px;
        .sub-apt {
            margin-right: 50px;
            font-weight: 500;
        }
        .count-p {
            background-color: var(--primary-bg-color) !important;
        }
    }

    .top-section-callback {
        font-size: 13px;
        background-color: #FFCCCC;
        margin-bottom: 10px;
        padding: 7px;
        .sub-apt {
            margin-right: 50px;
            font-weight: 500;
        }
        .count-p {
            background-color: var(--primary-bg-color) !important;
        }
    }

    .no-default-pagination {
        .gridjs-footer {
            .gridjs-pagination {
                display: none !important;
            }
        }
    }
    .gridjs.inspection-table {
        .gridjs-wrapper {
            .gridjs-tbody {
                .gridjs-tr:nth-child(3) td {
                    background-color: #ffcccc;
                }
            }
            .gridjs-th[data-column-id="grid-th-checkbox"] {
                position: absolute;
                top: 0;
                z-index: 1;
                padding: 10px 3px;
                border: 0;
            }
            .gridjs-th[data-column-id="grid-th-checkbox-title"] {
                padding-left: 25px;
            }
        }
    }
}

.grid-total-row {
    .gridjs .gridjs-footer {
        display: flex;
        flex-direction: column;
        padding: 0;

        .total-row {
            padding: 10px;
            display: flex;
            justify-content: end;
            border: 1px solid #e5e7eb;
            border-top: 0;
            background: #fff;
            .cost-col {
                font-size: 12px !important;
                //font-weight: 500;
                margin-right: 10px;
                background-color: #f8f8fc; //ededf5
                border: 1px solid #f8f8fc;
                border-radius: 4px;
                padding: 3px 10px;
                span {
                    font-weight: 500;
                    font-size: 13px !important;
                }
            }
        }

        .gridjs-pagination {
            order: 2;
            padding: 10px;
            //border-top: 1px solid #e5e7eb;
        }
    }
}

.manager-appointment-list {
    .gridjs-wrapper {
        .gridjs-tbody {
            .gridjs-tr td:first-child {
                position: relative;
                padding-left: 25px;
            }

            .internal-services {
                position: absolute;
                left: 0;
                top: 0;
                height: 99%;
                background: #2a72b5;
                width: 15px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 18px;
                z-index: 1;
            }

            .outside-services {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                background: #dbdbdb;
                width: 15px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: #000;
                font-size: 18px;
            }

            .last-note-tr .last-note-td .notes-wrap-workorder {
                padding-left: 28px;
            }
        }
    }
}
.cleaner-appointmnet-list {
    .gridjs-wrapper {
        .gridjs-tbody {
            .gridjs-tr td {
                position: relative;
                .apt-popup-icon {
                    color: #00315f;
                    //background-color: #fff;
                    padding: 1.5px;
                    border-radius: 2px;
                    line-height: 10px;
                    // position: absolute;
                    // right: 3px;
                    // top:3px;
                    font-size: 16px;
                    margin-right: 5px;
                    cursor: pointer;
                    svg {
                        position: relative;
                        top: 0px;
                    }
                }
            }
        }
    }
}

.tr-left {
    .gridjs-wrapper {
        background-color: var(--white-bg);
        padding: 2px 0 5px;
        .gridjs-tbody {
            .gridjs-tr td {
                font-size: 12px !important;
                line-height: 1.2;
            }
            .gridjs-tr td:first-child {
                position: relative;
                padding-left: 25px;
                .internal-services {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    background: #2a72b5;
                    width: 15px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    font-size: 18px;
                    z-index: 1;
                }

                .outside-services {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    background: #dbdbdb;
                    width: 15px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    color: #000;
                    font-size: 18px;
                    z-index: 1;
                }
            }
        }
    }
}

.bg-white {
    background-color: var(--white-bg);
}

.appointment-list.workorders-list {
    .gridjs.sub-table .gridjs-wrapper {
        .gridjs-tbody .gridjs-tr .gridjs-td {
            background-color: var(--white-bg) !important;
        }
        .last-note-tr .last-note-td {
            padding-bottom: 5px !important;
            position: static !important;
        }
    }

    .gridjs .gridjs-footer .total-row {
        padding: 5px 0px;
        .cost-col {
            font-size: 13px !important;
            background-color: transparent;
            span {
                font-size: 13px !important;
            }
        }
    }
}

.assign-staff-list {
    .appointment-list-icons {
        display: flex;
    }
    &.grid-total-row .gridjs .gridjs-footer {
        z-index: -1;
        background-color: var(--white-bg);
        padding: 0 !important;
    }
    .gridjs {
        .select__control {
            min-height: 24px;

            .select__value-container {
                height: auto !important;
            }
            .select__indicator {
                padding: 0px;
            }
            .select__input-container {
                padding: 0px;
                margin: 0;
            }
            .select__placeholder {
                font-size: 12px;
            }
            .select__single-value {
                font-size: 12px;
            }
        }
        .select__menu {
            margin-top: 2px;
            .select__option {
                padding: 2px 8px;
                font-size: 12px;
            }
        }

        .field-updated {
            .select__control {
                border-color: #b5e5b2 !important;
                background-color: #b5e5b2 !important;
            }

            .select__indicator {
                color: #525151;
            }
        }

        .field-not-updated {
            .select__control {
                border-color: #fdd0da !important;
                background-color: #fdd0da !important;  
            }

            .select__indicator {
                color: #525151;
            }
        }
        
    }
}

.edit-link-blue {
    color: var(--primary-link-color);
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.sub-task-table {
    .gridjs-wrapper {
        .gridjs-tbody {
            .gridjs-tr td:nth-child(2) {
                position: relative !important;
                padding-left: 25px;
            }
        }
        .internal-services {
            position: absolute;
            left: 0;
            top: 0;
            height: 99%;
            background: #2a72b5;
            width: 15px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 18px;
            z-index: 1;
        }

        .outside-services {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            background: #dbdbdb;
            width: 15px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: #000;
            font-size: 18px;
        }
    }
}

.sub-task-list {
    .gridjs .gridjs-wrapper {
        .last-note-tr-sub {
            position: relative;
            .last-note-td-sub {
                position: static !important;
                padding-bottom: 100px !important;
                .notes-wrap {
                    font-size: 12px;
                    font-style: italic;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    background-color: #f1f1f1;
                    padding: 6px 5px;
                    border-top: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                }

                .notes-wrap-workorder-sub {
                    font-size: 12px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    background-color: #f1f1f1;
                    padding: 6px 5px;
                    padding-left: 28px;
                    border-top: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                    .photos-item {
                        display: flex;
                        column-gap: 20px;
                        margin-top: 5px;

                        span {
                            //background-color: #ccc;
                            padding: 0px;
                            font-style: italic;
                            strong {
                                font-style: normal;
                                color: #000;
                                font-weight: 500;
                            }
                        }
                        .edit-link {
                            color: var(--primary-link-color) !important;
                        }
                    }
                    .subtask-list-photos {
                        width: 50px;
                        height: 50px;
                        border-radius: 4px;
                        display: inline-flex;
                        overflow: hidden;
                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
    &.sub-task-table {
        .notes-wrap-workorder-sub {
            padding-left: 55px !important;
        }
    }
}

.service-checklist-list-modal {
    .gridjs .gridjs-wrapper {
        .last-note-tr-sub {
            position: relative;
            .last-note-td-sub {
                position: static !important;
                padding-bottom: 100px !important;
                .notes-wrap {
                    font-size: 12px;
                    font-style: italic;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    background-color: #f1f1f1;
                    padding: 6px 5px;
                    border-top: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                }

                .notes-wrap-workorder-sub {
                    font-size: 12px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    background-color: #f1f1f1;
                    padding: 6px 5px;
                    padding-left: 28px;
                    border-top: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                    .photos-item {
                        display: flex;
                        column-gap: 20px;
                        margin-top: 5px;

                        span {
                            //background-color: #ccc;
                            padding: 0px;
                            font-style: italic;
                            strong {
                                font-style: normal;
                                color: #000;
                                font-weight: 500;
                            }
                        }
                        .edit-link {
                            color: var(--primary-link-color) !important;
                        }
                    }
                    .subtask-list-photos {
                        width: 50px;
                        height: 50px;
                        border-radius: 4px;
                        display: inline-flex;
                        overflow: hidden;
                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.cancel-apt-list,
.cl-appointment-list {
    .edit-link {
        color: var(--text-dark-color) !important;
        text-decoration: underline !important;
    }
}

.assign-staff-tb {
    .edit-link {
        color: var(--text-dark-color) !important;
        text-decoration: underline !important;
    }
}

// hanumant css

.appointment-list {
    .gridjs-wrapper {
        .gridjs-tbody {
            .internal-services {
                position: absolute;
                left: 0;
                top: 0;
                height: 99%;
                background: #2a72b5;
                width: 15px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 18px;
                z-index: 1;
            }

            .outside-services {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                background: #dbdbdb;
                width: 15px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: #000;
                font-size: 18px;
                z-index: 1;
            }

            .last-note-tr .last-note-td .notes-wrap-workorder {
                padding-left: 28px;
            }
        }
    }
}

.customize-b-table {
    .total-items-count {
        font-weight: 500;
        font-size: 13px;
        padding: 0px;
        padding-bottom: 0;
        .count {
            align-items: center;
            background-color: var(--primary-bg-color);
            border-radius: 50px;
            color: #fff;
            display: inline-flex !important;
            font-size: 11px;
            font-weight: 400;
            justify-content: center;
            line-height: 10px;
            margin-left: 5px;
            min-width: 18px;
            padding: 4px !important;
            text-align: center;
            white-space: nowrap;
            z-index: 9999;
        }
    }
    .table {
        margin-bottom: 0;
        tr {
            th {
                background-color: var(--white-bg);
                border-color: #e5e7eb;
            }
            td {
                background-color: var(--white-bg);
                border-color: #e5e7eb;
            }
        }
    }
    .table > :not(:first-child) {
        border-top: 0 solid currentColor !important;
    }
}

// below code is for create payroll page subtotal/total button - use class for create payroll if this code disturbs other tables
.gridjs .gridjs-footer {
    display: flex;
    flex-direction: column;
    padding: 0;

    .total-row {
        padding: 10px;
        display: flex;
        justify-content: end;
        border: 1px solid #e5e7eb;
        border-top: 0;
        background: #fff;
        .cost-col {
            font-size: 12px !important;
            //font-weight: 500;
            margin-right: 10px;
            background-color: #f8f8fc; //ededf5
            border: 1px solid #f8f8fc;
            border-radius: 4px;
            padding: 3px 10px;
            span {
                font-weight: 500;
                font-size: 13px !important;
            }
        }
    }
    .invoice-total-row {
        padding: 0 10px;
        display: flex;
        justify-content: end;
        border: 1px solid #e5e7eb;
        border-top: 0;
        background: #fff;
        .cost-col {
            font-size: 12px !important;
            //font-weight: 500;
            margin-right: 10px;
            // background-color: #f8f8fc; //ededf5
            // border: 1px solid #f8f8fc;
            // border-radius: 4px;
            padding: 5px 10px;
            border-right: 1px solid #ccc;
            span {
                font-weight: 500;
                font-size: 13px !important;
            }
            &.title {
                background-color: transparent;
                border: 0;
                font-weight: bold;
                border-right: 1px solid #ccc;
                border-radius: 0;
            }
            &.last-c {
                border-right: 0;
            }
        }
        &.amount-add {
            padding-right: 0px !important;
            .cost-col {
                border: 0 !important;
            }
            input {
                width: 80px;
                margin-right: 10px;
            }
            .form-check-input {
                width: 16px !important;
            }
            span {
                white-space: nowrap;
                font-size: 13px;
                margin-right: 10px;
            }
        }
        &.no-border {
            .cost-col {
                border: 0 !important;
            }
        }
    }
    .gridjs-pagination {
        order: 2;
        padding: 10px;
        //border-top: 1px solid #e5e7eb;
    }
}

.count-x {
    align-items: center;
    background-color: var(--primary-bg-color);
    border-radius: 50px;
    color: #fff;
    display: inline-flex !important;
    font-size: 11px;
    font-weight: 400;
    justify-content: center;
    line-height: 10px;
    margin-left: 5px;
    min-width: 18px;
    padding: 4px !important;
    text-align: center;
    white-space: nowrap;
    z-index: 9999;
}

.print-content-table {
    tr th {
        background-color: #aed3ae !important;
    }
}

.print-content-table2 {
    tr th {
        background-color: #e5cccc !important;
    }
}

.grid-form-control {
    .form-control {
        font-size: 12px;
        padding: 3px 5px;
        line-height: 20px;
    }
}

// schedule staff list

.customize-schedule-staff-table {
    .cal-btns {
        .btn.btn-light {
            border-color: #d9d5d5 !important;
            width: 30px;
            flex: auto;
            min-width: 30px;

            line-height: 15px;
            padding: 6px 8px;

            &.with-text {
                width: 70px;
                flex: auto;
                min-width: 70px;
                font-size: 12px;
                background-color: #fff !important;
            }
            &.today-btn {
                width: 70px;
                flex: auto;
                min-width: 70px;
                font-size: 12px;
                margin-right: 10px;
            }
        }
    }
    .total-items-count {
        font-weight: 500;
        font-size: 13px;
        padding: 0px;
        padding-bottom: 0;
        .count {
            align-items: center;
            background-color: var(--primary-bg-color);
            border-radius: 50px;
            color: #fff;
            display: inline-flex !important;
            font-size: 11px;
            font-weight: 400;
            justify-content: center;
            line-height: 10px;
            margin-left: 5px;
            min-width: 18px;
            padding: 4px !important;
            text-align: center;
            white-space: nowrap;
            z-index: 9999;
        }
    }
    .table > :not(caption) > * > * {
        border: 0 !important;
        padding: 5px !important;
    }

    .table > :not(:first-child) {
        border-top: 0 solid currentColor !important;
    }

    .table {
        border-spacing: 2px;
        border-collapse: separate;
        background: var(--customize-schedule-staff-table);
        tr {
            td,
            th {
                background-color: var(--white-bg);
                //vertical-align: middle;
                width: 11.33%;
                color: var(--text-dark-color);
                font-weight: 600;
                font-size: 12.5px;
            }
            th {
                vertical-align: middle;
            }
            td {
                padding: 0 !important;
            }
        }
    }
    .f-cl-width {
        width: 22% !important;
    }

    .s-column {
        padding: 5px !important;
        .draft-box {
            width: 30px;
            .draft {
                background-color: var(--danger);
                color: var(--text-white);
                font-size: 8px;
                padding: 0 3px;
                border-radius: 2px;
            }
            svg {
                font-size: 15px;
                color: var(--primary-bg-color);
            }
        }

        .s-photos {
            width: 50px;
            height: 42px;
            margin: 0 5px;
            margin-right: 10px;
            border-radius: 4px;
            overflow: hidden;
            img {
                max-width: 100%;
                max-height: 100%;
                height: 100%;
            }
        }
        .s-details {
            flex: 1;
            h5 {
                font-size: 13px;
                margin-bottom: 0;
            }
            p {
                font-size: 10px;
                line-height: 13px;
                font-weight: normal;
                margin-bottom: 0;
                position: relative;
            }
            svg {
                font-size: 14px;
                color: var(--primary-bg-color);
                position: absolute;
                right: 3px;
                top: 3px;
            }
        }
    }
    .s-buttons-column {
        justify-content: center;
        padding: 5px !important;
        & + .s-buttons-column {
            border-top: 1px solid #f1ecec;
        }
        .shift-hr-btn {
            padding: 3px 7px;
            font-size: 10px;
            width: 100%;
            margin-top: 10px;
            background-color: lightgrey !important;
        }
        .log-req-initiated-btn {
            padding: 3px 7px;
            font-size: 10px;
            width: 100%;
            margin-top: 10px;
            background-color: lightgoldenrodyellow !important;
        }
        .log-shift-started-btn {
            padding: 3px 7px;
            font-size: 10px;
            width: 100%;
            margin-top: 10px;
            background-color: red !important;
        }
        .s-buttons {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            .shift-hr-btn {
                padding: 3px 7px;
                font-size: 10px;
                width: 100%;
                margin-top: 10px;
                color: #9d9d9d !important;
            }
            .s-btn {
                padding: 1px 7px;
                font-size: 9.5px;
                width: 100%;
            }
            p {
                font-size: 10px;
                margin-bottom: 0;
            }
        }
        &.d-btn {
            .s-btn {
                span {
                    color: #9d9d9d !important;
                }
                p {
                    color: #9d9d9d !important;
                }
            }
        }
    }
    .a-btns {
        width: auto;
        margin-left: 5px;
        flex-direction: column;
        display: flex;
        .a-sm-btn {
            margin: 0;
            padding: 0 !important;
            min-width: 18px !important;
            height: 18px;
            font-size: 10px;
            & + .btn {
                margin-top: 2px;
            }
        }
    }

    .s-th-column {
        .a-btns {
            width: auto;
            flex-direction: row;
            display: flex;
            .btn {
                margin: 0 0px 0 3px !important;
            }
        }
    }
}

//

.log-shift-time-modal-table {
    .shift-end-btn {
        min-width: 100px !important;
    }
    .a-sm-btn.btn.button-icon.btn-sm {
        min-width: 25px !important;
        height: 25px !important;
        padding: 3px !important;
    }
}

@media (max-width: 767px) {
    .customize-schedule-staff-table {
        .table {
            tr {
                background-color: #f5f5f5 !important;
                th {
                    width: 100%;
                }
                td {
                    width: 95%;
                    margin: 10px;
                    padding-top: 5px !important;
                    border: 1px solid #e7e7e7 !important;
                    &::before {
                        padding: 5px;
                    }
                }
            }
        }
    }

    // responsive by nitin
    .log-shift-time-modal-table {
        .a-sm-btn {
            margin-bottom: 10px;
        }
    }

    .rs-device-table {
        &.without-action-td {
            #no-more-tables {
                tr {
                    td {
                        padding-top: 10px;
                        &:last-child {
                            padding-bottom: 10px;
                        }
                    }
                }
            }
        }
    }

    .manager-side-apt-list {
        .appointment-list.cl-appointment-list {
            .gridjs-wrapper {
                .gridjs-tbody .gridjs-tr {
                    .gridjs-td {
                        padding-left: 22px !important;
                    }
                }
            }
        }
    }
    .manager-status-center-list {
        .gridjs-wrapper .gridjs-tbody .gridjs-tr td {
            position: static !important;
            padding-left: 25px !important;
            .internal-service,
            .outside-services {
                left: -1px;
            }
        }
    }

    .service-checklist-list-modal .gridjs .gridjs-wrapper .last-note-tr-sub {
        .last-note-td-sub {
            padding-bottom: 0px !important;
            &:last-child {
                padding-bottom: 70px !important;
            }
        }
    }

}

.inv-light-green {
    background-color: #aed3ae !important;
}
.inv-light-red {
    background-color: #e5cccc !important;
}
.inv-light-gray {
    background-color: #e5e5e5 !important;
}
.inv-light-blue {
    background-color: #cae3f0 !important;
}

.service-checklist-table {
    .table > :not(caption) > * > * {
        border: 1px !important;
        padding: 5px !important;
    }

    .table > :not(:first-child) {
        border-top: 1px solid currentColor !important;
    }

    .table {
        border-spacing: 1px;
        border-collapse: separate;
        background: var(--customize-schedule-staff-table);
        tr {
            padding: 5px;
            td,
            th {
                background-color: var(--white-bg);
                width: 11.33%;
                color: var(--text-dark-color);
                font-weight: 600;
                font-size: 12.5px;
            }
            th {
                vertical-align: middle;
            }
            td {
                padding: 2px !important;
                font-weight: 400;
                font-size: 12.5px;
            }
        }
    }
    .f-cl-width {
        width: 2% !important;
    }
    .s-column {
        padding: 5px !important;
    }
    &.auto-td-width {
        tr {
            th, td {
                width: auto !important;
                padding: 5px !important;
            }
        }
    }
}

.schedule-staff-table {
    tr {
        th {
            &.current-date {
                background-color: #faffc3e4 !important;
            }
        }

        td {
            &.current-date {
                background-color: #faffc3e4 !important;
            }
        }
    }
}

.status-center-list {
    .progress {
        height: 14px;
        font-size: 10px;
    }
 .status-center-table{
    background-color: #fff;
 }
    tr {
        //position: relative;
        th {
            font-size: 12px;
        }
        td {
            background-color: unset;
            padding: 5px;
            position: static !important;
            font-size: 12px;
            a, p, div {
                font-size: 12px;
            }
            &::before {
                font-weight: 500 !important;
                color: #000 !important;
            }
            .btn.sm-td-btn {
                font-size: 10px!important;
            line-height: 14px;
            min-width: 65px;
            padding: 2px 3px!important;
            }
            .checked-details {
                padding-bottom: 12px !important;
            }
            .text-danger {
                color: var(--text-danger) !important;
                font-weight: 500;
            }
            .text-success {
                color: var(--text-success) !important;
                font-weight: 500;
            }
            &:first-child {
                position: relative !important;
            }
        }
    }

    .status-line {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
    }

    .manager-status-center {
        tr {
            //position: relative;
            td {
                padding: 5px;
                .status-line {
                    left: 15px;
                }
                &:first-child {
                    padding-left: 22px !important;
                }
            }
            
        }
    }

    .top-section-appointment {
        margin-bottom: 0;
    }

    .accordion {
        .accordion-item {
            margin: 10px 0;
            border: 0;
            .accordion-header {
                .accordion-button {
                    padding: 10px;
                    background-color: #e7f1ff;
                    font-size: 14px;
                    color: var(--text-dark-color);
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
            .accordion-body {
                padding: 0;
            }
        }
    }
}

@media (max-width: 441px) {
    .status-center-list {
        thead {
            display: none !important;
        }
        tr {
            position: relative !important;
            td {
                &:first-child {
                    position: static!important;
                }
            }
        }
    }
}

.status-center-appt {
    .status-1 {
        border-left: 4px solid #f63131 !important;
    }
    .status-2 {
        border-left: 4px solid #65bfe8 !important;
    }
    .status-3 {
        border-left: 4px solid #ffb400 !important;
    }
    .status-4 {
        border-left: 4px solid #2255cc !important;
    }
    .status-5 {
        border-left: 4px solid #74b749 !important;
    }

    .internal-services {
        background: #2a72b5;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 15px;
        font-size: 18px;
        z-index: 1;
    }

    .outside-services {
        background: #dbdbdb;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 15px;
        font-size: 18px;
        z-index: 1;
    }
}
.table th {
    font-weight: 500;
}
.table-bordered {
    &.table > :not(:first-child) {
        border-top: 1px solid currentColor;
    }
}

.message-list {
    .gridjs {
        .gridjs-wrapper {
            border: 0;
            th.gridjs-th {
                border: 0 !important;
                border-bottom: 0.5px solid var(--menu-border-color) !important;
            }
            td.gridjs-td {
                border: 0 !important;
                border-bottom: 0.5px solid var(--menu-border-color) !important;
            }
        }
    }
}



.grid-action-menus {
    &.dropdown-menu {
        background-color: var(--white-bg);
        border: 1px solid var(--menu-border-color) !important;
        background-clip: padding-box;
        border-radius: 3px !important;
        //box-shadow: 0 16px 18px #8787b61a!important;
        box-shadow: -1px 1px 10px 0px #e4e6e9;
        padding: 0;
        z-index: 1050;
        a {
            position: relative;
            padding: 7px 10px;
            display: flex;
            align-items: center;
            font-size: 12.5px;
            text-decoration: none;
            width: 100%;
            color: var(--input-label-color);
            border-top: 1px solid var(--menu-border-color) !important;
            border-radius: 0;
            margin: auto !important;
           
            &:first-of-type {
                border-top: 0 !important;
            }
            .menu-icon {
                font-size: 17px;
                margin-right: 10px;
                width: 24px;
                text-align: center;
                color: var(--input-label-color);
            }
            &:active {
                color: var(--primary-bg-color) !important;
            }
            &.disabled {
                color: #CCC !important;
            }
            &:hover, &:focus {
                background-color: transparent;
                color: var(--primary-bg-color);
                .menu-icon {
                    color: var(--primary-bg-color);
                }
            }
        }
    }
}

.dashboard-unconfirmed-apts {
    td[data-column-id="groupcol-1"] {
        position: relative;
        padding: 0px !important;
    }
    .edit-link {
        line-height: 1.2;
    }
    .apt-popup-icon-child {
        margin-top: 0;
        height: auto;
    }
    .apt-popup-icon {
        margin-top: 0;
    }
    .internal-services {
        position: absolute;
        left: 0;
        top: 0;
        height: 99%;
        background: #2a72b5;
        width: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 18px;
        z-index: 1;
    }

    .outside-services {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: #dbdbdb;
        width: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-size: 18px;
    }
}

.admin-create-invoice-list,
.manager-wo-edit-invoice-list {
    .invoice-total-row {
        padding: 10px;
        border: 1px solid #e5e7eb;
        span {
            font-size: 13px;
            white-space: nowrap;
            margin-right: 10px;
            width: 130px;
        }
        .cost-col {
            font-size: 14px;
            margin: 7px 0 !important;
            font-weight: 600;
            text-align: right;
        }

        .btn.button-icon.direct-action {
            margin-left: 10px !important;
        }
    }
}

.payroll-table {
    .table > :not(caption) > * > * {
        border: 1px !important;
        padding: 5px !important;
    }

    .table > :not(:first-child) {
        border-top: 1px solid currentColor !important;
    }

    .table {
        border-spacing: 1px;
        border-collapse: separate;
        background: var(--customize-schedule-staff-table);
        tr {
            padding: 6px;
            td,
            th {
                background-color: var(--white-bg);
            }
            th {
                padding: 8px !important;
                vertical-align: middle;
                font-weight: 600;
                font-size: 12.5px;
            }
            td {
                padding: 5px !important;
                font-weight: 400;
                font-size: 12.5px;
            }
        }
    }
    .f-cl-width {
        width: 2% !important;
    }
    .s-column {
        padding: 5px !important;
    }
}

.time-height {
    height: 17px;
    font-size: 11px;
}

.manager-itrip-intergration {
    div, p {
        font-size: 13px;
    }
    table {
        tr {
            td, th {
                font-size: 13px;
            }
        }
    }
}

@-moz-document url-prefix() {
    .gridjs .gridjs-wrapper table.gridjs-table .gridjs-thead th.gridjs-th {
        white-space: normal !important;
        background-color: #f9fafb !important;
    }
    .msf-1 {
        margin-left: 5px;
    }
}

.plain-table {
    .table {
        background-color:var(--white-bg);
        tr {
            th {
                color: var(--text-dark-color);
                font-weight: 600;
                font-size: 12.5px;
            }
            td {
                font-weight: 400;
                font-size: 12.5px;
            }
        }
    
        .t-w50 {
            width: 50%;
        }
        .t-text-right {
            text-align: right;
        }
        .t-text-center {
            text-align: center;
        }
        tr.sticky-header {
            position: sticky;
            z-index: 10;
            top: 64px
        }
    }
}
.plain-table-scroll {
    width: 100%;
    overflow-y: auto;
    .plain-table .table tr.sticky-header {
        top:0
    }
}


.checkbox-mr-0 {
    .form-check-input {
        margin-top: 0 !important;
    }
}

.status-center-table-print-header {
    display: none;
}

.inventory-report-custom-table{


    tr td:first-child {
        //position: relative;
        //padding-left: 25px;
    }
    
    .internal-services {
        position: absolute;
        left: 0;
        top: 0;
        height: 99%;
        background: #2a72b5;
        width: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 18px;
        z-index: 1;
    }
    
    .outside-services {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: #dbdbdb;
        width: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-size: 18px;
    }
    
    }

    .property-readiness-table {
        .gridjs-wrapper .gridjs-tbody .gridjs-tr {
            td {
                padding: 5px !important;
                .property-status {
                    display: flex;
                }
            }
            td.gridjs-td:last-child {
                position: relative !important;
            }
        }
    }

    .tr-td-no-padding {
        .gridjs .gridjs-wrapper .last-note-tr-sub td[role="alert"].last-note-td-sub {
            padding: 5px !important;
        }
    }
    .gridjs-wrapper .gridjs-tbody .gridjs-tr  td[role="alert"].gridjs-notfound {
        padding: 5px !important;
        position: relative !important;
    }
    

    .static-no-records-found {
        td {
            text-align: center;
            width: 100% !important;
            padding: 5px !important;
        }
    }

    .custom-table-font {
        tr {
            th, td {
                font-size: 13px;
            }
        }
    }


    .gridjs .gridjs-wrapper table.gridjs-table td.gridjs-td[data-title="Res#"] {
        word-break: break-all !important;

    }


.new-date {
    width: 120px !important;
    background-color: #00d4d4;
    padding: 3px;
    border-radius: 4px;
}

.roll-appts-table {
    .table > :not(caption) > * > * {
        border: 1px !important;
        padding: 5px !important;
    }

    .table > :not(:first-child) {
        border-top: 1px solid currentColor !important;
    }

    .table {
        border-spacing: 1px;
        border-collapse: separate;
        background: var(--customize-schedule-staff-table);
        tr {
            padding: 6px;
            background-color: var(--white-bg);
            th {
                background-color: var(--white-bg);
                cursor: pointer;
            }
            td,
            th {
                padding: 8px !important;
                vertical-align: middle;
                font-weight: 600;
                font-size: 12.5px;
            }
            td {
                padding: 5px !important;
                font-weight: 400;
                font-size: 12.5px;
            }
        }
    }
    .f-cl-width {
        width: 2% !important;
    }
    .s-column {
        padding: 5px !important;
    }
}

.appointment-list-note-last-col {
    .gridjs-wrapper .gridjs-tbody .gridjs-tr.last-note-tr .gridjs-td {
        // padding-bottom: 145px;
        //padding-bottom: clamp(50vw, 70vw, 1000px);
        .notes-wrap-workorder {
            font-size: 12px;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            background-color: var(--note-table-wrapper);
            padding: 6px 5px;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            overflow-y: auto;
            max-height: 130px;
            .notes-wrap-item {
                padding-bottom: 0;
                margin-bottom: 3px;
            }
            
        }

        .notes-wrap-workorder-pm {
            padding-left: 30px;
            
        }
    }
}

.assignment-progress {
    .table {
        tr {
            td.blue { background-color: #b8cce4; }
            td.yellow { background-color: #fce3bb; }
            td.red { background-color: #fdcece; }
        }
    }
}

.assign-staff-list {
    .gridjs-wrapper .gridjs-tbody .gridjs-tr {
        position: relative;
        .gridjs-td {
            // padding-bottom: 130px;
            position: static;
           //padding-bottom: clamp(50vw, 70vw, 1000px);
           .notes-wrap-workorder {
               font-size: 12px;
               position: absolute;
               left: 0;
               bottom: 0;
               width: 100%;
            //    background-color: var(--note-table-wrapper);
               padding: 6px 5px;
               border-top: 1px solid #ccc;
               border-bottom: 1px solid #ccc;
               overflow-y: auto;
               max-height: 130px;
               .notes-wrap-item {
                   padding-bottom: 0;
                   margin-bottom: 3px;
               }
               
           }
   
           .notes-wrap-workorder-pm {
               padding-left: 30px;
               
           }
       }
    }
}

@media (max-width: 441px) {
.appointment-list-note-last-col  {
    .gridjs-wrapper .gridjs-tbody .gridjs-tr td.gridjs-td{
        padding-bottom: 5px !important;
        .action-with-icon {
            text-align: left !important;
            padding-bottom: 5px;
        }
        &:last-child {
            position: relative !important;
        }
        .notes-wrap-workorder {
            position: relative !important;
            max-height: inherit !important;
        }
    }
}
}


@-moz-document url-prefix() {
    .cleaner-appointmnet-list.assign-staff-list #no-more-tables .gridjs-wrapper .gridjs-table {
        table-layout: inherit !important;
        .appointment-list-icons {
            flex-wrap: wrap !important;
            width: 170px;
        }
    }
}

@media print {
    .appointment-list-icons {
        display: none;
    }
    .apt-popup-icon {
        height: 0px;
        width: 0px;
        color: #fff;
        background: #fff;
    }
    .apt-popup-icon-child {
        height: 0px;
        width: 0px;
        color: #fff;
        background: #fff;
    }
    .status-center-table-print-header {
        display: block;
        padding-left: 25px;
        margin-bottom: 2px;
    }
    .internal-services {
        display: none;
        visibility: hidden;
    }
    .outside-services {
        display: none;
        visibility: hidden;
    }
}

.status-center-dnd td, .status-center-dnd a {
        color: white !important;
}

.form-label.form-label-chek-auto-share {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    text-transform: capitalize;
    color: var(--input-label-color);
    &+input {
        cursor: pointer;
        position: relative;
        z-index: 99;
    }
}