
.integration-right{

    .airbnb-link:hover{
        color: rgb(202, 4, 4) !important;
    }
    
    .integ-logo img{
        width: 150px;
    }

    .integ-logo-background{
        background-color: #003425 !important;
        padding: 5px;
    }
    
    .Logo-title{
        font-size: 12px;
        font-weight: bold;
    }
    
    .brand-divider{
        background-color: #4e4d4d;
    }
    
    button{
        border: 0;
        border-radius: 5px;
        padding: 5px 15px;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
    }
    
    button:hover{
        padding: 5px 15px;
        font-size: 14px;
        font-weight: 500;
        background-color: #5c636a;
        border-color: #565e64;
    }
    
    .notice-section{
        padding: 10px;
        background-color: #ffcdcc;
        color: #802d2b;
        font-size: 12px;
        margin-bottom: 20px;
        border-color: #fbabab;
        border-radius: 3px;
    
        button{
            background-color: #0c9abb;
            color: #ffff;
        }
    }
    
        .warning-section{
            padding: 10px;
            background-color: #fce1ba;
            color: #7b4e0e;
            font-size: 12px;
            margin-bottom: 20px;
            border: 1px solid #ffda80;
            border-radius: 3px;
         }

         .rezkey-step-img img{
            width: 200px;
         }

         .GuidedIcal img{
            width: 390px;
         }

         .accordion-button:not(.collapsed) {
            background-color: #5c636a;
            border-color: #5c636a;
            color: #ffffff;
            //box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
        }
            
.card {
    border: 0;
    background-color: var(--white-bg);
    .card-header {
      background-color: transparent;
      border: 0;
      padding: 15px 20px 0px 20px;
      position: relative;
      .card-title {
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 0px;
        line-height: 1.5;
        &::before {
          content: "";
          width: 3px;
          height: 16px;
          background: var(--primary-bg-color);
          position: absolute;
          left: 11px;
          display: block;
          top: 16px;
        }
      }
    }
}
}

table.vrmTb{
    tr th{
        text-align: center !important;
    }
    tr td{
        text-align: center !important;

        svg{
          color: green;
          width: 2em;
          stroke-width: 5;
          height: 2em;
        }
    }

    }

   .ratings .rating-stars svg{
     color: #FFBF00;
     margin-right: 0.5rem;
     font-size: 1.5em;
   } 

   .brightside-service-map-tb{
    td{
        background-color: transparent;
        text-align: start;

        &.bg-checkout-clean{
            background-color: #E37B40B3;
        }
        &.bg-daily-clean{
            background-color: #F5182399;
        }
        &.bg-full-serivce{
            background-color: #F0CA4D;
        }
        &.bg-oceana-service{
            background-color: #46B29DB3;
        }
        &.bg-trash-removal{
            background-color: #4d46b2b3;
        }
    }
   }

   .status-para{
    position: relative;
   }

   .status-section .status-indicator::after{
    content : "\2022"; 
    padding-right: 10px; 
    padding-bottom: 10px;
    color: #807f7f;
    font-size: 2.4rem;
    position: absolute;
    bottom: -28px;
   }

   .status-section.connected .status-indicator::after{
    color: #02af2e;
   }