.jobsrevenuetable{
    overflow-x: auto;
}

.jobsrevenuetable .table{
    border: 2px solid #e6e6e6;
}

.jobsrevenuetable .gridjs .gridjs-wrapper{
    border: 2px solid #d7d5d5;
}

.jobsrevenuetable .gridjs .gridjs-wrapper table.gridjs-table {
    table-layout: fixed;
    width: 100%;
}

.jobsrevenuetable .gridjs .gridjs-wrapper table.gridjs-table td.gridjs-td:first-child {
font-weight: 600;
}
.jobsrevenuetable .gridjs .gridjs-wrapper table.gridjs-table td.gridjs-td{
    padding: 8px!important;
}
.jobsrevenuetable .gridjs .gridjs-wrapper table.gridjs-table{
    tr.gridjs-tr:nth-child(2) td {
        border-bottom: 2px solid #8e8e8e;
        padding-bottom: 20px!important;
    }
    tr.gridjs-tr:nth-child(5) td {
        border-bottom: 2px solid #8e8e8e;
        padding-bottom: 20px!important;
    }
    tr.gridjs-tr:nth-child(7) td {
        border-bottom: 2px solid #8e8e8e;
        padding-bottom: 20px!important;
    }
    tr.gridjs-tr:nth-child(8) td {
        border-bottom: 2px solid #8e8e8e;
        padding-bottom: 20px!important;
    }
    tr.gridjs-tr:nth-child(9) td {
        border-bottom: 2px solid #8e8e8e;
        padding-bottom: 20px!important;
    }
    tr.gridjs-tr:nth-child(13) td {
        border-bottom: 2px solid #8e8e8e;
        padding-bottom: 20px!important;
    }
    tr.gridjs-tr:nth-child(15) td {
        border-bottom: 2px solid #8e8e8e;
        padding-bottom: 20px!important;
    }
    tr.gridjs-tr:nth-child(17) td {
        border-bottom: 2px solid #8e8e8e;
        padding-bottom: 20px!important;
    }
    tr.gridjs-tr:nth-child(19) td {
        border-bottom: 2px solid #8e8e8e;
        padding-bottom: 20px!important;
    }
    tr.gridjs-tr:nth-child(20) td {
        border-bottom: 2px solid #8e8e8e;
        padding-bottom: 20px!important;
    }
}