@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../../node_modules/gridjs/dist/theme/mermaid.min.css';
@import '../styles/colors.scss';
@import '../styles/typography.scss';
@import '../styles/global.scss';
@import '../styles/table.scss';
@import '../styles/modal.scss';
@import '../styles/header.scss';
@import '../styles/sidebar.scss';
@import '../styles/button.scss';
@import '../styles/input.scss';
@import '../styles/price-config.scss';
@import '../styles/dashboard.scss';
@import '../styles/integration.scss';
@import '../styles/photo-lightbox.scss';
@import '../styles/responsive.scss';
// @import '../../assets/scss/icons.scss';
//@import '../../assets/scss/style.scss';

#launcher {
  z-index: 1049 !important;
  right: 66px !important;
  margin-bottom: 2px !important;
}
.u-isActionable {
  padding: 0 !important;

}
  
@media (max-width: 768px) {
  #launcher {
    left: 0;
    margin: 5px 5px 5px 9px !important;
  }

  .sidebar-toggled {
    #launcher {
      display: none !important;
    }
  }
}
  .StripeElement {
    color: var( --input-text-color) !important;
    font-size: 14px;
    line-height: 25px;
    font-weight: 500;
    border: 1px solid  var(--menu-border-color) !important;
    padding: 9px 12px;
    border-radius:4px;
    background-color: var(--input-bg);
    display: flex;
    align-items: center;
    width: 100%;
  }

  .__PrivateStripeElement {
    width: 100%;
  }
  
  .StripeElement--focus {
      border-bottom: 2px solid #01896a;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  .StripeElement--placehoder {
    color: var( --grey-text-color);
    opacity: .6;
    font-weight: 400;
    font-size: 13px;
  }

//   .StripeElement--complete {
//     border-color:  var(--success-btn-border-color) !important;
//   }

  .StripeElement--empty {
    color:red !important
  }

  .tilled-js_input  {
    height: 37px !important;
    padding: 5px 12px;
    border-radius: 4px;
    border: 1px solid  var(--menu-border-color) !important;
    #cardExpiry {
      color: var(--input-text-color) !important;
      font-size: 14px !important;
      line-height: 25px !important;
      font-weight: 500 !important;
    }
    
  }
  #card-number-element {
    height: 37px !important;
    padding: 5px 12px;
    border-radius: 4px;
    border: 1px solid  var(--menu-border-color) !important;
  
    #cardNumber {
      color: var(--input-text-color) !important;
      font-size: 14px !important;
      line-height: 25px !important;
      font-weight: 500 !important;
    }
  }
  
