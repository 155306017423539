@import '../../../../styles/colors.scss';


.print-content {
    background-color: var(--modal-bg-color);
    padding: 10px;
}

.table {
    background-color:var(--white-bg);
    tr {
        th {
            color: var(--text-dark-color);
            font-weight: 600;
            font-size: 12.5px;
        }
        td {
            color: var(--body-text-color);
    font-weight: 400;
    font-size: 12.5px;
        }
    }

    .t-w50 {
        width: 50%;
    }
    .t-text-right {
        text-align: right;
    }
    .t-text-center {
        text-align: center;
    }
}


.upload-image-invoice {
    .image-item {
      position: relative;
      //height: 150px;
      width: 150px;
      max-height: 150px;
      height: auto;
      padding: 1px;
      border-radius: 8px !important;
      overflow: hidden;
      //margin-top: 20px;
      img {
        max-width: 100%;
        //width: 100%;
        max-height: 100%;
        height: 100%;
      }
      .image-item__btn-wrapper {
        position: absolute;
        top: 5px;
        width: 40px;
        right: 0px;
        //display: none;
        .u-btn {
          font-size: 14px;
          line-height: 30px;
          height: 26px;
          width: 26px;
          margin: 3px 1px;
          padding: 0;
          border-radius: 50px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          transition: all 0.3s ease-in-out;
          color: var(--text-white);
          border: none;
          &.delete-image {
            background-color: #f34343 !important;
            &:hover {
              background-color: #ee1f1f !important;
            }
          }
          &.edit-image {
            background-color: #38cab3 !important;
            &:hover {
              background-color: #2db59f !important;
            }
          }
          &.rotate-image {
            background-color: #000 !important;
            &:hover {
              background-color: #000 !important;
            }
          }
        }
      }
      // &:hover {
      //  .image-item__btn-wrapper{display: block;}
      // }
    }
  
    ///// new dropzone
  
    .dropzone {
      width: 100%;
      min-height: 250px;
      border: 2px dashed #b6c2c9;
      font-size: 24px;
      color: #b6c2c9;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      cursor: pointer;
    }
  }

.invoice-setting-logo {
    //height: 150px;
    width: 150px;
    max-height: 150px;
    height: auto;
    img {
        max-width: 100%;
        //width: 100%;
    }
}