@import '../../styles/colors.scss';
.rdw-editor-toolbar {
  border: none;
  border-bottom: 1px solid var(--text-grey-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.demo-wrapper {
  border: 1px solid var(--text-grey-color);
  border-radius: 10px;
}
.public-DraftStyleDefault-block{
  padding: 0 20px;
}
