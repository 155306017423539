.dashboard-page {
    body {
        background: #e9ecf6;
    }
    // .main-container {
    //     max-width: 1900px;
    // margin: 0 auto;
    // }
    h4 {
        font-size: 13px;
        font-weight: 500;
        color: var(--body-text-color);
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .dashbaord-container {
        .select__control {
            min-height: 25px;
            .select__value-container {
                height: 25px !important;
            }
            .select__indicator {
                padding: 0;
            }
            .select__input-container {
                margin: 0;
                padding-top: 0;
            }
            .select__placeholder {
                font-size: 12px;
                text-align: left;
            }
            .select__single-value {
                font-size: 12px;
            }
        }
        .react-datepicker__input-container input {
            padding: 1px 7px;
            font-size: 12px;
        }
    }

    .dashboard-wrapper {
        display: flex;
        justify-content: space-between;

        .status-box-right {
            padding: 10px;
            background-color: var(--white-bg);
            border-radius: 6px;

            .status-box-right-box {
                column-gap: 10px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                div,
                h5 {
                    text-align: center;
                    font-size: 12px;
                }
                .s-width {
                    flex: 0 0 47.8%;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    transition: all 0.2s ease;
                    .count {
                        font-size: 22px;
                        color: var(--danger);
                        sub {
                            font-size: 11px;
                            color: #000;
                        }
                    }
                    &:hover {
                        box-shadow: 0px 0px 4px 0px #c7c7c7;
                    }
                }

                .s-width.black {
                    //border-color: var(--text-dark-color);
                    border-color: #f8f8f8;
                    background-color: #f8f8f8;
                    .count {
                        color: var(--text-dark-color);
                    }
                }
                .s-width.red {
                    //border-color: var(--danger);
                    border-color: #ffebf0;
                    background-color: #ffebf0;
                    .count {
                        color: var(--danger);
                    }
                }

                &.green {
                    .s-width {
                        //border-color: var(--success);
                        border-color: #e8fcf9;
                        background: #e8fcf9;
                    }

                    .count {
                        color: var(--success);
                    }
                }

                &.blue {
                    .s-width {
                        //border-color: var(--primary-text-color);
                        border-color: #e8f4ff;
                        background-color: #e8f4ff;
                    }
                    .count {
                        color: var(--primary-text-color);
                    }
                }
            }
        }
        .status-buttons {
            padding: 0px 0;
            //background-color: var(--white-bg);
            border-radius: 6px;
            display: flex;
            column-gap: 10px;
            .btn {
                font-size: 10px;
                font-weight: 500;
                padding: 5px 5px;
                line-height: 13px;

                border: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease;
                width: 50%;
                .count {
                    font-size: 18px;
                    line-height: 24px;
                    margin-bottom: 5px;
                    width: 40%;
                    & + span {
                        width: 60%;
                        letter-spacing: -0.1px;
                    }
                }
                &.linear-complete-btn {
                    //background: linear-gradient(220.36deg, #f59090 -25.27%, #ed9e9e 137.67%);
                    background-color: #f94045;
                }
                &.linear-confirm-btn {
                    //background: linear-gradient(220.36deg, #e9d893 -25.27%, #ff9165 137.67%);
                    background-color: #f99719;
                }
                &.linear-schedule-appt-btn {
                    //background: linear-gradient(220.36deg, #e9d893 -25.27%, #ff9165 137.67%);
                    background-color: #74b749;
                }
                &.linear-properties-btn {
                    //background: linear-gradient(220.36deg, #e9d893 -25.27%, #ff9165 137.67%);
                    background-color: #38589a;
                }
                &:hover {
                    box-shadow: 0px 0px 4px 0px #807777;
                }
            }
        }
        .dashboard-left {
            width: 68%;
        }
        .dashboard-right {
            width: 30.5%;
            // background-color: var(--white-bg);
            // padding: 12px 10px;
            // border-radius: 6px;
        }

        .calendar-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 20px;
            padding-bottom: 0;

            .calendar-view {
                width: 66%;
                background-color: var(--white-bg);
                padding: 12px 10px;
                border-radius: 6px;
                &.status-view {
                    width: 31.7%;
                }
            }
        }
    }
    .all-status-wrapper {
        display: flex;
        justify-content: space-between;

        .status-box {
            background-color: var(--white-bg);
            padding: 0px;
            width: 31.7%;
            border-radius: 6px;
            //box-shadow: 0px 2px 10px 0px rgba(58, 53, 65, .5);
            display: flex;
            justify-content: space-between;
            .status-left {
                width: 60%;
                padding: 10px;
                padding-right: 0;
                h4 {
                    margin-bottom: 8px;
                    font-size: 12px;
                }
            }
            .status-right {
                width: 40%;
                padding-bottom: 20px;
            }
            .status-chart {
                height: 100px;
                //width: 100px;
                position: relative;
                p {
                    position: absolute;
                    bottom: -10px;
                    left: 0px;
                    width: 100%;
                    text-align: center;
                    font-weight: 600;
                    font-size: 10px;
                    text-transform: uppercase;
                    margin-bottom: 0px;
                    line-height: 1.5;
                }
            }
            .form-label {
                font-size: 10px;
                margin-bottom: 2px;
                font-weight: 500;
                color: #818ea3;
            }
        }
        &.manager-all-status {
            column-gap: 10px;
            .status-box {
                width: 30%;
            }
        }
    }
    .calender-legends {
        display: flex;
        justify-content: center;
        width: 100%;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            li {
                padding: 5px 7px 0;
                .dot {
                    width: 10px;
                    height: 10px;
                    display: inline-block;
                    margin-right: 6px;
                    border-radius: 50%;
                    position: relative;
                    top: 2px;

                    &.red-s {
                        //background-color: #f58da4;
                        background-color: #f94045;
                    }

                    &.light-blue {
                        background-color: #ceaeef;
                    }

                    &.orange {
                        //background-color: #e0c78c;
                        background-color: #f99719;
                    }

                    &.dark-blue {
                        background-color: #277da2;
                    }

                    &.green {
                        background-color: #43aa8c;
                    }
                    &.maroon {
                        background-color: #88300087;
                    }
                    &.grey {
                        background-color: #a5a0b1;
                    }
                    & + span {
                        font-size: 11px;
                       
                    }
                }
            }
        }
    }

    .dashboard-chart {
        display: flex;
        justify-content: space-between;
        column-gap: 20px;
        margin-top: 20px;

        .charts {
            &.chart-left {
                width: 68%;
            }
            &.chart-right {
                width: 30.5%;
                background-color: var(--white-bg);
                padding: 10px;
                border-radius: 6px;
            }
        }
        .revenue-graph-height {
            height: 300px;
        }
    }
    .revenue-chart {
        background-color: var(--white-bg);
        padding: 10px;
        border-radius: 6px;
    }

    .count-p {
        display: inline-flex !important;
        padding: 4px !important;
        font-size: 11px;
        z-index: 9999;
        border-radius: 50px;
        line-height: 10px;
        text-align: center;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        background-color: #dc3545;
        color: #fff;
        font-weight: 400;
        //height: 37px;
        min-width: 18px;
        margin-left: 5px;
        background-color: var(--primary-bg-color) !important;
    }

    .property-chart {
        background-color: var(--white-bg);
        padding: 10px;
        border-radius: 6px;
        min-height: 300px;
        max-height: 300px;
    }

    .property-status-table {
        &.modal-status-table {
            background-color: var(--white-bg);
            padding: 12px 10px;
            border-radius: 6px;
            .gridjs-pagination {
                padding: 0;
                padding-top: 10px;
                .gridjs-pages button {
                    line-height: 1;
                    min-width: 28px;
                    height: 28px;
                }
            }
            .td.gridjs-td {
                border-width: 0.5px !important;
            }
        }
        table {
            tbody {
                border: 0;
            }
            tr {
                th {
                    color: var(--th-color);
                }
                td {
                    color: var(--td-color);
                }
                td,
                th {
                    font-size: 12px;
                    padding: 9px 3px;
                    vertical-align: middle;
                    border-bottom: 0.5px solid var(--table-border-color);
                    background-color: var(--white-bg);
                }
            }
        }
        .property-status {
            span {
                width: 18px;
                height: 18px;
                align-items: center;
                border-radius: 50%;
                color: #fff;
                cursor: pointer;
                display: inline-flex;
                justify-content: center;
                margin-right: 6px;
                svg {
                    font-size: 11px;
                }
                &.greenbg {
                    background-color: #38cab3;
                }
                &.redbg {
                    background-color: #f58da4;
                }
                &.greybg {
                    background-color: #a5a0b1;
                }

                &.setting {
                    svg {
                        font-size: 10px !important;
                    }
                }
            }
        }
        .btn.btn-sm {
            padding: 2px !important;
            min-width: 66px;
        }
    }
}

.fc {
    font-family: "Poppins", sans-serif !important;
    .fc-view-harness {
        z-index: 1;
    }
    .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
        display: flex;
        flex-wrap: wrap;
    }
    .fc-daygrid-body-natural .fc-daygrid-day-events {
        //margin-bottom: 10px !important;

        margin-bottom: 5px !important;
        margin-top: 5px !important;
    }
    .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
        min-height: auto !important;
    }
    .fc-daygrid-event {
        width: 22px;
        height: 15px;
        //border-radius: 50%;
        display: flex;
        justify-content: center;
        font-size: 9px;
        align-items: center;
        line-height: 5;
        margin: 2px !important;
        border: 0 !important;
    }
    .fc-toolbar {
        align-items: flex-start !important;
    }
    .fc-toolbar.fc-header-toolbar {
        margin-bottom: 10px !important;
        .fc-toolbar-chunk {
            .fc-today-button {
                text-transform: uppercase;
            }
        }
    }
    .fc-daygrid-day-number {
        padding: 1px 6px !important;
    }

    a {
        cursor: pointer;
        text-decoration: none;
    }
    .fc-toolbar-title {
        font-size: 14px !important;
        font-weight: 500;
        color: var(--primary);
        text-transform: uppercase;
    }
    .fc-button {
        padding: 2px 5px !important;
        font-size: 12px !important;
    }
    .fc-col-header-cell-cushion {
        font-weight: 500 !important;
    }
    .fc-button-group {
        .fc-button {
            padding: 2px 5px !important;
            font-size: 12px !important;
        }
    }
    .fc-daygrid-body {
        width: 100% !important;
    }
    .fc-scrollgrid-section table,
    .fc-scrollgrid-section-body table,
    .fc .fc-scrollgrid-section-footer table {
        width: 100% !important;
    }

    .fc-agenda-slots td div {
        height: 40px !important;
    }
}

@media only screen and (min-width: 1550px) {
    .dashboard-page {
        .main-container {
            .container-fluid {
                max-width: 1550px;
                margin: 0 auto;
            }
        }
    }
}

// @media (min-width: 1026px) and (max-width:1320px) {
//     .dashboard-page .dashboard-wrapper .status-buttons .btn {
//         flex-direction: column;
//     }
// }

@media only screen and (min-width: 1025px) {
    .dashboard-page .dashboard-wrapper .status-buttons .btn {
        flex-direction: column;
    }
}

@media only screen and (max-width: 1024px) {
    .dashboard-page {
        .dashboard-wrapper {
            flex-wrap: wrap;
            .dashboard-left {
                width: 100%;
            }
            .dashboard-right {
                width: 100%;
                margin-top: 15px;
            }
        }
        .manager-all-status {
            flex-wrap: wrap;
            justify-content: flex-start;
            row-gap: 10px;
            .status-box {
                //width: 32.5% !important;
                width: 48.5% !important;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .confirm-complete-container .gridjs-thead .gridjs-tr th:not(:first-child) {
        display: none !important;
    }
    .gridjs-thead .gridjs-tr:first-child {
        display: flex !important;
        flex-direction: column;
    }
    .confirm-complete-container#no-more-tables thead tr {
        position: unset;
    }
    .dashboard-page {
        .dashboard-wrapper {
            .calendar-wrapper {
                .calendar-view {
                    width: 100%;
                }
                .status-view {
                    width: 100% !important;
                    margin-top: 15px;
                }
            }
        }
        .dashboard-chart {
            flex-wrap: wrap;
            .charts {
                &.chart-left {
                    width: 100%;
                }
                &.chart-right {
                    width: 100%;
                    margin-top: 15px;
                    margin-bottom: 0px;
                }
            }
        }
        .manager-property-table {
            margin-bottom: 0px;
        }

        .manager-all-status {
            justify-content: space-between;
            .status-box {
                width: 48.5% !important;
            }
        }
    }
}

// @media only screen and (min-device-width: 769px)
//                    and (max-device-width: 800px)
//                    and (orientation: landscape) {

//                     .dashboard-page .manager-all-status .status-box {
//                         width: 48.5% !important;
//                     }
// }
@media only screen and (max-width: 441px) {
    .confirm-complete-container .gridjs-thead .gridjs-tr th:not(:first-child) {
        display: none !important;
    }
    .gridjs-thead .gridjs-tr:first-child {
        display: flex !important;
        flex-direction: column;
    }
    .confirm-complete-container#no-more-tables thead tr {
        position: unset;
    }
    .dashboard-page {
        .dashboard-wrapper {
            flex-wrap: wrap;
            .dashboard-left {
                width: 100%;
            }
            .calendar-wrapper {
                margin-top: 0px;
                .calender-legends {
                    margin-top: 10px;
                }
                .calendar-view {
                    width: 100%;
                    order: 2;
                    margin-top: 10px;
                    &.status-view {
                        width: 100%;
                        order: 1;
                    }
                }
            }
            .dashboard-right {
                width: 100%;
                margin-top: 10px;
                &.manager-property-table {
                    margin-bottom: 20px !important;
                }
            }
        }
        .all-status-wrapper {
            flex-wrap: wrap;
            .status-box {
                width: 100%;
                margin-bottom: 10px;
            }
            &.manager-all-status {
                .status-box {
                    width: 100% !important;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .calender-legends {
        ul {
            li {
                padding: 0px 4px 0 !important;
            }
        }
    }
    .dashboard-chart {
        flex-wrap: wrap;
        .charts {
            width: 100% !important;
            .revenue-chart {
                .w-25 {
                    width: 60% !important;
                }
            }

            &.chart-right {
                margin-top: 10px !important;
                margin-bottom: 20px;
            }
        }
        .revenue-graph-height {
            height: auto;
        }
    }
    .property-status-table {
        .w-25 {
            width: 45% !important;
        }
        h4 {
            margin-bottom: 0 !important;
        }
        table tr {
            td,
            th {
                font-size: 11px !important;
                padding: 6px 1px !important;
            }
        }
        &.revenue-graph-table {
            table tr {
                td,
                th {
                    font-size: 11px !important;
                    padding: 6px 5px !important;
                }
            }
        }
    }
    .fc .fc-daygrid-event {
        width: 21px;
        height: 14px;
        line-height: 4;
        margin: 1px !important;
        border: 0;
    }
}

.dashboard-page {
    .admin-dashboard-wrapper {
        column-gap: 15px;
        flex-wrap: wrap;

        .dashboard-left {
            width: 100%;
            .chart-box {
                width: 49.3%;
            }
        }

        .dashboard-center {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 15px 0;
            .s-width {
                flex: 0 0 18.8% !important;
            }
            .status-box {
                width: 22%;
            }
            .center-status-view {
                width: 77%;
                padding-bottom: 0 !important;
                padding-top: 10px !important;
                h4 {
                    margin-bottom: 10px !important;
                }
            }
        }

        .dashboard-right {
            width: 100%;
            max-height: 535px;
            height: 100%;
            overflow: hidden;
            overflow-y: auto;
            margin: 15px 0;
        }

        .center-status-view {
            background-color: var(--white-bg);
            padding: 12px 10px;
            border-radius: 6px;
        }
        .chart-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .chart-box {
            background-color: var(--white-bg);
            padding: 10px;
            border-radius: 6px;
            // min-height: 300px;
            //max-height: 430px;
            width: 48.5%;
            margin-bottom: 15px;
            // canvas {
            //     height: 95% !important;
            // }
        }
        .status-box {
            background-color: var(--white-bg);
            padding: 0px;
            border-radius: 6px;
            //box-shadow: 0px 2px 10px 0px rgba(58, 53, 65, .5);
            display: flex;
            justify-content: space-between;
            .status-left {
                padding: 10px 0 10px 10px;
                width: 60%;
            }
            .status-right {
                padding-bottom: 20px;
                width: 40%;
            }
            .status-chart {
                height: 100px;
                //width: 100px;
                position: relative;
                p {
                    position: absolute;
                    bottom: -10px;
                    left: 0px;
                    width: 100%;
                    text-align: center;
                    font-weight: 600;
                    font-size: 10px;
                    text-transform: uppercase;
                    margin-bottom: 0px;
                    line-height: 1.5;
                }
            }
        }
    }
}

.gridjs-table-no-border {
    .gridjs .gridjs-wrapper {
        border: 0 !important;
        .gridjs-th,
        .gridjs-td {
            border: 0 !important;
        }
    }
}

// region-area-config page css
.region-area-config-wrap {
    .region-area-config {
        &.tab-container {
            .modal-heading {
                background-color: var(--primary-bg-color);
                .form-check-label {
                    color: var(--white-bg);
                }
                .all-colps-icon {
                    color: var(--white-bg);
                    font-size: 27px;
                    line-height: 6px;
                    cursor: pointer;
                }
            }
        }
        .active-button
        {
            background: var(--primary-btn-hover);
            color: var(--text-white) !important;
        }
        ul {
            list-style: none;
            margin: 0;
            li {
                display: block !important;
                margin-top: 15px !important;
                margin-bottom: 10px !important;
                font-size: 13px;

                .form-check-input {
                    margin-right: 10px;
                    margin-top: 0;
                }
            }
        }
        .accordion-button {
            font-size: 13px !important;
            padding: 7px !important;
            .form-check-input {
                margin-right: 10px;
                margin-top: 0;
            }
        }
        .modal-content-card {
            padding-bottom: 0 !important;
        }
        .main-access-body {
            border: 2px solid var(--primary-bg-color);
        }
    }
    .movable-item-wrap {
        width: 100%;
        background-color: var(--white-bg);
    }
    .movable-item {
        display: flex;
        width: 70%;
        margin: 10px auto;
        padding: 10px;
        justify-content: space-between;

        select {
            width: 100%;
            padding: 10px;
            min-height: 135px;
            font-size: 13px;
            background: #e7f1ff;
        }
        .listbox-left {
            width: 40%;
        }
        .listbox-right {
            width: 40%;
        }
        .listbox-buttons {
            width: 15%;
            .btn {
                margin: 3px 0;
            }
        }
    }

    .is-overide-box {
        background-color: #47749f;
        padding-top: 10px;
        .form-check {
            margin-bottom: 10px;
            .form-check-label {
                color: #fff;
            }
        }
        h4 {
            font-size: 15px;
            padding: 0px 10px;
            color: #fff;
            margin-bottom: 0;
        }
    }
}

.salef-checkbox {
    flex: 0 0 10% !important;
}

// board assignment page css for next sprint 24

.boards-search {
    justify-content: space-between;
    .search-container {
        width: 49%;
        background-color: var(--white-bg);
        .board-info {
            position: absolute;
            bottom: 10px;
            right: 10px;
            cursor: pointer;
            font-size: 20px;
        }
        .split-input {
            flex: 1;
        }
        .btn {
            min-width: 112px;
        }
    }
}

.all-boards-box {
    flex-wrap: wrap;
    justify-content: space-between;

    .board-box {
        padding: 10px;
        border-radius: 4px;
        background-color: #5089bf;
        width: 49%;
        margin: 5px 0;
        margin-bottom: 10px;
        .board-box-header {
            margin-bottom: 10px;
            .title {
                color: var(--white-text-color);
                .one {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1;
                }
            }
            .count-x {
                align-items: center;
                background-color: var(--white-bg);
                border-radius: 50px;
                color: var(--danger);
                display: inline-flex !important;
                font-size: 11px;
                font-weight: 400;
                justify-content: center;
                line-height: 10px;
                margin-left: 5px;
                min-width: 18px;
                padding: 4px !important;
                text-align: center;
                white-space: nowrap;
            }
        }
        .box-search {
            background-color: var(--white-bg);
            padding: 10px;
            margin: 5px 0;
            border: 4px;
            .select__control {
                min-height: 30px;
                .select__value-container {
                    height: 30px !important;
                }
                .select__indicator {
                    padding: 0;
                }
                .select__input-container {
                    margin: 0;
                    padding-top: 0;
                }
                .select__placeholder {
                    font-size: 12px;
                    text-align: left;
                }
                .select__single-value {
                    font-size: 12px;
                }
            }
        }
        .board-list {
            background-color: var(--white-bg);
            padding: 10px;
            margin: 0px 0;
            border: 4px;
        }
        .table tr {
            th {
                padding: 5px;
            }
            td {
                padding: 5px;
                .btn {
                    font-size: 12px;
                    min-width: 44px;
                    padding: 2px 5px;
                }
            }
        }
    }
}

//assignment progress task css

.assignment-outer-wrap {
    background: var(--white-bg);
    padding: 10px;
    margin: 10px 0;
    .table tr {
        th,
        td {
            padding: 5px;
            font-size: 12px;
        }
    }
}

.assignment-progress-btn {
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: fixed !important;
    right: 215px;
    bottom: 16px;
    z-index: 998;
    padding: 0 !important;
    min-width: 50px;
    font-size: 21px;
}

.edit-apt-task-modal {
    .form-control {
        padding: 5px;
        height: 26px;
    }
}

.count-editap {
    align-items: center;
    background-color: var(--danger);
    border-radius: 50px;
    color: var(--white-text-color);
    display: inline-flex !important;
    font-size: 11px;
    font-weight: 400;
    justify-content: center;
    line-height: 10px;
    margin-left: 5px;
    min-width: 18px;
    padding: 4px !important;
    text-align: center;
    white-space: nowrap;
}

.daily-mutli-rows {
    margin-bottom: 10px;
    column-gap: 20px;
    .input-daily {
        width: 38%;
    }
    .input-daily-total {
        width: 40%;
    }
    .input-daily-btn {
        width: 40%;
        .btn {
            min-width: 20px;
        }
    }
    &.border-top-1 {
        border-top: 1px solid var(--border-color-c);
    }
}

.baseline-table {
    table {
        tr {
            td {
                padding: 5px;
            }
        }
    }
    .form-control {
        height: 30px;
    }
    .btn {
        min-width: 20px;
    }
}

.sticky-wrapper {
    max-height: calc(100vh - 220px);
    overflow-y: auto;
    position: relative;
    .edit-appt-btns {
        background-color: #fff;
        border-top: 1px solid #b8cce4;
        bottom: -2px;
        padding: 7px 0 !important;
        position: -webkit-sticky;
        position: sticky;
        width: 100%;
    }
}

.quicbook-sub-table {
    .table {
        background-color: var(--white-bg);
        tr {
            th {
                font-size: 12.5px;
            }
            td {
                padding: 5px 10px;
                color: var(--body-text-color);
                font-size: 12.5px;
                font-weight: 400;
                .select__control {
                    min-height: 25px;
                    .select__value-container {
                        height: 25px !important;
                    }
                    .select__indicator {
                        padding: 0;
                    }
                    .select__input-container {
                        margin: 0;
                        padding-top: 0;
                    }
                    .select__placeholder {
                        font-size: 12px;
                        text-align: left;
                    }
                    .select__single-value {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}


// common area
@media (min-width: 768px) {
    .w-md-100 {
        width: auto !important;
    }
}
.common-area-upload-template {
    .search-container {
        display: flex;
        column-gap: 15px;
        .card {
            flex: 1;
            padding: 10px;
            &+.card {
                flex: 0 0 30%;
            }
            .dropzone {
                margin-top: 0;
            }
        }
    }
}
///// Housekeeper performance report

.housekeeper-performance {
    .modal-content-card {
        padding: 20px 20px 0;
    }
    .hp-wrapper {
        display: flex;
        column-gap: 15px;
        justify-content: space-between;
        .box {
            background-color: var(--white-bg);
            border-radius: 4px;
            padding: 10px;
            flex: 1;
            text-align: center;
            color: #fff;
            border: 1px solid #000;

            &.color-one {
                background-color: #f99719;
                border-color: #f99719;
            }
            &.color-two {
                background-color: #38cab3;
                border-color: #38cab3;
            }
            &.color-three {
                background-color: #2a72b5;
                border-color: #2a72b5;
            }
        }
    }
}

.common-area-edit-template {
    .template-btn {
        font-size: 11.5px;
        padding: 2px 5px;
        margin-bottom: 7px;
        &.btn-plus {
            font-size: 20px;
    min-width: 50px;
        }
    }
    
    .all-btns-wrap {
        display: flex;
        flex-wrap: wrap;
        column-gap: 5px;
    }
}
////// unit status view page css

.unit-status-view-table {
    .calender-overlay-mobile {
        display: none;
    }
    .react-datepicker-wrapper {
        width: 120px;
    }
    .current-active-date {
        background: #ffffa8 !important;
    }
    .bg-white {
        background-color: #ffffff;
    }
    .current-date {
        background-color: #ffffff;
        padding: 5px 10px;
        font-size: 13px;
        font-weight: 600;
    }
    .today-btn {
        width: 70px;
        flex: auto;
        min-width: 70px;
        font-size: 12px;
        line-height: 15px;
        padding: 6px 8px;
    }
    .cal-btns {
        .btn.btn-light {
            border-color: #d9d5d5 !important;
            width: 30px;
            flex: auto;
            min-width: 30px;

            line-height: 15px;
            padding: 6px 8px;

            &.with-text {
                width: 70px;
                flex: auto;
                min-width: 70px;
                font-size: 12px;
                background-color: #fff !important;
            }
        }
    }
    .total-items-count {
        font-weight: 500;
        font-size: 13px;
        padding: 0px;
        padding-bottom: 0;
        .count {
            align-items: center;
            background-color: var(--primary-bg-color);
            border-radius: 50px;
            color: #fff;
            display: inline-flex !important;
            font-size: 11px;
            font-weight: 400;
            justify-content: center;
            line-height: 10px;
            margin-left: 5px;
            min-width: 18px;
            padding: 4px !important;
            text-align: center;
            white-space: nowrap;
            z-index: 9999;
        }
    }
    table {
        white-space: nowrap;
        margin: 0;
        border-collapse: collapse;
        //border-spacing: 1.5px;
        // border-collapse: separate;
        // border-spacing: 1.5px;
        background: #e6e6e6;
        table-layout: fixed;
        width: auto;
        max-width: 100%;
        min-width: 100%;
    }
    tbody {
        tr {
            td {
                background: white;
                // padding: 5px;
                // padding-left: 10px;
                min-width: 150px;
            }
            th {
                &::after {
                    border-right: 1px solid #e6e6e6;
                    border-bottom: 0;
                    border-top: 0;
                }
            }
        }
    }
    table th {
        padding: 5px;
        padding-left: 10px;
        min-width: 150px;
        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            border-right: 1px solid #e6e6e6;
            border-bottom: 1px solid #e6e6e6;
            border-top: 1px solid #e6e6e6;
        }
    }
    table thead th {
        padding: 3px;
        color: var(--sidebar-icon-color);
        position: sticky;
        top: 0;
        z-index: 2;
        text-align: center;
        //width: 25vw;
        background: white;
        font-size: 13px;
    }
    table td {
        color: #000;
        padding: 0px 0px;
        text-align: center;
        table {
            min-height: 52px;
        }
        .border-td {
            border-right: 1px solid #e6e6e6;
        }
        .button-plus {
            //background-color: var(--primary-bg-color);
            background-color: #ccc;
            color: #ffffff;
            border-radius: 2px;
            width: 14px;
            height: 14px;
            font-size: 10px;
            line-height: 12px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        .wc {
            background-color: #807777;
            color: #ffffff;
            border-radius: 2px;
            width: 20px;
            height: 20px;
            font-size: 12px;
            line-height: 12px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        .appt {
            //background-color: #f64c4c;
            background-color: #313131;
            color: #ffffff;
            border-radius: 2px;
            width: 20px;
            height: 20px;
            font-size: 10px;
            line-height: 12px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        .ta {
            color: #ffffff;
            border-radius: 2px;
            width: 15px;
            height: 20px;
            font-size: 12px;
            line-height: 12px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        .green {
            background-color: #43aa8c;
        }
        .red {
            background-color: #f94045;
        }

        .event {
            height: 30px;
            line-height: 30px;
            font-size: 11.3px;
            display: block;
            margin: 1px 0 2px !important;
            border-radius: 2px;
            background-color: #91c33b;
            position: relative;
            cursor: pointer;
            .service-text {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                max-width: 144px;
            }
            &.percentage {
                width: 48%;
            }
            &.percentage2 {
                width: 40%;
            }
            &.orange {
                background-color: #ecac5e;
            }
            &.yellow {
                background-color: #f1eb39;
            }
            &.black {
                background-color: #ccc;
            }
            &.blue {
                background-color: #90d0f5;
            }
            .right-count {
                position: absolute;
                right: 1px;
                top: 1px;
                z-index: 1;
            }
            .right-plus-bt {
                position: absolute;
                right: 1px;
                bottom: 1px;
                z-index: 1;
                border-radius: 2px;
                width: 10px;
                height: 10px;
                font-size: 8px;
                line-height: 9px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                z-index: 1;
                margin-left: 3px;
                background-color: var(--primary-bg-color);
                color: #ffffff;
            }

            .count {
                background-color: #c30606;
                color: #ffffff;
                border-radius: 2px;
                width: 10px;
                height: 10px;
                font-size: 8px;
                line-height: 9px;
                display: inline-block;
                cursor: pointer;
                position: relative;
                z-index: 1;
                margin-left: 3px;
                &.right {
                    position: absolute;
                    right: 1px;
                    top: 1px;
                }

                &.left {
                    position: absolute;
                    left: 1px;
                    top: 1px;
                }
                &.wc {
                    background-color: #807777;
                    color: #ffffff;
                }
            }
        }
    }
    .th-count-wrap {
        padding: 7px;
        .th-count {
            background-color: #c30606;
            color: #ffffff;
            border-radius: 2px;
            width: 30px;
            height: 20px;
            font-size: 13px;
            line-height: 20px;
            display: inline-block;
            position: relative;
            z-index: 5;
        }
    }

    table tbody th {
        text-align: left;
        position: relative;
        background: white;
    }
    table thead th:first-child {
        position: sticky;
        left: 0;
        z-index: 3;
        background-color: #fff;
        min-width: 129px;
    }
    table tbody th {
        position: sticky;
        left: 0;
        z-index: 2;
        color: var(--sidebar-icon-color);
        vertical-align: middle;
    }

    [role="region"][aria-labelledby][tabindex] {
        width: 100%;
        max-height: 98vh;
        overflow: auto;
    }

    .event-details {
        position: relative;
        z-index: 3;
        .event-name {
            color: var(--sidebar-icon-color);
        }
        .event-icon {
            display: flex;
            margin-left: 0px;
            // span {
            //     font-size: 18px;
            //     margin: 0 3px;
            //     &.red {
            //         color: #c30606;
            //     }
            //     &.orange {
            //         color: #ecac5e;
            //     }
            //     &.yellow {
            //         color: #f1eb39;
            //     }

            //     &.green {
            //         color: #91c33b;
            //     }

            //     &.black {
            //         color: #000000;
            //     }
            // }
            span {
                width: 18px;
                height: 18px;
                align-items: center;
                border-radius: 50%;
                color: #fff;
                cursor: pointer;
                display: inline-flex;
                justify-content: center;
                margin-right: 3px;
                svg {
                    font-size: 11px;
                }
                &.greenbg {
                    background-color: #38cab3;
                }
                &.redbg {
                    background-color: #f58da4;
                }
                &.greybg {
                    background-color: #a5a0b1;
                }
                &.blackbg {
                    background-color: #4c4242;
                }

                &.setting {
                    svg {
                        font-size: 10px !important;
                    }
                }
            }
        }
    }
}

.unit-status-view-table {
    .calender-legends {
        display: flex;
        justify-content: center;
        width: 100%;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            li {
                padding: 5px 15px 0 0;
                .dot {
                    width: 10px;
                    height: 10px;
                    display: inline-block;
                    margin-right: 6px;
                    border-radius: 50%;
                    position: relative;
                    top: 2px;

                    &.red-s {
                        //background-color: #f58da4;
                        background-color: #f94045;
                    }

                    &.light-blue {
                        background-color: #ceaeef;
                    }

                    &.orange {
                        //background-color: #e0c78c;
                        background-color: #f99719;
                    }

                    &.dark-blue {
                        background-color: #277da2;
                    }

                    &.green {
                        background-color: #43aa8c;
                    }
                    &.maroon {
                        background-color: #88300087;
                    }
                    &.grey {
                        background-color: #a5a0b1;
                    }
                    &.black {
                        background-color: #4c4242;
                    }
                    & + span {
                        font-size: 11px;
                        color: var(--input-text-color);
                    }
                }
            }
        }
    }
    .calender-legends-same-line {
        display: flex;
        border-bottom: 1px solid #e6e6e6;
    }

    .month-view-table {
        th {
            min-width: 85px;
            white-space: normal;
            word-break: break-all;
            padding: 1px;
            font-size: 12px;
            .edit-link-blue {
                font-size: 12px;
            }
            .event-icon {
                margin-top: 5px;
            }
            .th-count-wrap .th-count {
                min-width: 16px;
                height: 16px;
                font-size: 10px;
                line-height: 16px;
            }
        }
        td {
            .button-plus {
                width: 15px;
                height: 15px;
                font-size: 10px;
            }
            .appt {
                width: 20px;
                height: 15px;
                font-size: 10px;
            }
            .event {
                height: 27px;
                line-height: 27px;
                font-size: 10.3px;
            }
            .wc {
                width: 15px;
                height: 15px;
                font-size: 10px;
            }
            .ta {
                width: 12px;
                height: 15px;
                font-size: 10px;
            }
        }
        .unit-status-sub-table .f-td div {
            margin: 0 1px;
        }
    }
}

// css added by brinda for unit status view

.unit-status-sub-table {
    border-spacing: 0 !important;
    width: 100% !important;
    .event {
        width: 99%;
        text-overflow: ellipsis;
        overflow: hidden;
        transform: skew(-20deg);
        margin: 0 2px !important;
    }
    .event-ta-custom {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        transform: skew(-20deg);
        margin: 0 0px;
        font-size : 11.3px;
        height: 30px;
        line-height: 30px;
        display: block;
    }
    td {
        // padding: 0 !important;
    }
    .f-td {
        display: flex;
        justify-content: center;
        padding: 4px 0;
        align-items: center;
        div {
            margin: 0 2px;
        }
    }
    .ta-table-strip {
        display: flex;
        column-gap: 5px;
        align-items: center;
    }

    .ta-table-week-strip {
        // padding : 0 4%;
    }

    .ta-table-month-strip {
        // padding: 0 2%;
    }
}

.custom-scrollbar {
    background: #f5f5f5;
    overflow-y: scroll;
    margin-bottom: 25px;
}

#style-2::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
	border-radius: 0px;
	background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar
{
	width: 16px;
	background-color: transparent !important;
}

#style-2::-webkit-scrollbar-thumb
{
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.3);
	background-color: #293072;
}

#loading-bar-spinner.spinner {
    left: 50%;
    margin-left: -20px;
    top: 50%;
    margin-top: -20px;
    position: absolute;
    z-index: 19 !important;
    animation: loading-bar-spinner 400ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
    width: 40px;
    height: 40px;
    border:  solid 4px transparent !important;
    border-top-color:  rgb(4, 5, 5) !important;
    border-left-color: rgb(4, 5, 5) !important;
    border-radius: 50%;
}

@keyframes loading-bar-spinner {
  0%   { transform: rotate(0deg);   transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
}

/* styles.css */
.loader-container {
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0; /* Optional background color for the loader */
}

.loader {
    width: 50px; /* Set the width of the loader image */
    height: 50px; /* Set the height of the loader image */
    animation: spin 2s linear infinite; /* Apply a rotation animation */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.dashboard-analytics{
    justify-content: start !important;
}