@import "../styles/colors.scss";

.main-header {
  z-index: 1000 !important;
  width: 100% !important;
  left: 0;
  right: 0;
  top: 0;
  position: fixed !important;
  z-index: 3 !important;
  padding-left: 290px !important;
  background-color: var(--primary-header-bg);
  box-shadow: 0 7px 26px #9a9acc1a;
  height: 64px;
  margin-bottom: 20px;
  display: flex;
  border-bottom: 1px solid var(--header-border-color);
  transition: left 0.32s ease;
  .header-icon {
    width: 30px;
    height: 30px;
    border-radius: 25px;
    text-align: center;
    font-size: 20.8px;
    color: var(--header-icon-color);
    cursor: pointer;
  }

  // header right side menu

  .navbar-nav-icons {
    .nav-item {
      a {
        display: block;
        font-size: 22px;
        color: var(--header-icon-color);
        position: relative;
        line-height: 33px;
        outline: none;
        height: 34px;
        width: 34px;
        margin: auto 6px !important;
        text-align: center;
        transition: 0.3s color;
        border-radius: 25px;
        svg {
          width: 18px;
          height: 18px;
          color: var(--header-icon-color);
        }
        .header-badge {
          position: absolute;
          top: 1px;
          right: 1px;
          display: block !important;
          min-width: 14px;
          padding: 2px !important;
          font-size: 9px;
          z-index: 9999;
          border-radius: 50px;
          line-height: 10px;
          text-align: center;
          white-space: nowrap;
          font-weight: 400;
          transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        }
      }
      &.profile-menus {
        padding: 0 10px;
        a {
          padding: 0;
          img {
            border-radius: 50%;
            margin-left: 0;
            width: 34px;
            height: 34px;
            box-shadow: 0 4px 15px #dbe4f9;
          }
        }
      
        .main-img-user::after {
          content: none !important;
        }
        .dropdown-toggle {
          display: block;
          font-size: 16px;
          color: var(--header-icon-color);
          position: relative;
          line-height: 33px;
          outline: none;
          height: 34px;
          width: 34px;
          margin: auto 6px !important;
          text-align: center;
          transition: 0.3s color;
          border-radius: 25px;
          overflow: hidden;
          min-width: 34px;
          border: 1px solid !important;
          padding: 0;
          background-color: transparent;

          &.btn {
            &::after {
              content: none;
            }
            &:hover,
            &:focus {
              outline: none !important;
              //border: none !important;
              box-shadow: none;
            }
          }
          img {
            max-width: 100%;
          }
        }
        .dropdown-menu {
          background-color: var(--header-dropdown-bg);
          width: 230px;
          border: 1px solid var(--header-border-color) !important;
          background-clip: padding-box;
          border-radius: 3px !important;
          box-shadow: 0 16px 18px #8787b61a !important;
          padding: 0;
          a {
            position: relative;
            padding: 10px 5px 10px 10px;
            height: 38px;
            display: flex;
            align-items: center;
            font-size: 13px;
            text-decoration: none;
            width: 100%;
            color: var(--header-icon-color);
            border-top: 1px solid var(--header-border-color) !important;
            border-radius: 0;
            margin: auto !important;
            .menu-icon {
              font-size: 17px;
              margin-right: 10px;
              width: 24px;
              text-align: center;
              color: var(--header-icon-color);
            }
            .menu-icon-label {
              line-height: 1.2;
              text-align: left;
            }
            &:hover {
              background-color: transparent;
              color: var(--header-dropdown-hover-color) !important;
            }
          }
          .language-select {
          padding: 10px;
          }
          .d-user-wrap {
            display: flex;
            padding: 16px 10px;
            color: var(--dropdown-title-text);
            .main-img-user {
              @extend .dropdown-toggle;
            }
            h6 {
              font-size: 15px !important;
              line-height: 1;
              font-weight: 600;
            }
            span {
              font-size: 12px;
              opacity: 0.6;
            }
          }
        }
        .dropdown-menu.show {
          right: 0;
          left: auto;
        }
      }
      &.setting {
        @extend .profile-menus;
        padding: 0;
        .dropdown-menu {
          padding-top: 0;
          width: auto;
          max-width: 230px;
          a:first-of-type {
            border-top: 0 !important;
          }
        }
      }
      &.billing {
        .header-badge {
          right: 1px !important;
          top: 1px !important;
        }
      }
      &.help-header-icon {
        font-size: 17px;
        color: var(--header-icon-color);
      }

      &.notification {
        .circle {
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: #10b197;
          box-shadow: 0px 0px 1px 1px #12b5711a;
          position: absolute;
          top: 6px;
          right: 7px;
        }
        .header-badge {
          right: -3px !important;
        }

        .pulse {
          animation: pulse-animation 1s infinite;
        }

        @keyframes pulse-animation {
          0% {
            box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
          }
          100% {
            box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
          }
        }
      }
    }
  }
  .navbar-nav-icons .nav-item.profile-menus {
    .header-dropdown-submenu {
      .dropdown-toggle {
        position: relative;
        padding: 10px 10px;
        height: 38px;
        display: flex;
        align-items: center;
        font-size: 13px;
        text-decoration: none;
        width: 100%;
        border: 0 !important;
        color: var(--header-icon-color);
        border-top: 1px solid var(--header-border-color) !important;
        border-radius: 0;
        margin: auto !important;
        .menu-icon {
          font-size: 17px;
    margin-right: 10px;
    width: 24px;
    text-align: center;
    color: var(--header-icon-color);
    &.menu-icon-right {
      font-size: 20px;
    }
        }
        &:hover, &:focus {
          color: var(--header-dropdown-hover-color) !important;
        }
      }
    }
  }
}

.sidebar-toggled {
  .main-header {
    padding-left: 80px !important;
  }
}

@media (max-width: 441px) {
  .main-header .navbar-nav-icons .nav-item.setting {
    display: none;
  }
  .main-header .navbar-nav-icons{
    flex-wrap: wrap;
    .nav-item {
      &.daily-closeout-li {
        order: 1;
        padding: 5px 0;
      }
    }
  }
  .main-header .navbar-nav-icons {
    min-height: 40px !important;
    height: auto !important;
    .nav-item.profile-menus .dropdown-toggle {
      line-height: 26px;
      height: 26px;
      width: 26px;
      min-width: 26px;
      border-radius: 50%;
      margin: auto 0px !important;
    }
  } 
}
