@import '../styles/colors.scss';
.form-group {
    //margin-bottom: 16px;
    position: relative;
    margin-bottom: 10px;
    // new margin addedd for new lable implement
    // margin-bottom: 10px;
    // margin-top: 10px;
    .form-label {
      color: var(--input-label-color);
      //font-size: 13.0509px;
      line-height: 19px;
      margin-bottom: 5px;
      // new added for label on top textbox
    //   font-size: 12px;
    //   position: absolute;
    //   background: #ffffff;
    // padding: 0 5px;
    // left: 8px;
    // top: -12px;
    // color: #878686;
    // z-index: 1;
    }
    &.required  {
        .form-label::after {
          content: "*";
          font-size: 14px;
          color: var(--text-danger);
          font-weight: 500;
          margin-left: 2px;
        }
    }
    &.td-width-150 {
      width: 150px;
      margin-bottom: 0;
       @media (max-width: 441px) {
width: 100%;
      }
    }

    &.td-width-280 {
      width: 280px;
      margin-bottom: 0;
       @media (max-width: 441px) {
width: 100%;
      }
    }
  }

  .invalid-feedback {
    font-size: 12px;
    color: var(--validation-text-color);
  }

  .form-control {
    color: var( --input-text-color) !important;
    font-size: 14px;
    line-height: 25px;
    font-weight: 500;
    border: 1px solid  var(--menu-border-color) !important;
    padding: 5px 12px;
    border-radius:4px;
    background-color: var(--input-bg);
    &:focus {
      background-color: transparent !important;
      box-shadow: none !important;
      outline: none !important;
    }
}


.form-control[readonly]  {
  background-color: var(--input-readonly) !important;
  color: var(--input-readonly-text) !important;
  font-weight: 400;
  &:focus {
    background-color: var(--input-readonly) !important;
  }
}

.form-control[readonly]:focus  {
background-color: var(--input-readonly) !important;
}


// placeholder color
.form-control::-webkit-input-placeholder {
    color: var( --grey-text-color);
    opacity: .6;
    font-weight: 400;
    font-size: 13px;
  }
  .form-control:-ms-input-placeholder {
    color: var( --grey-text-color);
    opacity: .6;
    font-weight: 400;
    font-size: 13px;
  }
  
  .form-control::placeholder {
    color: var( --grey-text-color);
    opacity: .6;
    font-weight: 400;
    font-size: 13px;
  }
  // .form-control[readonly] {
  //   opacity: .6;
  // }

  .form-control:disabled {
color: var(--form-control-disabled) !important;
  }
  

  .form-switch {
    &.custom-toggle {
      .form-check-input {
        height: 20px;
        width: 40px;
      }
    }
  }

  .react-tel-input .form-control {
    width: 100% !important;
    color: var(--input-text-color) !important;
    font-size: 14px;
    line-height: 25px;
    font-weight: 500;
    height: auto !important;
  }

  .css-319lph-ValueContainer {
    height: 30px!important;
    .css-1e8x2zg-Input {
      padding: 8px !important;
    }
  }

  .css-qc6sy-singleValue {
    color: var(--input-text-color) !important;
    font-size: 14px;
  }

  .rc-time-picker-panel {
    z-index: 1090 !important;
  }


  .react-datepicker-wrapper {
    width:100%;
    .react-datepicker__input-container {
      input {
        width: 100%;
        color: var( --input-text-color) !important;
        font-size: 14px;
        line-height: 25px;
        font-weight: 500;
        border: 1px solid  var(--menu-border-color) !important;
        padding: 5px 12px;
        border-radius:4px;
        background-color: var(--input-bg);
        &:focus {
          background-color: transparent !important;
          box-shadow: none !important;
          outline: none !important;
        }
        &[readonly] {
            background-color: var(--readonly);
            &:focus {
              background-color: var(--readonly) !important;
            }
        }
        &[disabled]{
          background-color: var(--readonly);
          color: #909090 !important;
          &:focus {
            background-color: var(--readonly) !important;
          }
      }
      }
    }
    
  }

  .react-datepicker {
    .react-datepicker__navigation {
      top:7px
    }
    .react-datepicker__day--outside-month {
      color: #ccc !important;
    }
  }

  // .react-datepicker {
  //   .react-datepicker__day--disabled {
  //     color: #000 !important;
  //   }
  // }
  


  .edit-appointment {
    .react-datepicker-wrapper {
      width:100%;
      .react-datepicker__input-container {
        input {
          //padding: 0px 2px !important;
          width:80%;
          font-size: 11px !important;
        }
      }
    }
    
    .rc-time-picker {
      width:80%;
      input {
        //padding: 0px 2px !important;
        font-size: 11px !important;
      }
      .rc-time-picker-clear {
        width: 12px;
        right: 9px;
        top:1px !important;
      }
    }
    .form-group.select-updated .select__control {
      box-shadow: 0 0 2px 1px green !important;
    }
  }

  .react-datepicker-popper {
    z-index: 2 !important;
  }
 

  #root-portal {
    .react-datepicker-popper {
      z-index: 9999 !important;
    }
   
  }

  .select__control {
    background-color: var(--white-bg) !important;
  }
  .select__placeholder {
    color: var(--grey-text-color) !important;
  }
  .select__menu {
    background-color: var(--white-bg) !important;
    .select__option { 
      color: var(--text-dark-color) !important;
      &.select__option--is-focused {
        color: var( --text-dark-color) !important;
      }
      &.select__option--is-selected {
        background-color: #deebff;
      }
    }
  }
  

  // select dropdown css which is used in table 

  .small-size-select .select__menu .select__option {
    padding: 2px 8px !important;
    font-size: 12px !important;
}
.replace-is-modal {
  .form-control {
      padding: 1px 5px;
      line-height: 23px;
      text-align: center;
  }
}

.inventory-smart-list, .inventory-smart-template-wrap {
 
  .select__control {
    min-height: 25px;
    .select__value-container {
        height: 25px !important;
    }
    .select__indicator {
        padding: 0;
    }
    .select__input-container {
        margin: 0;
        padding-top: 0;
    }
    .select__placeholder {
        font-size: 12px;
        text-align: left;
    }
    .select__single-value {
        font-size: 12px;
    }
}
}

    // disabled dropdown

    .select--is-disabled {
      .select__control.select__control--is-disabled {
        background-color: var(--disabled) !important;
      }
    }

    .StripeElement.StripeElement--empty + .invalid-feedback{
      display: block !important;
    }


    .sm-select.select__menu-portal {
      .sm-select.select__option {
        font-size: 12px;
        padding: 5px 10px;
      }
    }

    .select__clear-indicator {
      padding: 6px !important;
    }

.unit-status-view-table {
  .react-datepicker-popper {
    z-index: 6 !important;
  }
}
    
.show-message {
  font-size: 12px;
  color: green;
}

// .form-check-input {
//   width: 14px !important;
//   height: 14px !important;
// }
.form-check-input[type="checkbox"] {
  border-color: var(--text-dark-color) !important;
}

input[type='checkbox'] {
  accent-color: #0075ff;
}

table tr td {
  input[type="checkbox"] {
    width: 14px !important;
    height: 14px !important;
    border-color: var(--text-dark-color) !important;

  }
}

table tr th.gridjs-th .gridjs-th-content {
  .form-check-input[type="checkbox"] {
    border-color: var(--text-dark-color) !important;
    width: 14px !important;
    height: 14px !important;
  }
  
}
.gridjs .gridjs-wrapper table.gridjs-table td.gridjs-td {
  .custom-toggle.form-switch {
    .form-check-input {
    height: 20px !important;
    width: 40px !important;
    }
    .form-check-input:checked, .form-check-input:checked {
      border-color: var(--primary-btn-border) !important;

    }
  }
}

input[type="radio"] {
  
  border-color: var(--text-dark-color) !important;

}

.property-photo-checkbox {
  display: flex;
  justify-content: space-between;
  .form-check-input[type="checkbox"] {
    border-color: var(--text-dark-color) !important;
    width: 18px;
    height: 18px;
  }
}


