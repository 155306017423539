//@import '../../styles/colors.scss';

.btn-link {
  text-decoration: none;
}
.btn {
  border: 1px solid transparent;
  padding: 7px 12.48px;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 3px;
  min-width: 80px;
  &.btn-primary {
    color: var(--primary-btn-text-color);
    background-color: var(--primary-btn-bgcolor);
    border-color: var(--primary-btn-border);
    position: relative;

    &:hover,
    &:focus {
      color: var(--primary-btn-text-color) !important;
      background: var(--primary-btn-hover);
      border-color: var(--primary-btn-hover);
    }
  }
  &:focus {
    outline: none;
    box-shadow: none !important;
  }

  &.btn-secondary {
    color: var(--secondary-btn-text-color);
    background-color: var(--secondary-btn-bgcolor);
    border: 1px solid var(--secondary-btn-border);
  }

  &.btn-success {
    color: var(--secondary-btn-text-color);
    background-color: var(--success-bg-color);
    border: 1px solid var(--success-border-color);;
  }

  &.btn-danger {
    background-color: var(--danger-btn-border-color) !important;
    border: 1px solid var(--danger-btn-border-color) !important;
    color: var(--text-white) !important;
    &.button-icon:focus {
      background-color: var(--danger-btn-border-color) !important;
      border: 1px solid var(--danger-btn-border-color) !important;
      color: var(--text-white) !important;
    }
  }

  &.btn-warning {
    background-color: var(--btn-warning-btn-bgcolor) !important;
    border: 1px solid var(--btn-warning-btn-border) !important;
    color: var(--text-white) !important;
    &.button-icon:focus {
      background-color: var(--btn-warning-btn-bgcolor) !important;
      border: 1px solid var(--btn-warning-btn-border) !important;
      color: var(--text-white) !important;
    }
  }

  &.btn-info {
    color: var(--text-white) !important;
  }

  &.btn-outline-primary {
    color: var(--primary-btn-ouline-text);
    background-color: transparent;
    border: 1px solid var(--primary-btn-ouline-border);
    // &:hover,
    // &:focus {
    //   color: var(--primary-btn-text-color);
    //   background:  var(--primary-btn-hover);
    //   border-color: var(--primary-btn-border);
    // }

    &:hover {
      background: var(--primary-btn-hover);
      color: var(--text-white) !important;
    }
    &:focus {
      color: var(--primary-btn-ouline-text);
    }
  }

  &.btn-outline-secondary {
    color: var(--secondary-border-color);
    background-color: transparent;
    border: 1px solid var(--secondary-border-color);
    &:hover {
      color: var(--text-white);
      background-color: var(--secondary-border-color);
      border: 1px solid var(--secondary-border-color);
    }
  }
  &.btn-outline-danger {
    border: 1px solid var(--danger-btn-border-color);
    color: var(--danger-btn-border-color);
    &:hover {
      color: var(--text-white);
    }
    &:focus {
      color: var(--danger-btn-border-color);
    }
  }

  &.btn-outline-warning {
    border: 1px solid var(--btn-warning-btn-border);
    color: var(--btn-warning-btn-border);
    &:hover {
      color: var(--text-white);
    }
    &:focus {
      color: var(--btn-warning-btn-border);
    }
  }

  &.btn-outline-success {
    border: 1px solid var(--success-btn-border-color);
    color: var(--success-btn-border-color);
    &:hover {
      color: var(--text-white);
    }
    &:focus {
      color: var(--success-btn-border-color);
    }
  }
  &.btn-outline-light {
    border-color: var(--btn-light-btn-border) !important;
    color: var(--dark-text-color) !important;
  }

  &.btn-light {
    background-color: var(--btn-light-btn-bgcolor) !important;
    border-color: var(--btn-light-btn-border) !important;
    color: var(--dark-text-color) !important;
  }

  &.button-icon {
    min-width: 42px !important;
    &.btn-sm {
      min-width: 30px !important;
      height: 28px;
    }
    &:focus {
      background-color: transparent !important;
    }
    &.btn-outline-primary {
      &:focus {
        color: var(--primary-btn-ouline-text) !important;
      }
    }
  }
  &.button-icon.btn-secondary {
    &:focus {
      background-color: var(--secondary-btn-bgcolor) !important;
    }
  }

  &.btn-link {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &.btn-text-link {
      color: $blue;
      padding: 3px 5px !important;
      min-width: auto !important;
      font-size: 20px !important;
      text-decoration: none;
      line-height: 20px !important;
      &:hover {
        text-decoration: none;
        color: $light-blue;
      }
    }
  }
  // &.btn-delete{
  //   &:hover {
  //     box-shadow: inset  0px 0px 3px 3px rgb(206, 206, 206 , 0.3);
  //   }
  // }
  // button size
  &.btn-sm {
    padding: 0.25rem 0.5rem !important;
    font-size: 12px !important;
    border-radius: 0.2rem !important;
  }
  // button size
  &.btn-xsm {
    padding: 5px 8px !important;
    font-size: 0.875rem !important;
    border-radius: 0.2rem !important;
    line-height: 15px !important;
    min-width: auto !important;
    img {
      height: 18px;
    }
  }
  &.btn-clear {
    padding: 0 !important;
    border: 1px solid var(--border-color-c);
    &:hover {
      text-decoration: none;
      background-color: #f3f3f3;
    }
  }

  &.btn-width-auto {
    min-width: auto !important;
  }
}

.btn-group {
  .btn {
    background-color: transparent;
    color: var(--primary-bg-color);
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .btn-check:checked + .btn-primary {
    background-color: var(--primary-bg-color);
    border: var(--primary-bg-border);
  }
  :not(.btn-check) + .btn {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
  }
}
.group-by-appt {
  .btn-group {
    .btn {
      padding: 7px 7px;
      min-width: 45px;
      font-size: 12px;
      border: 1px solid var(--primary-bg-border) !important;
      &:hover,
      &:active{
        color: var(--text-white)!important;
        outline: none;
        box-shadow: none;
      }
  
      &:focus {
        background-color: transparent;
        color: var(--primary-bg-color)!important;
        border:1px solid var(--primary-bg-border);
        outline: none;
        box-shadow: none;
      }
    }
  }
  .btn-group > .btn-check:checked + .btn:focus {
    border: 1px solid var(--primary-bg-border) !important;
    background-color: var(--primary-bg-color)!important;
    color: var(--text-white)!important;
  }
}




// responsive css starts
@media (max-width: 575.98px) {
  .btn {
    min-width: auto;
  }
}
// responsive css ends

.scroll-btn {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: fixed !important;
  right: 20px;
  bottom: 5px;
  z-index: 1049;
  padding: 0 !important;
  min-width: 50px;
  font-size: 21px;
  &.help-bottom {
    bottom: 80px;
  }
}

.btn-light-green {
  color: var(--text-white);
  background-color: var(--success-bg-color);
  border-color: var(--success-border-color);
  &:hover {
    color: var(--text-white);
    background-color: var(--success-bg-color);
    border-color: var(--success-border-color);
  }
}

.btn-default {
  color: var(--text-white);
  background-color: var(--default-color);
  border-color: var(--default-color);
  &:hover {
    color: var(--text-white);
    background-color: var(--default-color);
    border-color: var(--default-color);
  }
}

.btn-blue {
  color: var(--text-white);
  background-color: var(--blue-sky);
  border-color: var(--blue-sky-border);
  &:hover {
    color: var(--text-white);
  background-color: var(--blue-sky);
  border-color: var(--blue-sky-border);
  }
}

.tooltip {
  pointer-events: none !important;
  //position:fixed !important;
}
.popover{ 
  pointer-events:none !important; 
  //position:fixed !important;
}

.tooltip-arrow {
  display: none !important;
}

.stripe-connected-cards {
  text-align: center;
  .btn-group {
    // display: flex;
    // justify-content:space-around;
    .btn {
      //width: 22%;
      height: 240px;
      flex: inherit;
      width: 220px;
      margin: 10px;
      border-radius: 4px !important;
      padding: 0;
      border: 1px solid var(--btn-light-btn-border) !important;
      overflow: hidden;
      .radio-header {
        padding: 10px;
        //border-bottom: 1px solid var(--btn-light-btn-border);
        font-size: 14px;
        color: var(--dark-text-color);
        font-weight: 500;
        background-color: #f5f5f5;
      }
      .radio-body {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: calc(100% - 42px);
        padding: 10px;
      }
      &:hover {
        background-color: transparent;
        box-shadow: 1px 1px 6px 2px #d2e9ff !important;
      }
      &:focus {
        background-color: transparent;
        box-shadow: 1px 1px 6px 2px #d2e9ff !important;
      }
      h4 {
        color: var(--dark-text-color);
        font-size: 15px;
        flex: 1;
        margin-bottom: 15px;
      }
      h5 {
        color: var(--dark-text-color);
        font-size: 13px;
        flex: 1;
        font-style: italic;
        font-weight: normal;
      }
      p {
        color: var(--dark-text-color);
        flex: 1;
        font-size: 12px;
      }
      &.radio-dark-blue {
      }
    }
  }
  .btn-group .btn-check:checked + .btn-primary {
    background-color: transparent;
    border: 1px solid var(--primary-bg-border) !important;
    box-shadow: 1px 1px 6px 2px #d2e9ff !important;
  }
  .btn-group > :not(.btn-check:first-child) + .btn,
  .btn-group > .btn-group:not(:first-child) {
    margin-left: 0;
  }
}
.btn.button-icon.direct-action {
  min-width: 24px !important;
  padding: 0 3px;
}

.add-schedule-staff-table {
  table {
      tr {
          td {
              padding: 0.5rem 0.5rem;
              font-size: 13px;
          }
          th {
              padding: 0.3rem 0.5rem;
              font-size: 13px;
              font-weight: 500;
          }
      }
  }
  .btn.button-icon {
      padding: 0px !important;
      min-width: 25px !important;
      height: 25px !important;
      font-size: 12px !important;
      line-height: 23px;
  }
  .form-control {
      font-size: 12px;
      line-height: 22px;
      font-weight: 500;
      border: 1px solid var(--menu-border-color) !important;
      padding: 2px 7px;
      &:focus {
          background-color:var(--white-bg)  !important;
          box-shadow: none !important;
          outline: none !important;
      }
  }
  .form-control[readonly]  {
      background-color: var(--readonly) !important;
      &:focus {
        background-color: var(--readonly) !important;
      }
    }
    
  .select__control {
      min-height: 28px;
      height: 28px;
   
      .select__indicator {
          padding: 0;
      }
      .select__menu {
          .select__option {
              padding: 5px 8px;
              font-size: 12px;
          }
      }
      .select__placeholder {
          font-size: 12px;
      }
      .select__input-container {
          margin: 0 !important;
          padding-top: 0 !important;
          padding-bottom: 0 !important;
      }
      .select__value-container {
          height: 24px !important;
          min-height: 24px !important;
          padding-left: 2px;
          padding-right: 2px;
          &.select__value-container--is-multi {
              height: auto !important;
          }
      }
      .select__single-value {
          color: var(--text-dark-color);
          font-size: 12px;
      }
      .select__multi-value {
          margin: 0 !important;
          top: -1px !important;
          padding: 1px !important;
      }
      .select__multi-value__label {
          padding: 0px !important;
      }
  }
}


.dropdown-item.active, .dropdown-item:active {
  color: var(--text-white) !important;
}

.message-list-header {
  padding: 15px 0;

}

.message-btns {
  .btn {
    min-width: 20px;
    padding: 6px 12px;
    border: 0;
    //background-color: #f0f4f7;
    background-color: rgba(192, 214, 233, .68);
    color: #2c384af2;
    font-size: 12px;
    &:hover {
      background-color: #9bc5ee;
      color: #2c384af2 !important;
    }
    svg {
      font-size: 16px;
      color: #2c384af2;
      margin-right: 3px;
    }
  }
}

.tooltip {
  overscroll-behavior: contain;
}

.btn-group > .btn-check:checked + .btn {
  z-index: 0;
}

.accordion {
  .accordion-button:hover {
    z-index: 0;
  }
}

.btn.btn-secondary.no-hover-color {
  color: #fff !important;
}

.btn.button-icon.btn-outline-primary.email-appt-success, 
.btn.button-icon.btn-outline-primary.email-appt-success:focus {
  background-color: green !important;
  color: white !important;
  span.tick {
    color: white !important;
    display: inline-block !important;
  }
}