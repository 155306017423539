@import '../../styles/colors.scss';

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    width: 100%;
    left: 0;
    right: 0;
    background-color: var(--footer-bg);
    border-top: 1px solid var(--header-border-color);
    margin-top: auto;
    padding: 18px 0px;
    padding-left: 240px;
    z-index: 1;
  p {
    font-size: 14px;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    color: var(--footer-text);

  }
}