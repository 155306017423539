.modal-backdrop.show {
    z-index: 1050;

    & + div[role="dialog"][aria-modal="true"] {
        z-index: 1050;
    }
}

body.modal-open {
    padding-right: 0 !important;
}

.modal-open {
    padding: 0;
    .dropdown-menu.show {
        z-index: 1099;
    }
}

.modal {
    left: auto;
    right: 0;
    padding-right: 0 !important;
    .modal-dialog {
        margin: 0;
        transform: translateX(1000px) !important;
        max-width: calc(90% - 282px);
        //width: max-content;
        margin-left: auto;
        height: 100%;
       
        .modal-content {
            border: 0;
            border-radius: 0;
            background-color: var(--modal-bg-color);
        }

        .modal-header {
            background-color: var(--modal-header-bg-color);
            border-bottom-color: var(--modal-header-border-bottom);
            h4 {
                margin-bottom: 0;
                font-size: 15px;
            }
            .btn-close {
                color: var(--modal-close-btn-color);
                display: none;
            }
            .modal-close-btn {
                color: var(--modal-close-btn-color);
                margin-left: auto;
                font-size: 22px;
                cursor: pointer;
            }
        }
        .modal-body {
            height: 100vh;
            padding: 16px 15px;
            background-color: var(--modal-bg-color);
            .modal-content-card {
                background-color: var(--modal-card-bg);
                padding: 20px;
                border: 0px solid transparent;
                margin-bottom: 15px;
                box-shadow: 1px 1px 7px #9a9acc1a;
                border-radius: 4px;
                h5 {
                    font-size: 13px;
                    color: var(--footer-text);
                }
            }
        }

        .modal-heading {
            padding: 0px 0px;
            position: relative;
            margin-bottom: 15px;
            margin-top: 0px;
            h3 {
                font-weight: 600;
                font-size: 14px;
                text-transform: uppercase;
                margin-bottom: 0px;
                line-height: 14px;
                align-items: center;
                display: flex;
                
                &::before {
                    content: "";
                    width: 3px;
                    height: 16px;
                    background: var(--primary-bg-color);
                    display: inline-block;
                    margin-right: 5px;
                }
            }
        }

        .modal-footer {
            background-color: var(--modal-header-bg-color);
        }
        &.modal-md {
            max-width: calc(70% - 282px);
        }
        &.modal-sm {
            max-width: calc(50% - 282px);
        }

        &.show .modal-dialog {
            transform: translateX(0) !important;
        }
    }

    &.center-modal.custom-small {
        .modal-dialog{
            max-width: calc(350px);
        }
    }
    &.center-modal.custom-small-md {
        .modal-dialog{
            max-width: calc(500px);
        }
    }
   

    .checkbox-label {
        font-size: 14px;
        font-weight: 400;
    }
    .checkbox-label2 {
        font-size: 15px;
        font-weight: 400;
    }

    .access-card {
        background-color: #f0f0f0;
        padding: 0 20px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }

    .access-body2 {
        background-color: var(--white-bg);
        border-radius: 2px;
        border: 1px solid var(--menu-border-color);
        box-shadow: 0px 0px 4px 1px #9a9acc00;
        padding: 3px 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        min-width: 150px;
    }

    .access-cards {
        display: flex;
        flex-wrap: wrap;
        .access-body {
            background-color: var(--white-bg);
            border-radius: 2px;
            border: 1px solid var(--menu-border-color);
            box-shadow: 0px 0px 4px 1px #9a9acc00;
            padding: 10px 20px;
            margin-right: 20px;
            min-width: 322px;
            margin-bottom: 20px;
            .form-check {
                margin-bottom: 10px;
            }
        }
    }
    &.without-footer {
        .modal-footer {
            display: none !important;
        }
    }
    &.without-header {
        .modal-header {
            display: none !important;
        }
    }
}

.modal {
    &.confirm-modal {
        .modal-dialog {
            max-width: 475px;
            width: 475px;
            margin: auto;
            height: auto;
            transform: translateX(0) !important;
            transform: scale(0) !important;
            .modal-content {
                border-radius: 4px;
                overflow: hidden;
            }
            .modal-body {
                height: 100%;
                background-color: var(--white-bg);
                .icon {
                    font-size: 50px;
                    line-height: 1;
                    color: var(--text-danger);
                }
                .region-area-popup{
                    font-size: small;
                }
            }
        }

        .modal-dialog.modal-xl {
            width: 70% !important;
            max-width: 70% !important;
        }
        &.shadow-confirm {
            .modal-content {
                box-shadow: 1px 1px 8px 7px #0000004d;
            }
            &.hide-default-message {
                .can-hide {
                    display: none;
                }
            }
        }
        &.hide-some-text {
            .can-hide {
                display: none;
            }
        }
    }
    &.show .modal-dialog {
        transform: scale(1) !important;
    }
    &.region-filter-modal {
        top: 65px;
        .modal-dialog {
            .modal-content {
                border: 0px solid rgba(0, 0, 0, 0.2) !important;
                box-shadow: 1px 1px 4px 4px #2c2b2b47;
                border-radius: 6px !important;
                overflow: visible;
                .modal-header {
                    .h4 {
                        font-size: 15px !important;
                        margin-bottom: 0;
                    }
                }
            }
            .modal-body {
                height: 100% !important;
                overflow: visible;
                background-color: var(--white-bg);
            }
        }
        &.search-support-modal {
            .modal-dialog {
                max-width: 600px;
                .modal-content {
                    position: relative;
                    border-radius: 4px !important;
                    overflow: hidden;
                    .modal-header {
                        padding: 9px 10px !important;
                        background-color: transparent!important;
                        position: absolute;
                        z-index: 1;
                        width: 100%;
                        border: 0;
                        .modal-close-btn {
                            position: absolute;
                            top:0px;
                            right: 10px;
                        }
                        .h4 {
                            font-size: 15px !important;
                            margin-bottom: 0;
                        }
                    }
                    .modal-footer {
                        padding: 5px 10px !important;
                    }
                }
            }
            .search-support {
                display: flex;
                column-gap: 30px;
            }
        }
    }
}

.modal {
    &.center-modal {
        .modal-dialog {
            max-width: 800px;
            width: 800px;
            margin: auto;
            height: auto;
            transform: translateY(0) !important;
            transform: scale(1) !important;
            border-radius: 4px;
            .modal-content {
                border-radius: 4px;
                overflow: hidden;
                .modal-header {
                    padding: 7px;
                    .modal-title {
                        font-size: 16px;
                    }
                }
                .modal-footer {
                    padding: 7px;
                }
            }
            .modal-body {
                height: 100%;
                background-color: var(--white-bg);
                .icon {
                    font-size: 50px;
                    line-height: 1;
                    color: var(--text-danger);
                }
            }
        }

        .modal-dialog.modal-xl {
            width: 70% !important;
            max-width: 70% !important;
        }
        &.show .modal-dialog {
            transform: scale(1) !important;
            transform: translateY(100px) !important;
            box-shadow: 1px 1px 8px 7px #0000004d;
        }
        &.no-modal-buttons {
            .modal-footer {
                display: none !important;
            }
        }
        &.center-modal-table-bg {
            .modal-body {
                background-color: var(--modal-bg-color) !important;
            }
        }
    }
   
    &.view-add-appt-modal {
        .prop-name {
            font-size: 14px !important;
            &::before {
                content: none !important;
            }
        }
        .add-note {
            display: none !important;
        }
    }
    &.max-width-modal {
        .modal-dialog {
            max-width: calc(100% - 282px) !important;
        }   
    }
    &.appointment-info-modal {
        .modal-dialog {
           
            .prop-name-hide {
                margin-bottom: 5px !important; 
                padding: 5px !important;
                background: transparent;
                box-shadow: none;
                display: none !important;
                .modal-heading {
                    h3 {
                        display: none !important;
                        &::before {
                            content: none;
                        }
                    }
                    .btn {
                        padding: 5px !important;
                        line-height: 1 !important;
                    }
                    &.will-be-hide {
                        display: none !important;
                    }
                }
            }
            .modal-heading {
                .btn {
                    padding: 4px 8px !important;
    font-size: 13px !important;
                }
            }
            .tab-container .nav-item .nav-link {
                margin-bottom: 10px !important;
            }
        }
        .lightbox {
            position: fixed;
            margin: 12px;
            height: 97%;
            box-sizing: border-box;
            width: 97%;
            .react-multi-carousel-item {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 93vh;
            }
        }
    }
    .show-another-icon {
        position: absolute;
        right: 80px;
    }
}

.custom-height {
    .modal-body {
        max-height: 450px !important;
        overflow: hidden;
        overflow-y: scroll;
    }
}

@media (max-width: 768px) {
    .modal.confirm-modal .modal-dialog.modal-xl {
        width: 100% !important;
        max-width: 100% !important;
        .h4title {
            font-size: 19px;
        }
        .public-terms {
            padding: 0 !important;
            height: 55vh;
            p {
                font-size: 14px;
                line-height: 19px;
            }
        }
    }
    .modal .modal-dialog.modal-md
    {
        max-width: calc(100% - 0px);
    }

    .modal.center-modal .modal-dialog {
        width: 97% !important;
        max-width: 97% !important;
    }
    .modal .modal-dialog.modal-sm {
        max-width: calc(100% - 0px);
    }
    .modal.max-width-modal .modal-dialog
    {
        max-width: calc(100% - 0px) !important;
    }   
    .appointment-info-modal {
        .lightbox {
            width: 100% !important;
            height: 100% !important;
            margin: 0 !important;
        }
    }
}

.modal.center-modal.max-width-modal {
    .modal-dialog
    {
        width: auto !important;
    }   
}

.modal.right-modal-with-shadow {
    .modal-dialog {
        box-shadow: 1px 1px 8px 7px #0000004d;
    }
}



@media (max-width: 441px) {
    .modal.confirm-modal .modal-dialog {
        width: 97% !important;
        max-width: 97% !important;
        .modal-body {
            .p-4 {
                padding: 1.5rem 0 !important;
            }
            .footer-modal {
                .btn {
                    min-width: 70px;
                }
            }
        }
        .h4title {
            font-size: 19px;
        }
        .public-terms {
            padding: 0 !important;
            height: 55vh;
            p {
                font-size: 14px;
                line-height: 19px;
            }
        }
    }
}

//////popover

.popover {
    .popover-arrow{
        display: none;
    }
    .popover-body
     {
        padding: 5px;
        
        .popover-content {
            border-bottom: 1px solid var(--menu-border-color);
            &:last-of-type {
                border-bottom: 0;
            }
            h5 {
                font-size: 12px;
                margin-bottom: 5px;
                color: var(--menu-icon-color);
                font-weight: 400;
                span {
                    width: 17px;
                    height: 17px;
                    border: 1px solid#bfbfbf;
          background-color: #bfbfbf;
          color: #fff;
                    align-items: center;
                    justify-content: center;
                    display: inline-flex;
                    border-radius:4px;
                    cursor: pointer;
                    margin: 5px 5px 2px 0;
                    position: relative;
                    top:1px;
                    &.blue {
                        border: 1px solid #277DA2; //6852ff
                        color:var(--text-white) ;
                        background-color:  #277DA2;
                     }
            
                    &.red {
                        border: 1px solid #F94045; //e54a50
                        color: var(--text-white);
                        background-color: #F94045;
                    }
                    &.green {
                        border: 1px solid #198754;
                        color: #fff;
                        background-color: #198754;
                    }
                    &.light-green {
                        border: 1px solid #43AA8C; //#00acac //#359d73
                        color: #fff;
                        background-color: #43AA8C;
                    }
                    &.dark-vip-yellow {
                        border: 1px solid #ffdb51; //#00acac //#359d73
                        color: #fff;
                        background-color: #ffdb51;
                    }
                    &.danger {
                        border: 1px solid #F94045; //red
                        color: #F94045;
                    }
                    &.dark-blue {
                        border: 1px solid #277DA2; //001ae5
                        color: #ffffff;
                        background-color: #277DA2;
                    }
                    &.yellow {
                        border: 1px solid #f99719; //dcd455
                        color: #ffffff;
                        background-color: #f99719;
                    }
                }
            }
            strong {
                font-size: 12px;
            }
            p {
                font-size: 11px;
                color: var(--grey-text-color);
                margin-bottom: 5px;
            }
        }
    }
}

.edit-inspection-template-modal {
    .modal-dialog .modal-body {
        height: 100% !important;
    }
}

.modal-open {
    .scroll-btn {
     display: none !important;
    }
 }
 .hide-left-border {
    h3 {
        &::before {
            display: none !important;
        }
    }
 }
 .modal-open .select__menu-portal {
    z-index: 9999 !important;
}

.car-details-modal {
    .modal-body {
        background-color: transparent !important;
    }
}
// .appointment-info-modal {
//     .prop-name {
//         font-size: 16px !important;
//     }
// }

.log-time-modal {
    .rc-time-picker {
        svg {
            position: absolute;
    right: 7px;
    top: 11px;
        }
    }
    .rc-time-picker-input {
        padding-right: 40px;
    }
.rc-time-picker-clear {
        right: 25px;
    }
 
}

.select__menu-portal {
    z-index: 9999 !important;
}

.create-service-request-modal {
    .modal-body {
        padding-bottom: 50px !important;
    }
    .custom-footer-service-request {
        position: fixed;
        bottom: 0;
        background: var(--white-bg);
        border-top:1px solid var(--menu-border-color);
        width: 100%;
        left: 0;
        padding: 10px 30px;
        text-align: right;
    }
}




.tl {
    position: relative;
    //max-width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
    width: 100%;

    .date-timeline {
        font-size: 10.5px;
        color: #000000;
        margin-bottom: 0rem;
        font-weight: 500;
        line-height: 1.2;
        margin-top: 5px;
    }
    
  }
  
  .tl::after {
    content: "";
    position: absolute;
    width: 3px;
    background-color: var(--primary-bg-color);
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -2.1px;
  }
  
  .tl-container {
    padding: 0px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
  }
  
  .tl-container::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    right: -12px;
    background-color: #fff;
    border: 3px solid var(--primary-bg-color);
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }
  
  .tl-left {
    left: 0;
  }
  
  .tl-right {
    left: 50%;
  }
  
  .tl-left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid var(--primary-bg-color);
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #e3e3e3;
  }
  
  .tl-right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid var(--primary-bg-color);
    border-width: 10px 10px 10px 0;
    border-color: transparent #e3e3e3 transparent transparent;
  }
  
  .tl-right::after {
    left: -12px;
  }
  
  .tl-content {
    padding: 10px;
    background-color: #e3e3e3;
    position: relative;
    border-radius: 6px;
    h2 {
        font-size: 14px;
    }
    p {
        font-size: 12px;
        margin-bottom: 10px;
        line-height: 1.4;
    }
  }
  
  @media screen and (max-width: 600px) {
    .tl::after {
      left: 31px;
    }
  
    .tl-container {
      width: 100%;
      padding-left: 70px;
      padding-right: 25px;
    }
  
    .tl-container::before {
      left: 60px;
      border: medium solid var(--primary-bg-color);
      border-width: 10px 10px 10px 0;
      border-color: transparent #fff transparent transparent;
    }
  
    .tl-left::after,
    .tl-right::after {
      left: 15px;
    }
  
    .tl-right {
      left: 0%;
    }
  }
  
  .container-name{
    margin-top: 10px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: var(--input-label-color);
  }