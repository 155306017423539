// global font declaration

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
html {
  font-family: "Poppins", sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 16px;
  height: 100%;
}
body {
  font-family: "Poppins", sans-serif !important;
  background-color: var(--body-bg-color);
  font-size: 16px;
  color: var(--body-text-color);
  height: 100%;
}
body > #root {
  height: 100%;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2.1875rem;
}

h2,
.h2 {
  font-size: 1.75rem;
}

h3,
.h3 {
  font-size: 1.53125rem;
}

h4,
.h4 {
  font-size: 1.3125rem;
}

h5,
.h5 {
  font-size: 1.09375rem;
}

h6,
.h6 {
  font-size: 0.875rem;
}
.font18 {
  font-size: 18px;
}
.font17 {
  font-size: 17px;
}
.font16 {
  font-size: 16px;
}
.font15 {
  font-size: 15px;
}
.font14 {
  font-size: 14px;
}
.font13 {
  font-size: 13px;
}
.font12 {
  font-size: 12px;
}
.font11 {
  font-size: 11px;
}
.font10 {
  font-size: 10px;
}
.fontw500 {
  font-weight: 500;
}
.fw-bold {
  font-weight: 500;
}

.line-height-1 {
  line-height: 1;
}
p {
  font-size: 13.0509px;
}
a {
  font-size: 13.0509px;
  color: var(--black-text-color);
  &:hover {
    color: var(--anchor-hover-color) !important;
  }
}
.active-link {
  color: var(--breadcrumb-active-text-color);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.no-underline-link {
  text-decoration: none !important;
}
label {
  font-size: 13px;
}
.ft14 {
  font-size: 15px;
}
.ft12 {
  font-size: 15px;
}
.text-small {
  font-size: 11px;
}

.font-weight-semibold {
  font-weight: 500 !important;
}
.label-font-size {
  .form-check-label {
    font-size: 14px;
    font-weight: 500 !important;
  }
}
.flex1{
  flex: 1;
}
.word-break-all {
  word-break: break-all;
}

.limit-text-overflow {
  display: inline-block;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.min-width20 {
  min-width: 20px !important;
}
a.text-decoration-none {
  text-decoration: none !important;
}
.white-bg {
  background-color: #fff !important;
}
.bg-primary {
  background-color: var(--primary-bg-color) !important;
}

.bg-secondary {
  background-color: var(--secondary-bg-color) !important;
}
.text-danger {
  color: var(--text-danger) !important;
}
.sm-text-9 {
  font-size: 9px;
}

.grey-color {
color: var(--grey-icon);
}

.stripe-color {
  color: #6058f7;
}
.text-success {
  color:#38cab3 !important;
}
.cursor {
  cursor: pointer;
}
.not-allowed .toggle-switch-label {
  cursor: not-allowed !important;
}
.mt20 {margin-top: 20px;}
.pd-l-10 {
  padding-left: 10px !important;
}

@media (min-width: 1500px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

.mw-100px {
  max-width: 100px;
}
.mw-150px {
  max-width: 150px;
}
.mw-200px {
  max-width: 200px;
}
.mw-250px {
  max-width: 250px;
}

.main-container {
  min-height: 100vh;
}
/* custome scrollbar */
.custome-scrollbar {
  &::-webkit-scrollbar-track {
    background-color: #ededf5;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #ededf5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d1daed;
  }
}
.lang-select::before {
  display: inline-block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  content: "";
  height: 16px;
  width: 16px;
  margin-right: 0.375rem;
  margin-right: var(--space-xxs);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.en-lang::before {
  background-image: url("../../assets/img/us.svg");
}
.es-lang::before {
  background-image: url("../../assets/img/es.svg");
}

option[value="en"] {
  background-image: url("../../assets/img/us.svg");
}

.main-container {
  margin-left: 290px;
  padding-top: 75px;
  padding-bottom: 75px;
  padding-right: 10px;
  transition: margin-left 0.32s ease;
}

.sidebar-toggled {
  .main-container {
    margin-left: 90px;
  }
}

.auth-page {
  .main-container {
    margin-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  #launcher {
    display: none !important;
  }
}

// common css
.mt-15 {
  margin-top: 15px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px !important;
}
hr {
  background-color: #cecece;
}

// switch
.form-check-label {
  color: var(--body-text-color);
  font-weight: 400;
  font-size: 13px;
}

.form-switch .form-check-input {
  width: 26px;
}

.form-check-input:checked,
.form-check-input:checked {
  background-color: var(--primary-bg-color) !important;
  border-color: var(--primary-bg-color) !important;
}

.page-title {
  color: var(--page-title-color);
  font-weight: 500;
  font-size: 18px;
  text-indent: -1px;
  line-height: 1;
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
  text-transform: uppercase;
}

.content {
  background-color: var(--white-bg);
  padding: 20px 20px 10px;
  border-radius: 4px;
}

.alert {
  padding: 10px;
  font-size: 13px;
}

.alert-dismissible .btn-close {
  font-size: 10px;
  padding: 15px;
}

.search-container {
  //margin-bottom: 20px;
  margin-bottom: 15px;
  .card {
    border: 0;
    background-color: var(--white-bg);
    .card-header {
      background-color: transparent;
      border: 0;
      padding: 5px 20px 0px 20px;
      position: relative;
      .card-title {
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 0px;
        line-height: 1.5;
        &::before {
          content: "";
          width: 3px;
          height: 16px;
          background: var(--primary-bg-color);
          position: absolute;
          left: 11px;
          display: block;
          top: 8px;
        }
      }
    }

    .card-body {
      padding: 5px 20px 8px;
      .react-datepicker-wrapper .react-datepicker__input-container input{
        font-size: 13px;
    line-height: 20px;
      }
      .select__control{
        min-height: 30px;
        font-size: 13px;
      }
      .select__dropdown-indicator
      {
        padding: 3px 5px;
      }
      .form-group {
        margin-bottom: 2px;
      }
      .form-control {
        font-size: 13px;
        line-height: 22px;
      }
      .form-label{
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 2px;
      }
    }

    .card-footer {
      border: 0;
      background-color: transparent;
      padding: 0 20px 20px;
    }
  }
  &.apt-search-container {
    .card .card-body {
      padding-top: 0;
      .form-group {
        margin-bottom: 2px;
        .form-label {
          margin-bottom: 3px;
        }
      }
      .react-datepicker-wrapper .react-datepicker__input-container input{
        padding: 4px 12px;
      }
      .select__value-container  {
        padding: 0 8px;
      }
    }
  }
}

// animation Css

.animation {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

@keyframes slideDown {
  0% {
    top: 50px;
  }
  100% {
    top: 100%;
  }
}

.slideDown {
  -webkit-animation-name: slideDown;
  animation-name: slideDown;
}
.color-palette {
  background-color: var(--white-bg);
  position: fixed;
  height: 100%;
  width: 280px;
  top: 63px;
  right: 0;
  z-index: 0;
  transition: 0.3s ease-in;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.24), 0 2px 10px rgba(0, 0, 0, 0.03);
  overflow: hidden;
  overflow-y: auto;
  .setting-panel-header {
    display: block;
    padding: 15px 20px;
    color: var(--page-title-color);
    font-size: 15px;
    border: 1px solid var(--menu-border-color);
    background: var(--menu-border-color);
  }
  .theme-btn {
    min-width: 50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 15px;
    background-color: var(--primary-bg-color);
    border: 0;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #38cab3 !important;
}

.payroll {
  .form-check {
    border: 1px solid var(--secondary-bg-color);
    margin-bottom: 0;
    padding-left: 35px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 9px 7px;
    font-size: 16px;
    line-height: 1;
    background-color: var(--secondary-bg-color);
    color: var(--text-white);
    &:hover {
      background-color: var(--primary-bg-color);
      color: var(--text-white) !important;
    }
  }

  .btn {
    min-width: 40px !important;
    padding: 7px !important;
  }
}

.popovher {
  position: absolute;
  z-index: 2;
  top: 0;
}

.cover {
  position: fixed;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
}

.color-picker {
  width: 50px;
  height: 50px;
  border-radius: 6px;
  border: 1px solid;
}

.language-select {
  select {
    font-size: 13px;
  }
}

.upload-image {
  .image-item {
    position: relative;
    height: 150px;
    width: 150px;
    padding: 1px;
    border-radius: 8px !important;
    overflow: hidden;
    margin-top: 20px;
    img {
      max-width: 100%;
      width: 100%;
      max-height: 100%;
      height: 100%;
    }
    .image-item__btn-wrapper {
      position: absolute;
      top: 5px;
      width: 40px;
      right: 0px;
      //display: none;
      .u-btn {
        font-size: 14px;
        line-height: 30px;
        height: 26px;
        width: 26px;
        margin: 3px 1px;
        padding: 0;
        border-radius: 50px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        transition: all 0.3s ease-in-out;
        color: var(--text-white);
        border: none;
        &.delete-image {
          background-color: #f34343 !important;
          &:hover {
            background-color: #ee1f1f !important;
          }
        }
        &.edit-image {
          background-color: #38cab3 !important;
          &:hover {
            background-color: #2db59f !important;
          }
        }
        &.rotate-image {
          background-color: #000 !important;
          &:hover {
            background-color: #000 !important;
          }
        }
      }
    }
    // &:hover {
    //  .image-item__btn-wrapper{display: block;}
    // }
  }

  ///// new dropzone

  .dropzone {
    width: 100%;
    min-height: 250px;
    border: 2px dashed #b6c2c9;
    font-size: 24px;
    color: #b6c2c9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
  }
}

.tab-container {
  .nav-item {
    .nav-link {
      border: 1px solid var(--primary-custom-outline-btn-border);
      color: var(--primary-custom-outline-btn-color);
      margin-right: 10px;
      margin-bottom: 20px;
      position: relative;
      font-size: 13px;
      padding: 7px 12.48px;
      &.active,
      &:hover {
        background-color: var(--primary-custom-outline-btn-bg-hover);
        border: 1px solid var(--primary-custom-outline-btn-border);
        color: var(--primary-custom-outline-btn-color-hover) !important;
        cursor: pointer;
      }
    }
    &.count-tab {
      .nav-link {
        //padding-right: 30px;
        .count {
          position: relative;
          top: -1px;
          right: -5px;
          display: inline-flex !important;
          padding: 0px !important;
          width: 18px;
          height: 18px;
          font-size: 9px;
          z-index: 1;
          border-radius: 50px;
          line-height: 10px;
          text-align: center;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          font-weight: 400;
          background-color: var(--bs-danger);
          color: var(--text-white) !important;
        }
      }
    }
  }
  .modal-content-card {
    padding-bottom: 10px !important;
    .modal-card-body {
      background-color: var(--modal-bg-color);
      padding: 10px;
      margin-bottom: 10px;
      color: var(--input-label-color);
      .accordion {
        .accordion-body {
          background-color: var(--modal-card-bg);
        }
        .accordion-item {
          margin-bottom: 10px;
          border: 0;
        }
        .accordion-button {
          font-size: 15px;
          padding: 10px;
          background-color: var(--accordion-btn-bg-color);
          color: var(--accordion-btn-color);
          .form-check-input {
            margin: 0;
            margin-right: 10px;
          }
        }
        .accordion-button:active,
        .accordion-button:focus {
          outline: none;
          box-shadow: none;
        }

        .checkList-container {
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
              margin: 20px 7px 20px;
              font-size: 13px;
              color: var(--input-label-color);
              input {
                margin-right: 10px;
              }
              ul {
                padding-left: 30px;
                li {
                  margin: 7px;
                  ul {
                    margin: 10px 0;
                  }
                }
              }
            }
          }
        }
      }
      .big-title-label {
        margin: 0px 0 10px;
        color: var(--input-label-color);
        .form-check-label {
          font-size: 16px !important;
          color: var(--input-label-color);
        }
      }
      .access-box {
        background-color: var(--white-bg);
        border-radius: 2px;
        border: 1px solid var(--menu-border-color);
        box-shadow: 0px 0px 4px 1px #9a9acc00;
        padding: 10px 20px;
        margin-right: 20px;
        margin-bottom: 10px;
        min-width: 100% !important;
        width: 100% !important;
        color: var(--input-label-color);

        .form-check.font-weight-bold {
          margin-bottom: 10px;
          border-bottom: 1px solid #ececec;
          padding-bottom: 5px;
        }
        .title-label {
          font-size: 14px !important;
          text-transform: uppercase;
          font-weight: 500;
          color: var(--input-label-color);
        }
        .access-box-body {
          display: flex;
          flex-wrap: wrap;
          margin: 10px 0 0;

          .form-check {
            margin-right: 0px;
            flex-basis: 30%;
            color: var(--input-label-color);
          }
        }
        &.parent-access-box {
          display: flex;
          flex-wrap: wrap;
          .form-check {
            margin-right: 0px;
            flex-basis: 30%;
            color: var(--input-label-color);
          }
          .parent-title {
            flex-basis: 100%;
          }
        }
      }
    }
  }
}

.radio-box {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  label {
    color: var(--input-label-color);
    font-size: 15px;
  }
  .small {
    color: var(--input-label-color);
    font-size: 11px;
    padding-left: 25px;
  }
}

.rdw-editor-wrapper {
  border: 1px solid var(--menu-border-color);
  .rdw-editor-main {
    min-height: 340px;
  }
}

.upload-clients {
  .upload-image {
    .btn.btn-outline-primary {
      width: 100%;
      min-height: 250px;
      border: 2px dashed #b6c2c9;
      font-size: 24px;
      color: #b6c2c9;
      &:hover,
      &:focus {
        background-color: var(--body-bg-color) !important;
        color: var(--black-text-color) !important;
      }
    }
  }
}

.thank-you-wrap {
  text-align: center;
  h1 {
    font-family: "Satisfy", cursive;
    font-size: 50px;
    color: #293072;
    margin: 20px 0;
  }
  p {
    color: #939393;
    margin: 20px 0;
    font-size: 16px;
  }
  .icon {
    width: 130px;
    height: 130px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 60px;
    background-color: #f0f3ff;
    color: #87d185;
  }
}

.css-1rhbuit-multiValue {
  position: relative;
  z-index: 1;
}
.css-26l3qy-menu {
  z-index: 2 !important;
}

.more-user {
  // width: 20px;
  // height: 20px;

  font-size: 16px;
  color: var(--primary-bg-color);
  margin-left: 10px;
  cursor: pointer;
}

.service-details-row {
  //border-bottom: 1px solid var(--menu-border-color);
  margin: 0 0 0px 0;
  //padding-bottom: 20px;
  .service-column {
    flex: 1;
    margin-right: 10px;
    .th-column {
      color: var(--input-label-color);
      font-size: 13.0509px;
      line-height: 19.5763px;
    }
    .td-column {
      margin: 10px 0;
    }
    &.buttons-column {
      flex: 0 0 40%;
    }
    &.order-column {
      flex: 0 0 10%;
    }
  }
}

.add-property-service-details-row {
  //border-bottom: 1px solid var(--menu-border-color);
  margin: 0 0 0px 0;
  //padding-bottom: 20px;
  .service-column {
    flex: 1;
    margin-right: 10px;
    .th-column {
      color: var(--input-label-color);
      font-size: 12px;
      line-height: 13px;
      height: 25px;
    }
    .td-column {
      margin: 10px 0;
    }
    &.buttons-column {
      flex: 0 0 40%;
    }
    &.order-column {
      flex: 0 0 10%;
    }
  }
  .btn.button-icon {
    min-width: 30px !important;
    padding: 2px 6.48px;
    font-size: 14px;
  }
}

.rc-time-picker {
  display: flex;
  width: 100%;
  input {
    color: var(--input-text-color) !important;
    font-size: 14px;
    line-height: 25px;
    font-weight: 500;
    border: 1px solid var(--menu-border-color) !important;
    padding: 5px 12px;
    border-radius: 4px;
    background-color: var(--input-bg);
    height: auto;
  }
  .rc-time-picker-clear {
    top: 9px;
  }
  &.readonly {
    input {
      opacity: 0.5;
    }
  }
}
.add-staff-switch {
  .form-check-label {
    font-size: 14px;
  }
}
.property-notes-list {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin-bottom: 20px;
    border-bottom: 1px solid var(--menu-border-color);
    padding-bottom: 10px;
    .date {
      font-size: 12px;
      color: #7b8191;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
    }
    p {
      margin-bottom: 0.5rem;
    }
  }
  &.inspection-property-notes-list li{
    margin-bottom: 10px;
    padding-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
      border: 0;
      padding-bottom: 0;
    }
  }
}

.admin-notes-list {
  ul {
    margin: 0;
    margin-top: 30px;
    padding: 0;
    li {
      display: flex;
      padding: 10px 0px;
      border-bottom: 1px solid var(--menu-border-color);
      .b-color {
        width: 12px;
        background-color: #bfa300;
        margin-right: 10px;
      }
      .notes {
        p {
          margin-bottom: 0.5rem;
        }
        .date {
          font-size: 12px;
          color: #7b8191;
          margin-bottom: 0rem;
          font-weight: 500;
          line-height: 1.2;
        }
      }
      .close-note {
        margin-left: auto;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
}

.my-account-wrapper {
  .search-container {
    margin-bottom: 0;
  }
  .custom-footer {
    background-color: var(--white-bg);
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 20px 10px;
    border-top: 1px solid var(--menu-border-color);
  }
}

.additional-features {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: flex;
      padding: 10px 8px;
      border-bottom: 1px solid var(--menu-border-color);
      div {
        font-size: 13.0509px;

        color: var(--input-label-color);
      }
      div + div {
        margin-left: auto;
        flex: 0 0 22%;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  .desc-note {
    font-size: 10px !important;
    line-height: 12px;
    margin-top: 5px;
    padding: 3px;
    color: #777272 !important;
    border-radius: 3px;
  }
}

.w-150 {
  width: 150px;
}
.w-200 {
  width: 200px;
}
.w-250 {
  width: 250px;
}

.w-15 {
  width: 15px;
}


.custom-accordion {
  .accordion {
    .card {
      margin-bottom: 10px;
      border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
      border-top-left-radius: 0.25rem !important;
      border-top-right-radius: 0.25rem !important;
      .card-header {
        display: flex;
        padding: 0;
        background-color: var(--white-bg);
        .accordion-link {
          flex: 0 0 auto;
          margin-right: 20px;
          font-size: 14px;
          padding: 10px;
          //color: var(--primary-btn-bgcolor);
          cursor: pointer;
          display: flex;
          span {
            margin-left: 10px;
          }
        }
        .accordion-button {
          font-size: 14px;
          padding: 10px;
          border: 0;
          background-color: transparent;
          box-shadow: none;

          &:focus {
            box-shadow: none;
          }
          &:after {
            transition: transform 0.5s ease-in-out;
          }
          &[aria-expanded="false"]::after {
            background-image: url("../../assets/img/sorting-arrow-dsc.svg");
            transform: rotate(-180deg);
          }

          &[aria-expanded="true"]::after {
            background-image: url("../../assets/img/sorting-arrow-dsc.svg");
            transform: rotate(0deg);
          }
        }
      }
      .accordion-collapse {
        padding-left: 30px;
        font-size: 13px;
        .card-body {
          font-size: 13px;
          padding: 0;
        }
      }
    }
  }
}

.appointment-list {
  .content {
    background-color: var(--body-bg-color);
    padding: 0px !important;
    .gridjs .gridjs-wrapper table.gridjs-table .gridjs-thead th.gridjs-th {
      background-color: var(--white-bg);
    }
  }

  .count {
    display: inline-flex !important;
    padding: 4px !important;
    font-size: 11px;
    z-index: 9999;
    border-radius: 50px;
    line-height: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    background-color: #dc3545;
    color: #fff;
    font-weight: 400;
    //height: 37px;
    min-width: 18px;
    margin-left: 5px;
  }
  h6 {
    //background: #d5dde5;
    background-color: var(--white-bg);
    margin: 0;
    .count {
      background-color: #dc3545;
    }
  }
  .top-section-appointment {
    font-size: 13px;
    background-color:  var(--white-bg);
    margin-bottom: 10px;
    padding: 7px;
    .sub-apt {
      margin-right: 50px;
      font-weight: 500;
    }
    .count-p {
      background-color: var(--primary-bg-color) !important;
    }
  }
}
.list-count {
  font-size: 13px;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 7px;
  .sub-apt {
    font-weight: 500;
  }
  .count {
    display: inline-flex !important;
    padding: 4px !important;
    font-size: 11px;
    z-index: 9999;
    border-radius: 50px;
    line-height: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    background-color: #dc3545;
    color: #fff;
    font-weight: 400;
    //height: 37px;
    min-width: 18px;
    margin-left: 5px;
  }
  .count-p {
    background-color: var(--primary-bg-color) !important;
  }
}

.service-checklist-page {
  padding: 10px 10px 0;
  .sub-apt {
    margin-right: 50px;
    font-weight: 500;
    font-size: 14px;
  }
  .count-p {
    background-color: var(--primary-bg-color) !important;
  }
}

.appointment-column-checklist {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin: 20px 7px 20px;
      font-size: 13px;
      color: var(--input-label-color);
      display: flex;
      div {
        flex: 1;
      }
    }
  }
}

.edit-template-region {
  ul li {
    display: block !important;
    margin-top: 0 !important;
  }
  .accordion-button {
    font-size: 14px !important;
  }
}

.edit-template-page {
  padding: 0;
  height: 100% !important;
  .template-wrap {
    display: flex;
    height: 100% !important;
    .left-box {
      flex-basis: 23%;
      //background-color: #647d93;
      border-right: 1px solid var(--grey-text-color);
      padding: 10px;
      .nav-pills {
        .nav-link {
          border-radius: 0;
          color: var(--primary-bg-color);
          background-color: transparent;
          border: 1px solid var(--primary-bg-color);
          margin-bottom: 10px;
          border-radius: 4px;
          cursor: pointer;
          text-align: center;
          font-size: 13px;
          svg {
            font-size: 19px;
          }
          &.active,
          &:hover {
            background-color: var(--primary-bg-color);
            color: var(--text-white) !important;
          }
          .edit-i {
            svg {
              font-size: 12px;
            }
          }
        }
      }
    }
    .right-box {
      flex-basis: 77%;
      padding: 10px 20px;
      position: relative;
      .service-details-row {
        //background-color: var( --modal-bg-color);
        padding: 5px;
        .service-column {
          &.buttons-column {
            flex: 0 0 13%;
          }
          &.img-column {
            flex: 0 0 10%;
          }
          .td-column {
            margin: 5px 0 !important;
            position: relative;
            img.min-width {
              min-width: auto;
              max-width: 100%;
              width: 100%;
            }
            .u-btn {
              position: absolute;
              top: 2px;
              right: 2px;
              background-color: var(--text-danger);
              color: var(--text-white);
              border: 0;
              border-radius: 50%;
              display: inline-flex;
              font-size: 12px;
              padding: 4px;
            }
          }
          .form-control {
            font-size: 12px;
            padding: 2px 9px;
          }
          .btn {
            min-width: 40px;
            padding: 4px 10px;
          }
        }
      }
      .heading-row {
        background-color: #f5f5f5;
        padding: 7px 5px;
        .th-column {
          font-weight: 500;
        }
      }
      .grand-total-row {
        background-color: #f5f5f5;
        padding: 15px 5px;
        font-size: 15px;
        text-align: center;
        strong {
          font-weight: 500;
        }
      }
    }
  }
  .small-text {
    font-style: italic;
    font-size: 12px;
  }
}

.min-width {
  min-width: auto;
}

.upload-template {
  ul li {
    display: block !important;
    margin-top: 0 !important;
  }
  .accordion-button {
    font-size: 14px !important;
  }
}
.d-horizontal {
  .template-details-wrap {
    display: flex;
    justify-content: space-between;
    .template-name {
      flex: 0 0 35%;
    }
    .only-checkbox {
      padding-right: 10px;
    }
  }
}

.hide-title {
  .modal-heading {
    display: none;
  }
}

.hide-label {
  .can-hide {
    display: none;
  }
}

.select__group {
  padding-bottom: 0 !important;
  .select__group-heading {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

.box-shadow-none {
  box-shadow: none !important;
}

.prop-type {
  font-size: 11px;
  font-style: italic;
  line-height: 1;
  font-weight: 500;
}

.h-100 {
  height: 100px !important;
}
.ht-100 {
  height: 100% !important;
}

.readonly-label {
  font-weight: 500;
}

.circle-icon {
  width: 18px;
  height: 18px;
  font-size: 12px;
  margin: 2px 5px 2px 0;
}

.li-margin {
  .property-notes-list li {
    margin-bottom: 10px !important;
  }
}

.pos-adjust {
  position: relative;
  top: -10px;
  left: 25px;
}

.ha-task-list {
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      display: inline-block;
      background-color: var(--grey-text-color);
      color: var(--text-white);
      padding: 5px;
      border-radius: 2px;
      margin-right: 7px;
      font-size: 10px;
      line-height: 1;
      margin-bottom: 7px;
    }
  }
}

.readonly-text-with-bg {
  display: block;
  color: #706f6f;
  font-size: 13px;
  line-height: 1;
  margin-top: 5px;
  /* font-weight: 500; */
  background: #f7f7f7;
  padding: 10px 10px;
  border-radius: 4px;
}

.readonly-text-with-bg2 {
  display: block;
  color: #706f6f;
  font-size: 13px;
  line-height: 1;
  margin-top: 5px;
  /* font-weight: 500; */
  background: #e4e4e4;
  padding: 10px 10px;
  border-radius: 4px;
}

.readonly-text-with-bg-dark {
  display: block;
  color: #5d626d;
  font-size: 12px !important;
  line-height: 1;
  margin-top: 0px;
  font-weight: 500;
  background: #e4e4e4;
  padding: 7px 10px;
  border-radius: 4px;
}

// .icon-width-circle {
//   width: 18px;
//   height: 18px;
//   border: 1px solid var(--secondary-bg-color);
//   background-color: var(--secondary-bg-color);
//   color: #ffffff;
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   display: inline-flex;
//   border-radius: 50%;
//   cursor: pointer;
//   margin: 5px 5px 2px 0;
// }

.date-readonly {
  font-size: 14px;
  font-weight: 500;
}

.info-icon {
  font-size: 20px;
  color: var(--primary-bg-color);
  cursor: pointer;
}

//inspection view-report-modal

.item-wrapper {
  display: flex;
  //border-bottom: 1px solid #ccc;
  //margin-bottom: 7px;
  padding: 0px 10px;
  flex: 1;
  .item-image {
    width: 8%;
    .img-width {
      width: 55px;
      height: 45px;
      border: 1px solid #ccc;
      overflow: hidden;
      img {
        max-height: 100%;
        max-width: 100%;
        width: 100%;
        height: 100%;
      }
    }
  }

  .item-details {
    width: 50%;
    display: flex;
    align-items: flex-start;
    .text {
      font-size: 12px;
      margin-bottom: 7px;
      margin-left: 7px;
      color: #000;
    }
    .i-btn {
      background-color: var(--primary-bg-color) !important;
      color: var(--white-text-color) !important;
      padding: 2px 2px;
      line-height: 1;
      border-radius: 2px;
      display: inline-flex;
      height: 21px;
      align-items: center;
      cursor: pointer;
    }
    .badge {
      font-weight: normal;
      font-size: 10px;
      border-radius: 2px;
      background-color: transparent !important;
      color: var(--text-dark-color) !important;
    }
  }
  .item-btns {
    width: 42%;
    padding-top: 5px;
    .btn-group {
      min-width: 160px;
      .btn {
        padding: 2px !important;
        font-size: 13px !important;
        span {
          position: relative;
          top: 1px;
        }
      }
      .btn-primary {
        background-color: var(--primary-bg-color) !important;
        color: var(--white-text-color) !important;
      }
      .btn-success {
        background-color: #5cd3b9 !important;
        color: var(--white-text-color) !important;
      }
      .btn-primary {
        background-color: var(--primary-bg-color) !important;
        color: var(--white-text-color) !important;
      }
    }
  }
}

.view-edit-report-modal {
  .count {
    z-index: 0 !important;
  }
  .accordion {
    .card {
      margin-bottom: 10px;
      border-radius: 4px;
      .card-header {
        display: flex;
        padding: 5px 0;
        align-items: flex-start;
        background-color: var(--white-bg);
        border: 0;
        border-radius: 8px;
        .accordion-button {
          width: 50px;
          margin-left: auto;
          padding: 5px 10px;
          background-color: transparent;
          border: 0;
          box-shadow: none;
          &[aria-expanded="false"]::after {
            background-image: url("../../assets/img/sorting-arrow-dsc.svg");
            transform: rotate(-180deg);
          }

          &[aria-expanded="true"]::after {
            background-image: url("../../assets/img/sorting-arrow-dsc.svg");
            transform: rotate(0deg);
          }
        }
      }
      .card-body {
        border-top: 1px solid var(--menu-border-color);
        .action-btn {
          .button-icon {
            padding: 0px 3px 1px !important;
            min-width: auto !important;
            height: auto !important;
          }
        }
        li {
          margin-bottom: 10px;
          padding-bottom: 10px;
          p {
            font-size: 12px !important;
          }
          .date {
            margin-bottom: 0;
          }
        }
        .property-pics {
          padding: 10px !important;
          padding-top: 0 !important;
          box-shadow: none !important;
          border: 0 !important;
          margin-bottom: 0 !important;
          .modal-heading {
            margin-bottom: 0 !important;
          }
          .image-item {
            margin-right: 7px !important;
            height: 160px;
            width: 160px;
          }
        }
      }
    }
  }
  .lightbox {
    position: fixed;
    margin: 12px;
    height: 97%;
    box-sizing: border-box;
    width: 97%;
  }
  .view-edit-table-facility-insp {
    border-top: 1px solid var(--grey-text-color);
    padding-top: 15px;
  }
  .modal-footer {
    .btn-secondary {
      display: none !important;
    }
  }
  .callback-img {
    img {
      width: 70px;
    }
  }
}

.assign-callback-modal {
  .property-config-form {
    .form-details {
      column-gap: 35px;
      margin: 5px 0 !important;
      .form-group {
        flex-basis: 22% !important;
        margin-bottom: 0 !important;
        .btn {
          min-width: 68px !important;
        }
      }
    }
    &.config-form-heading {
      padding: 0px 10px !important;
      .form-details {
        margin: 5px 0 !important;
      }
    }
  }
}

.report-help {
  p {
    font-size: 12px;
  }
  div {
    font-size: 12px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
  .flex-1 {
    flex: 0 0 160px;
  }
  .flex-2 {
    flex: 0 0 160px;
    .btn {
      min-width: 160px;
    }
  }
  .btn-group {
    .btn {
      font-size: 13px !important;
      padding: 2px !important;
      &.btn-success {
        background-color: #5cd3b9 !important;
        color: #fff !important;
        color: var(--white-text-color) !important;
      }
    }
  }
  .green {
    td {
      background: #dff0d8 !important;
    }
  }
  .table-2 {
    .green:nth-child(2) td {
      background: #f2dede !important;
    }
  }
  .table-3 {
    .green:nth-child(2) td {
      background: #f2dede !important;
    }
    .green:nth-child(3) td {
      background: #fff !important;
    }
  }
}

.modal-content-card {
  &.view-edit-report-modal-header {
    .modal-heading {
      padding: 5px 10px !important;
      align-items: center;
      margin-bottom: 0 !important;
      h3 {
        &::before {
          display: none !important;
        }
      }
    }
    .m-body {
      padding: 10px 20px 10px !important;
    }

  }
}

.icon-on-modal {
  position: relative;
  top: -5px;
  svg {
    font-size: 12px !important;
  }
}

.edit-workorder-items-row {
  .service-column {
    flex: 1;
    &.buttons-column-two {
      flex: 0 0 5%;
    }
    &.buttons-column-three {
      flex: 0 0 8%;
    }
    &.desc-column {
      flex: 0 0 35%;
    }
    &.items-column {
      flex: 0 0 25%;
    }
    &.items-column2 {
      flex: 0 0 15%;
    }
    &.desc-column2 {
      flex: 0 0 25%;
    }
    &.request-column {
      flex: 0 0 20%;
    }
    &.buttons-column-one {
      flex: 0 0 2%;
      margin-right: 0;
      display: flex;
      align-items: center;
      .notes-wrap-item {
        padding: 0;
        .light-green {
          font-size: 11px;
          /* line-height: 2; */
          display: flex;
          padding: 4px 0;
        }
      }
    }
  }
}


.light-green-td {
  .notes-wrap-item {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .light-green {
    font-size: 11px;
    /* line-height: 2; */
    display: inline-flex;
    padding: 3px 0;
  }
}

.int-out-tm-workorder {
  background-color: #d5e3f0;
  padding: 5px;
}

.plain-text {
  font-size: 12px;
  line-height: 1.3;
}

.icon-width-circle {
  width: 18px;
  height: 18px;
  border: 1px solid var(--icon-dark-green-color);
  background-color: var(--icon-dark-green-color);
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  border-radius: 50%;
  cursor: pointer;
  margin: 5px 5px 2px 0;
  &.property {
    border: 1px solid var(--icon-light-green-color);
    background-color: var(--icon-light-green-color);
  }
  &.facility {
    border: 1px solid var(--secondary-bg-color);
    background-color: var(--secondary-bg-color);
  }
}

.icon-width-building {
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  cursor: pointer;
  margin: 5px 5px 2px 0;
}

.break-coonections-btn {
  border: 1px solid #c0d7eb;
  border-radius: 4px;
  padding: 0 7px;
  background: #c0d7eb;
  display: flex;
  align-items: center;
  .form-check {
    margin-bottom: 0;
  }
}

.all-btns {
  .btn-sm {
    width: 165px;
  }
  span {
    padding: 0px !important;
    width: 15px;
    height: 15px;
    font-size: 9px;
    z-index: 1;
    border-radius: 50px;
    line-height: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    font-weight: 400;
    margin-right: 5px;
    display: none;
  }
  div {
    display: flex;
    align-items: center;
  }
  .vacant-status {
    color: #38cab3;
    span {
      background-color: #38cab3;
    }
  }
  .dnd-status {
    color: var(--bs-danger);
    span {
      background-color: var(--bs-danger);
    }
  }
  .refused-status {
    color: var(--secondary-bg-color);
    span {
      background-color: var(--secondary-bg-color);
    }
  }
  .reconcile-status {
    color: var(--primary-bg-color);
    span {
      background-color: var(--primary-bg-color);
    }
  }
}

.integration-wrapper {
  display: flex;
  width: 100%;
  //justify-content: space-between;
  align-items: flex-start;

  .integration-left {
    width: 68%;
    //background-color: #b8cce4;
    padding: 0px;
    border-radius: 4px;
    .select__single-value {
      font-size: 13px;
    }
    .integration-heading {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      padding-top: 5px;
      h3 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
      }
      span {
        margin-left: auto;
        font-size: 13px;
        font-weight: 500;
      }
    }
    .list-config-form {
      .form-details {
        display: flex;
        flex-wrap: wrap;
        column-gap: 6px;
        width: 100%;
        padding: 5px;
        border-bottom: 1px solid #fbf7f7;
        .form-group {
          flex: 1;
          margin-bottom: 0;
          &.filed-updated {
            .field-loader {
              margin-left: 5px;
              &.check {
                color: green;
                font-size: 14px;
              }
            }
            .form-control {
              border-color: #b5e5b2 !important;
              background-color: #b5e5b2 !important;
              &:focus {
                border-color: #b5e5b2 !important;
                background-color: #b5e5b2 !important;
              }
            }
            .select__control {
              border-color: #b5e5b2 !important;
              background-color: #b5e5b2 !important;
            }
            .react-datepicker__input-container input {
              border-color: #b5e5b2 !important;
              background-color: #b5e5b2 !important;
            }
            .form-check-input[type="checkbox"] {
              border-color: #b5e5b2 !important;
              background-color: #b5e5b2 !important;
            }
            .rc-time-picker input {
              border-color: #b5e5b2 !important;
              background-color: #b5e5b2 !important;
            }
          }
          &.filed-not-updated {
            .field-loader {
                margin-left: 5px;
                &.check {
                    color: var(--text-danger);
                    font-size: 14px;
                }
            }
            .form-control {
                border-color: #fdd0da !important;
                background-color: #fdd0da !important;
                &:focus {
                    border-color: #fdd0da !important;
                    background-color: #fdd0da !important;
                }
            }
            .select__control {
                border-color: #fdd0da !important;
                background-color: #fdd0da !important;
            }
            .react-datepicker__input-container input {
                border-color: #fdd0da !important;
                background-color: #fdd0da !important;
            }
            .form-check-input[type="checkbox"] {
                border-color: #fdd0da !important;
                background-color: #fdd0da !important;
            }
            .rc-time-picker input {
                border-color: #fdd0da !important;
                background-color: #fdd0da !important;
            }
        }
          .form-label {
            font-size: 12px;

            &.title-l {
              font-weight: 500;
            }
          }
          &.automated-link {
            // flex: 0 0 60%;
            flex: 2 2;
            position: relative;
            .loading-span {
              position: absolute;
              right: 7px;
            }
          }
          &.wallet-status-col {
            flex: 0 0 10%;
          }
          &.wallet-card-col {
            .form-check-label {
              font-size: 10px;
              color: var(--primary-link-color);
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        .form-control {
          padding: 2px 12px;
        }
      }
      &.list-content {
        background-color: #fff;
      }
    }
    .list-form-heading {
      background-color: #eaeaea;
    }
  }
  .integration-right {
    width: 30%;
  }

  .integration-left.smart-left {
    width: 60%;
  }
  .integration-right.smart-right {
    width: 38%;
  }
}

.wallet-list {
  .integration-wrapper {
    align-items: inherit;
    .integration-left {
      background-color: #fff;
      padding: 10px;
    }
    .wallet-right {
      background-color: #fff;
      .integration-heading {
        display: flex;
        align-items: center;
        padding: 10px;

        h3 {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 0;
        }

      }
      .wallet-body {
        padding: 10px;
        .alert-box {
          .icon {
            justify-content: center;
            align-items: center;
            display: flex;
            margin-right: 20px;
            font-size:50px;
            color: #f74f75;
          }
        }
        .edit-link {
          color: var(--primary-link-color);
        }
        .wizard-edit-link {
          color: var(--primary-link-color) !important;
        }
      }
    }
  }

}

.assignment-progress-wrapper {
  display: flex;
  // justify-content: space-between;
  column-gap: 15px;
  .search-container {
    width: 32%;
    border: 2px solid #b8cce4;
    .card-header {
      padding-top: 10px !important;
      padding-right: 10px;
      .card-title {
        font-size: 12px !important;
        &::before {
          top: 11px;
          width: 2px;
          height: 13px;
        }
      }
    }
    .card-body {
      padding: 10px !important;
      padding-bottom: 5px !important;
      .gridjs .gridjs-wrapper table.gridjs-table {
        .gridjs-thead th.gridjs-th {
          padding: 5px !important;
        }
        td.gridjs-td {
          padding: 5px !important;
          font-size: 11.5px;
        }
      }
    }
  }
}

.card-title-indi {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0px;
  line-height: 1.5;
  // background-color: #fff;
  margin-top: 15px;
}

.failed-item-wrapper-inspection {
  background: #81b4e4;
  margin-bottom: 10px;
  .property-config-form {
    &.config-form-heading {
      background-color: transparent !important;
      border-bottom: 0;
      .form-details {
        margin-top: 0 !important;
        .form-group {
          flex-basis: 22% !important;
          &.notes-col {
            flex-basis: 50% !important;
          }
        }
        .form-label {
          color: #fff !important;
        }
      }
    }

    &:last-of-type {
      border-bottom: 1px solid #81b4e4 !important;
    }
    .form-group.notes-col {
      flex-basis: 50% !important;
    }
  }
  .content-form {
    background-color: #fff !important;
    border: 1px solid #81b4e4 !important;
    padding: 5px 10px !important;
    .form-details {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 6px;
      width: 100%;
      .form-group {
        flex-basis: 22% !important;
        margin-bottom: 0 !important;
        .form-label {
          font-size: 12px !important;
          line-height: 14px;
          margin-bottom: 0 !important;
        }
        &.notes-col {
          flex-basis: 50% !important;
        }
      }
    }
    &:last-of-type {
      border-top: 0px solid #81b4e4 !important;
    }
    .images {
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;
      .img {
        width: 50px;
        height: 50px;
        margin-right: 5px;
        margin-bottom: 5px;
        img {
          width: 100%;
          max-width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.tilled-logo {
  width: 200px;
}
.stripe-logo {
  width: 100px;
}

.img-bg{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.tilled-info {
  h4 {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .tilled-info-item {
    display: flex;
    margin-bottom: 10px;
    label {
      width: 170px;
      font-size: 13px;
      font-weight: 500;
    }
    span {
      width: 70%;
      font-size: 14px;
    }
  }
}

.hide-recur-check-on-assign-subtask {
  .make-recure-checkbox {
    display: none !important;
  }
}


// hanumat css SATURDAY, OCT 15
.btn.btn-sm.apt-list-cl-bk-btn {
  font-size: 9px !important;
  padding: 1px !important;
  min-width: 58px;
  margin-right: 5px;
}
.apt-popup-icon {
  width: 20px;
    height: 20px;
    border: 1px solid #0fce59;
    color: #fff;
    background-color: #0fce59;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    border-radius: 50%;
    cursor: pointer;
    margin: 5px 5px 2px 0;
    font-size: 10px;
}
.apt-popup-icon-child {
  width: 20px;
    height: 20px;
    // border: 2px solid;
    color: #fff;
     background-color: #ff9800;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    border-radius: 50%;
    cursor: pointer;
    margin: 5px 5px 2px 0;
    font-size: 10px;
}

.view-invoice-seacr-right {
  display: flex;
  font-size: 12px;
  align-items: flex-start;
  img {
    margin-right: 20px;
    position: relative;
    top: -12px;
  }
}

.invoice-details {
  p {
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 500;
  }
  .invoice-from {
    flex: 1;
  }

  .invoice-to {
    flex: 1;
    border-left: 1px solid #ccc;
    padding-left: 30px;
  }
}

.invoice-cl-logo {
  width: 120px;
  img {
    max-width: 100%;
  }
}

.approval-modal-photos {
  .photos {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    display: inline-flex;
    overflow: hidden;
    margin: 2px;
    img {
        max-width: 100%;
    }
}
}

.bg-light-blue{
  background-color: #eefaff !important;}

.modal-heading.no-border {
  h3 {
    &::before {
      display: none !important;
    }
  }
}

.invoice-field-not-updated {
  border-color: #fdd0da !important;
  background-color: #fdd0da !important;
}

.invoice-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;

  .invoice-left {
    width: 100%;
    //background-color: #b8cce4;
    padding: 0px;
    border-radius: 4px;
    .invoice-heading {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      padding-top: 5px;
      h3 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
      }
      span {
        margin-left: auto;
        font-size: 13px;
        font-weight: 500;
      }
    }
    .list-config-form {
      .form-details {
        display: flex;
        flex-wrap: wrap;
        column-gap: 6px;
        width: 100%;
        padding: 5px;
        border-bottom: 1px solid #fbf7f7;
        .form-group {
          flex: 1;
          margin-bottom: 0;
          &.apt-f-w {
            flex: 0 0 100px;
          }
          &.action-f-w {
            flex: 0 0 50px;
          }
          &.resv-f-w {
            flex: 0 0 15%;
            .resv-lable  {
              word-break: break-all;
            }
          }
          &.filed-updated {
            .field-loader {
              margin-left: 5px;
              &.check {
                color: green;
                font-size: 14px;
              }
            }
            .form-control {
              border-color: #b5e5b2 !important;
              background-color: #b5e5b2 !important;
              &:focus {
                border-color: #b5e5b2 !important;
                background-color: #b5e5b2 !important;
              }
            }
            .select__control {
              border-color: #b5e5b2 !important;
              background-color: #b5e5b2 !important;
            }
            .react-datepicker__input-container input {
              border-color: #b5e5b2 !important;
              background-color: #b5e5b2 !important;
            }
            .form-check-input[type="checkbox"] {
              border-color: #b5e5b2 !important;
              background-color: #b5e5b2 !important;
            }
            .rc-time-picker input {
              border-color: #b5e5b2 !important;
              background-color: #b5e5b2 !important;
            }
          }
          .form-label {
            font-size: 12px;

            &.title-l {
              font-weight: 500;
            }
          }
          &.automated-link {
            // flex: 0 0 60%;
            flex: 2 2;
            position: relative;
            .loading-span {
              position: absolute;
              right: 7px;
            }
          }
        }
        .form-control {
          padding: 2px 12px;
        }
      }
      &.list-content {
        background-color: #fff;
      }
    }
    .list-form-heading {
      background-color: #eaeaea;
    }
  }
  .invoice-right {
    width: 30%;
  }
}
.smart-cleaner-form-details {
  flex: 2 2 !important;
  position: relative;
  margin-bottom: 0 !important;
  .smart-cleaner-loader {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.yellow-c {
  background-color: var(--yellow-color) !important;
  border-color: var(--yellow-color) !important;
}

.pink-c {
  background-color: var(--pink-color) !important;
  border-color: var(--pink-color) !important;
}

.blue-c {
  background-color: var(--blue-color) !important;
  border-color: var(--blue-color) !important;
}

.late-fees {
  position: absolute;
  right: 0px;
  display: inline-flex !important;
  padding: 0px !important;
  width: 18px;
  height: 18px;
  font-size: 10px;
  z-index: 1;
  border-radius: 50px;
  line-height: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-weight: 500;
  background-color: var(--success-bg-color);
  color: var(--text-white) !important;
  cursor: pointer;
}

.chart {
  .chart-box {
flex:1;
position: relative;
padding-top:40px;
padding-bottom:20px;
p {
  position: absolute;
  top:10px;
  left:20px;
  font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 0px;
    line-height: 1.5;
}

  }
  &.chart-50 {
    .chart-box {
      width: 50% !important;
    }
  }
}

.apt-container {
  background-color: var(--white-bg);
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 6px;
}
.total-row {
  background-color: var(--white-bg);
  font-size: 14px;
  padding: 0px;
  margin-bottom: 15px;
 .count {
    align-items: center;
    background-color: var(--primary-bg-color);
    border-radius: 50px;
    color: #fff;
    display: inline-flex!important;
    font-size: 11px;
    font-weight: 400;
    justify-content: center;
    line-height: 10px;
    margin-left: 5px;
    min-width: 18px;
    padding: 4px!important;
    text-align: center;
    white-space: nowrap;
    z-index: 9999;
}
}

.custom-toast-conatiner {
    top: 64px !important;
    right: 0 !important;
    // overflow-y: auto;
    width: 400px !important;
    //height: calc(100% - 64px);
    overflow-y: auto;
  z-index: 1049 !important;
  padding: 0 !important;
  height: auto;
  overflow-y: auto;
  max-height: calc(100% - 70px);
  .Toastify__toast-theme--colored.Toastify__toast--default {
    margin: 5px !important;
    background-color: #fdd !important;
    box-shadow: none;
    }
    .Toastify__close-button {
      color: var(--text-dark-color) !important;
    }
}

.Toastify#B {
  position: relative;
  z-index: 1;
}

.Toastify__toast-container {
  font-family: "Poppins", sans-serif;
  p {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    line-height: 1.7;
  }

}


.invoice-col-btn {
  .btn.btn-sm {
    padding: 1px 3px !important;
    font-size: 11px !important;
    margin-bottom: 5px !important;
    min-width: 20px !important;
  }
}

.btn-pd-0 {
  button {
    padding: 0 !important;
  }
}

.letter {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--primary-bg-color);
  color: var(--white-text-color);
  font-size: 15px;

}

.message-details {
  .subject {
    border-bottom: 1px solid var(--menu-border-color);
    h4 {
      font-size: 18px;
      font-weight: 400;
    }
  }
  .message-content {
    padding-top: 20px;
    padding-bottom: 20px;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        span {
          font-size: 13px;
          &.bold {
            font-weight: 500;
          }
        }
      }
    }
  }
  .message-footer-content {
    //border-top: 1px solid var(--menu-border-color);
    padding-top: 20px;
    .bold {
      font-weight: 500;
    }
  }
}

.status-connected {
  font-size: 13px;
  .dot {
    width: 9px;
    height: 9px;
    display: inline-block;
    border-radius: 100%;
    background-color: var(--success-bg-color);
  }
  .not-c-dot {
    width: 9px;
    height: 9px;
    display: inline-block;
    border-radius: 100%;
    background-color: var(--grey-text-color);
  }
}

.inventory-smart-template-wrap {
  .is-template-img {
    width: 50px;
    height: 50px;
    img {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
    }
  }
  .is-template-img1 {
    width: 30px;
    height: 30px;
    img {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
    }
  }
}

.is-template-img2 {
  width: 150px;
  img {
    max-height: 100%;
    width: 100%;
  }
}

.property-pricing-search {
  .s-content-div {
    flex: 1;
  }
  .width-100 {
    width: 100px;
    text-align: center;
  }
}
.my-ac-email-noti-info {
  font-size: 18px;
  color: var(--primary-link-color);
  position: relative;
  top: -10px;
  right: 0;
  left: 99%;
}
@-moz-document url-prefix() {
  .integration-wrapper .integration-left {
    .select__single-value {
      grid-area:1 !important;
    }
  }
  .edit-workorder-items-row {
    .select__single-value {
      grid-area: 1/1/1/1 !important;
    }
  }
  .property-config-table {
    .schedule-content {
      .select__single-value {
        grid-area: 1/1/1/1 !important;
      }
    }
  }

}


.reader-pages {
  .search-container {
   background-color: var(--white-bg);
   padding: 10px;
  }
   .c-name {
      h4 {
       font-size: 18px;
       font-weight: 500;
      }
   }
   .reader-header {
       border-bottom: 1px solid var( --menu-border-color);
   }
   .admin-name {
       //border-bottom: 1px solid var( --menu-border-color);
       padding: 5px 0;
   }
   .btn-info {
       min-width: 50px;
   }
   .inti-list {
       ul {
           margin: 0;
           margin-left: 7%;
           padding: 0;
           display: flex;
           flex-wrap: wrap;
           li {
               padding: 10px 0;
               list-style: none;
               width: 30%;
               a {
                   font-size: 13px;
                   padding: 6px 20px;
                   color: var(--anchor-hover-color) !important;
                   text-decoration: none !important;
                   border: 1px solid #b8cce4;
                   border-radius: 5px;
                   min-width: 60%;
                   display: inline-block;
                   text-align: center;
                   &:hover {
                       background-color: #b8cce4;
                       color: #fff;
                       box-shadow: 0px 0px 3px 0px #ccc;
                   }
               }
           }
       }


   }
   .feed-header {
       // display: flex;
       // justify-content: space-between;
       // align-items: center;
       padding: 5px 0px;
   }
   .feed-table-header {
       background-color: #ebe8e8;
       padding: 5px;
       margin-bottom: 10px;
       padding-top: 0;
   }
   .list-table-box {
       padding:0 0px;
   }
   .reader-form-wrap {
       background-color: var( --white-bg);
       height: 100vh;
       position: relative;
       .form-box {
           width: 50%;
           margin: 0 auto;
       }
       .footer {
           // position: absolute;
           padding-left: 0;
           padding-right: 0;
       }
   }

}

.accordion-button.apt-note-ac-btn {
  min-width: 30px !important;
  width: 30px !important;
  height: 28px;
  padding:0 !important;
    font-size: 12px !important;
    border-radius: 0.2rem !important;
  box-shadow: none;
  margin-right: 5px;
  margin-left: 0 !important;
  color: var(--primary-btn-ouline-text);
  background-color: transparent !important;
  border: 1px solid var(--primary-btn-ouline-border) !important;

  &:focus {
    box-shadow: none;
  }
  &:after {
    content: "";
    display: inline-block;
    position: relative;
    transition: transform 0.5s ease-in-out;
    background-image: url("../../assets/img/sorting-arrow-dsc.svg");
    background-size: 70%;
    background-position: center;
    margin: 0 auto;
  }
  &[aria-expanded="false"]::after {
    background-image: url("../../assets/img/sorting-arrow-dsc.svg");
    transform: rotate(-180deg);
  }

  &[aria-expanded="true"]::after {
    background-image: url("../../assets/img/sorting-arrow-dsc.svg");
    transform: rotate(0deg);
  }
}

.more-link {
  color: var(--white-text-color) !important;
  background-color: var(--primary-bg-color);
  border: 1px solid var(--primary-btn-ouline-border) !important;
  padding: 0px 5px;
  border-radius: 2px;
  line-height: 10px;
  margin-left: 10px;
  &:hover {
    color: var(--white-text-color) !important;
  }
}

.notes-wrap-item {
  // border-bottom: .5px solid var(--menu-border-color);
  padding-bottom: 3px;
  margin-bottom: 3px;
  &+.notes-wrap-item {
    border: 0 !important;
  }
  .light-green{
    border: 1px solid #43AA8C;
    color: #fff;
    background-color: #43AA8C;
    margin-right: 5px;
  }
  .red {
    border: 1px solid #F94045;
    color: var(--text-white);
    background-color: #F94045;
    margin-right: 5px;
  }
  .yellow {
    border: 1px solid #f99719; //dcd455
    color: #ffffff;
    background-color: #f99719;
    margin-right: 5px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
}

.search-container .card .card-body .settings-my-account {
  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .form-label {
      width: 60%;
      margin-bottom: 0;
    }
  }
}

.div-btn {
  color: var(--secondary-btn-text-color);
    background-color: var(--secondary-btn-bgcolor);
    border: 1px solid var(--secondary-btn-border);
    padding: 0.25rem 0.5rem !important;
    font-size: 12px !important;
    border-radius: 0.2rem !important;
    display: inline-block;
}

.view-password {
  position: relative;
  span {
    position: absolute;
    top: 31px;
    right: 7px;
    bottom: 0;
    margin: auto;
    line-height: 1;
  }
  .form-control.is-invalid {
    background-position: right calc(0.375em + 2rem) center;
  }
  input::-ms-reveal,
	input::-ms-clear {
	display: none;
}
}

.inventory-icon-image {
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  img {
    max-height: 100%;
    width: 100%;
  }
}

.text-white-label .form-check-label{
  color: var(--text-white);
  font-weight: 400;
  font-size: 13px;
}

.accordian-cleaner-insp-checkbox{
    position: absolute;
    right: 25px;
    top: 8px;
    z-index: 1;
}

.service-error-alert{
    width: 100%;
    word-break: break-all;
    padding-right: 150px;
    position: relative;
    display: flex;
    .btn {
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .qb-logo{
      width: 70px;
      padding: 20px;
      padding-top: 0;
    }
    .qb-error-title{
      font-size: 18px;
      font-weight: bold;
    }
}

.collectionfieldUpdated {
  .select__control  {
    border-color: #b5e5b2 !important;
    background-color: #b5e5b2 !important;
  }
}

.table-selected-uploaded {

  .form-group {
    &.filed-updated {
      .field-loader {
        margin-left: 5px;

        &.check {
          color: green;
          font-size: 14px;
        }
      }

      .form-control {
        border-color: #b5e5b2 !important;
        background-color: #b5e5b2 !important;

        &:focus {
          border-color: #b5e5b2 !important;
          background-color: #b5e5b2 !important;
        }
      }

      .select__control {
        border-color: #b5e5b2 !important;
        background-color: #b5e5b2 !important;
      }

      .react-datepicker__input-container input {
        border-color: #b5e5b2 !important;
        background-color: #b5e5b2 !important;
      }

      .form-check-input[type="checkbox"] {
        border-color: #b5e5b2 !important;
        background-color: #b5e5b2 !important;
      }

      .rc-time-picker input {
        border-color: #b5e5b2 !important;
        background-color: #b5e5b2 !important;
      }
    }

    &.filed-not-updated {
      .field-loader {
        margin-left: 5px;

        &.check {
          color: var(--text-danger);
          font-size: 14px;
        }
      }

      .form-control {
        border-color: #fdd0da !important;
        background-color: #fdd0da !important;

        &:focus {
          border-color: #fdd0da !important;
          background-color: #fdd0da !important;
        }
      }

      .select__control {
        border-color: #fdd0da !important;
        background-color: #fdd0da !important;
      }

      .react-datepicker__input-container input {
        border-color: #fdd0da !important;
        background-color: #fdd0da !important;
      }

      .form-check-input[type="checkbox"] {
        border-color: #fdd0da !important;
        background-color: #fdd0da !important;
      }

      .rc-time-picker input {
        border-color: #fdd0da !important;
        background-color: #fdd0da !important;
      }
    }
  }
}

.ca-apt {
  .react-datepicker-wrapper .react-datepicker__input-container input {
      line-height: 16px;
      font-size: 13px;
  }
  th {
    font-size: 12.5px;
font-weight: 600;
}
td {
  font-size: 13px;
}
}

.ca-schedule-apt-container {
  .cal-btns {
      .btn.btn-light {
          border-color: #d9d5d5 !important;
          width: 30px;
          flex: auto;
          min-width: 30px;

          line-height: 15px;
          padding: 6px 8px;

          &.with-text {
              width: 70px;
              flex: auto;
              min-width: 70px;
              font-size: 12px;
              background-color: #fff !important;
          }
          &.today-btn {
              width: 70px;
              flex: auto;
              min-width: 70px;
              font-size: 12px;
              margin-right: 10px;
          }
      }
  }

  .ca-schedule-apt {
      th {
          padding-top: 2px;
          padding-bottom: 2px;
          font-size: 12.5px;
    font-weight: 600;
      }
  }
  .edit-appt-btns {
      position: sticky;
      bottom: 57px;
      background-color: #ffffff;
      border-top: 1px solid var(--menu-border-color);
  }
}

.status-ca {
  display: flex;
  column-gap: 10px;
  .status-box {
      padding: 2px 5px;
      border-radius: 2px;
      font-size: 12px;
      color: var(--white-text-color);
      &.pending {
          background-color: var(--danger);
      }
      &.inprogress {
          background-color: var(--success);
      }
      &.completed {
          background-color: var(--primary-bg-color);
      }
      &.cancelled {
          background-color: var(--btn-warning-btn-bgcolor);
      }
  }
}
.area-details {
  .area-details-header {
      display: flex;
      column-gap: 15px;
      justify-content: space-between;
      //border: 1px solid var(--border-color-c);
      background-color: #ececec;
      .area-details-header-box {
          //ackground-color: var(--body-bg-color);

          padding: 5px 10px;
          border-radius: 2px;
          .title {
              font-size: 13px;
              font-weight: 500;
          }
          .text {
              font-size: 13px;
              margin-top: 5px;
          }
      }
      .btn-sm {
          min-width: 60px !important;
      }
  }
  .card-body {
      background-color: #ececec;
      margin: 10px 0;
      border-radius: 6px;
      padding: 7px 10px!important;
      .btn-sm {
          min-width: 60px !important;
          &.button-icon {
              min-width: 30px !important;
          }
      }
      h4 {
          font-size: 14px;
          color: #000;
          font-weight: 500;
          margin-bottom: 10px;
      }
      .note-text {
          position: relative;
          padding-right: 45px;
          font-size: 12.5px;
          margin-top: 15px;
          p {
              font-size: 12px;
              color: #8b8484;
              line-height: 22px;
              margin-bottom: 0;
          }
          .button-icon {
              position: absolute;
              right: 0;
              top:0
          }
      }
      .photos {
          margin-top: 15px;
          display: flex;
          flex-wrap: wrap;
          column-gap: 10px;
          .photo-item {
              width: 100px;
              height: 100px;
              position: relative;
              overflow: hidden;
              border: 1px solid var(--border-color-c);
              border-radius: 4px;
              margin-bottom: 10px;
              img {
                  max-width: 100%;
                  width: 100%;
                  max-height: 100%;
                  height: 100%;
              }
              .button-icon {
                  min-width: auto !important;
               height: auto !important;
                  padding: 0 3px !important;
                  position: absolute;
                  top: 3px;
                  right: 3px;
              
              }
          }
      }
  }
}
.popup-container {
  position: relative;
  display: inline-block;
}

.popup {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 1;
}

.popup-container:hover .popup {
  display: block;
}

.integ-logo-background-global {
background-color: #003425 !important;
padding: 5px;
width: 10%;
}


// analytics page css
.analytics-search {
  .react-datepicker-wrapper {
    width: 100%;
  }
}

.month-year-select {
  display: flex;
  column-gap: 10px;
  > div {
    flex: 0 0 40%;
    }

  .select__control {
    .select__indicator {
      padding: 0 !important;
    }
    .select__input-container {
      margin: 0 !important;
    }
  }
}

.status-center-small {
  .react-datepicker-wrapper  .react-datepicker__input-container input {
    padding: 2px;
    width: 100px;
    font-size: 11px;
  }
  .rc-time-picker input {
    padding: 2px;
    width: 100px;
    font-size: 11px;
  }
}

.form-check-reverse {
display: inline-block;
.form-check-label {
  color: #000000;
    font-weight: 600;
    font-size: 12px;
}
}

.pm-config-tick {
  display: flex;
  align-items: center;
  .tick {
    color: #19a470;
    font-size: 14px;
    margin-left: 5px;
    line-height: 1;
  }
}

.no-index{
  z-index: 0 !important;
}

.or-text {
  display: flex;
  align-items: center;
  padding-top: 9px;
  font-weight: bold;
}

.wizard-edit-link {
  color: var(--primary-link-color) !important;
  font-size: 16px !important;
  text-decoration: none !important;
}

.pl-0 {
  padding-left: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pl-12 {
  padding-left: 12px;
}

.integrationIcon {
  width:15px;
  display: inline-block;
}

.wizard-text{
  margin-top:20px;
}

.rms_property_label{
  word-wrap: break-word;
  word-break: break-all;
}

//to hide headers in new inventory feature -- appointment icon
.grid-js-inventory-table thead {
  display: none;
}

.no-border-grid {
  .gridjs .gridjs-wrapper {
    border: 0 !important;
  }
  tr {
    td {
      border: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      width:33%;
    }
    th{
      border: 0 !important;
    }
  }
}

.in-title {
  font-size: 13px;
  font-weight: 500;
  color: #000;
  width: 33%;
}

.in-h2 {
  color: #293072;
  margin-bottom: 0;
}

.no-match-text2 {
  color: #212529;
  font-size: 12px;
}

.font12-i {
  font-size: 12px !important;
  font-weight: 600;
}

.inventory-edit-link {
  color: var(--primary-link-color);
}

.table-width {
  width: 100%;
  border-collapse: collapse;
  th{
    font-weight: 600;
  }
}

.td-style {
  border: 0;
  padding: 8px 8px 8px 30px;
  font-size: 12px;
  color: #212529;
  background-color: #ffffff;
  width: 50%;
}
.tr-style {
  margin-left: 30px !important;
}

.td-style2 {
  border: 0;
  padding: 8px;
  font-size: 12px;
  color: #212529;
  background-color: #ffffff;
  width: 33%;
}

.th-column-f12{
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #212529 !important;
}

.service-column-33 {
  flex: 0 0 33% !important;
  .select__control {
    width: 100% !important;
  }
  .form-control{
    width: 100% !important;
  }
}
.service-column-25 {
  flex: 0 0 15% !important;
  display: flex;
  flex-wrap: nowrap;
}
.inventory_total_footer {
  width: 86px;
  display: inline-block;
  text-align: left;
}

// css added at 4th july

.staff-planner-table {
  .cal-btns {
    .btn.btn-light {
        border-color: #d9d5d5 !important;
        width: 30px;
        flex: auto;
        min-width: 30px;

        line-height: 15px;
        padding: 6px 8px;

        &.with-text {
            width: 90px;
            flex: auto;
            min-width: 90px;
            font-size: 12px;
            background-color: #fff !important;
        }
        &.today-btn {
            width: 90px;
            flex: auto;
            min-width: 90px;
            font-size: 12px;
            margin-right: 10px;
        }
    }
}
 
  h6 {
    font-size: 12px;
  }
  .main-boxx {
    display: flex;
    column-gap: 10px;
  }
  .boxx-a {
    background-color: #D3D3D3;
    padding: 2px 4px;
    border-radius: 4px;
    width:'150px';
    color: #fff;
    line-height: 1;
    .pr-name {
      margin-bottom: 5px;
      color: #000;
      font-weight: 500;
      font-size: 11px;
      line-height: 1;
      position: relative;
      width: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .sr-name {
      color: #000;
      margin-top: 2px;
      margin-bottom: 0;
      font-size: 10px;
      line-height: 1;
      position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    &.sr-name-50 {
      width: 50px;
    }
    &.sr-name-80 {
      width: 80px;
    }
    &.sr-name-110 {
      width: 100px;
    }
    }
    .ic {
      background-color: #F94045;
      border-radius: 2px;
      padding: 0px;
      width: 14px;
      height: 14px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .badge {
      line-height: 8px;
      // background-color: #4e4c4c !important;
      font-weight: normal !important;
      font-size: 8px;
      padding: 3px 4px 2px;
      margin-left : auto;
        }
    .time-x {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }
  }
  .boxx-b {
    padding: 2px 4px;
    border-radius: 4px;
    width:99%;
    color: #fff;
    line-height: 1;
    text-align: center;
    position: relative;
    .pr-name {
      margin-bottom: 5px;
      color: #000;
      font-weight: 500;
      font-size: 11px;
      line-height: 1;
      position: relative;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    }
    .sr-name {
      color: #000;
      margin-top: 2px;
      margin-bottom: 0;
      font-size: 10px;
      line-height: 1;
      overflow: hidden;
      white-space: nowrap; 
      text-overflow: ellipsis;
    }
    .ic {
      background-color: #F94045;
      border-radius: 2px;
      padding: 0px;
      width: 14px;
      height: 14px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &.close-button {
        position: absolute;
        right: 0;
        top: -3px;
    font-size: 10px;
    width: 12px;
    height: 12px;

      }
    }
    .badge {
      line-height: 8px;
      // background-color: #81b4e4 !important;
      font-weight: normal !important;
      font-size: 8px;
      padding: 3px 4px 2px;
      margin-left : auto;
        }
    .time-x {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }
  }
  .staff-planner-details {
    .left {
      width: 50px;
      height: 42px;
      display: flex;
      align-items: center;
      img {
        max-width: 100%;
    height: 100%;
    max-height: 100%;
      }
    }
    .right {
      h5 {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 0;
        color: #000;
      }
      p {
        font-size: 12px;
        margin-bottom: 0;
        font-weight: normal;
        color: #000;


      }
    }
  }
  .calender-legends {
    display: flex;
    justify-content: center;
    width: 100%;
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        li {
            padding: 5px 7px 0;
            .dot {
                width: 10px;
                height: 10px;
                display: inline-block;
                margin-right: 6px;
                border-radius: 50%;
                position: relative;
                top: 2px;

                &.inspection-icon {
                  background-color: #CEAEEF;
                }

                &.client-service-icon {
                  background-color: #E4DC89;
                }

                &.property-inspection-icon {
                  background-color: #b5f87a;
                }

                & + span {
                  font-size: 11px;
                }
            }
        }
    }
}

}

.staff-planner-table-wraper {
  width: 100%;
  max-height: 98vh;
  overflow: auto;
  table {
    white-space: nowrap;
    margin: 0;
    background: #e6e6e6;
    table-layout: fixed;
    width: auto;
    max-width: 100%;
    min-width: 100%;
    thead th{
      color: var(--sidebar-icon-color);
      position: sticky;
      top: 0;
      z-index: 2;
      text-align: center;
      background: white;
      font-size: 12px;
font-weight: 500;
color: #000;
padding: 6px 3px;
span {
  position: relative;
  //left: -5px;
}
      &:first-child {
        position: sticky;
        left: -1px;
        z-index: 3;
        background-color: #fff;
      }
    }
    tbody {
      th {
        position: sticky;
        left: -1px;
        z-index: 2;
        color: var(--sidebar-icon-color);
        text-align: left;
      background: white;
  }
  td {
    height: 30px;
    padding: 4px 3px;

  }
    }
  }
}

.boxx-b {
  position: relative; /* Ensure the container is positioned relatively */
  padding: 10px; /* Add some padding to make space for the close button */
}

.close-button {
  position: absolute;
  top: 5px; /* Adjust as needed */
  right: 5px; /* Adjust as needed */
  cursor: pointer;
}

.success-tick-button {
  position: absolute;
  right: 15px; 
  top:0px;
  .tick{
    color: green;
    height: 100px;
    display: block;
  }
}

.ic {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pr-name {
  margin: 10px 0;
}

.time-x {
  display: flex;
  align-items: center;
}

.sr-name {
  margin-top: 10px;
}

body.red-not-allowed {
  cursor: url('../../assets/img/not-allowed.png') 12 12, not-allowed;
}


.planner-box_border {
  border-radius: 4px;
  height: 50px;
  box-shadow:0 0 2px 1px green !important;
  position: relative;
}

.card-half-width {
  width: 50%;
  background-color: white; /* Ensuring the background is white */
  margin: 0 auto; /* Centering the card */
}

//to implement scroll on the rejected appointment grid

.rejected-appointments .gridjs-container .gridjs-wrapper{
  max-height: 127px !important;
}

//dashboard rejected appt count badge
.rejected-appointments-badge{
  font-size: 0.55rem;
  padding: 0.3em 0.6em;
}

.common-area-item-title{
  width: 210px;
}

.upload-staff-temp-staff-data{
  height: 328px;
  overflow-y: auto;
}

.checkbox-warning .form-check-input {
  appearance: none; /* Removes default styling */
  width: 16px;
  height: 16px;
  border: 2px solid red;
  border-radius: 4px;
  outline: none;
  position: relative;
}

.checkbox-warning .form-check-input:checked::before {
  content: '✔'; /* Adds a checkmark on checked state */
  font-size: 12px;
  color: white;
  position: absolute;
  top: -2px;
  left: 2px;
}

.form-group.only-checkbox {
  display: flex;
  align-items: center;
}

.checkbox-warning {
  .form-check-input[type="checkbox"] {
    border : 2px solid red !important;
    border-color: red !important;
  }
}

//Wallet Page card
.wallet-card-icon{
  width: 40px;
  height: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
  }
}

.autopay-status-active {
  color: #1acc02 !important;
  font-weight: bold;
}

.autopay-status-inactive {
  color: #f94045 !important;
  font-weight: bold;
}

.wallet-integration-left{
  width: 89% !important;
}

.redrock-inventory-scroll{
  overflow: hidden;
  .plain-table {
    overflow: auto;
    .table tr.sticky-header{
      top:0!important
    }
  }
}

.line-height-sm {
  line-height: 1.2;
}

.text-header-icon-color{
  color:var(--header-icon-color)
}

.global-no-spinner::-webkit-outer-spin-button,
.global-no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

/* Firefox */
.global-no-spinner {
  -moz-appearance: textfield !important;
}


.appointmentimg {
  position: absolute;
  //position: relative;
  background: black;
  top: 0;
  width: 100%;
  padding: 0px;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  z-index: 9999;
  overflow: hidden;
  .close {
    color: var(--text-dark-color);
    text-align: right;
    font-size: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9999;
    background: #fff;
    box-shadow: 0px 0px 3px 1px #7a7373;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
  }
}

.appointmentimg .carousel {
  .slider {
    overflow: visible!important;
  }
}
.appointmentimg .slider img {
    height: 90vh!important;
    max-width: 90%!important;
    object-fit: contain!important;
}