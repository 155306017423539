.lightbox-modal {
    .lightbox {
        position: relative;
    }
}

.lightbox {
    position: absolute;
    //position: relative;
    background: black;
    top: 0;
    width: 100%;
    padding: 0px;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    z-index: 9999;
    overflow: hidden;
    .close {
      color: var(--text-dark-color);
      text-align: right;
      font-size: 30px;
      margin-bottom: 30px;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 9999;
      background: #fff;
      box-shadow: 0px 0px 3px 1px #7a7373;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ccc;
    }
  }
  
  .carousel {
    .slider {
      margin: 0 20px;
      overflow: hidden;
      padding: 0rem 0;
      position: relative;
      height: calc(100% - 120px);
      text-align: center;
  
      .image-item__btn-wrapper {
        position: absolute;
        top: 5px;
        width: 30px;
        right: 0px;
        //display: none;
        .u-btn {
          font-size: 14px;
          line-height: 30px;
          height: 28px;
          width: 28px;
          margin: 4px 1px;
          padding: 0;
          border-radius: 50px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          transition: all 0.3s ease-in-out;
          color: var(--text-white);
          border: none;
          &.delete-image {
            background-color: #f34343 !important;
            &:hover {
              background-color: #ee1f1f !important;
            }
          }
          &.edit-image {
            background-color: #38cab3 !important;
            &:hover {
              background-color: #2db59f !important;
            }
          }
        }
      }
    }
  
    .slider img {
      width: auto;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      border-radius: 10px;
    }
  
    .react-multi-carousel-list {
      padding: 0rem 0 2rem 0;
    }
  
    .custom-dot-list-style button {
      border: none;
      background: rgb(255, 68, 68);
    }
    .react-multi-carousel-dot.react-multi-carousel-dot--active button {
      background: rgb(255, 68, 68) !important;
    }
  }
  .property-pics {
    .upload-image .image-item {
      height: 200px;
      width: 200px;
      position: relative;
      display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e7e2e2;
    background-color: #f3f3f3;
    padding-bottom: 40px;
    padding-top: 0px;
    .image-item__btn-wrapper {
      width: 30px;
    }
    img {
      max-width: 100%;
    width: auto;
    max-height: 100%;
    height: auto;
}
    
      .photo-click-overlay {
        background-color: transparent;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      }
      .photo-uploaded-by {
        position: absolute;
        z-index: 1;
        background: #000000a3;
        width: 100%;
        bottom: 0;
        color: #fff;
        font-size: 12px;
        padding: 3px 7px;
      p {
        font-size: 12px;
        margin: 0;
        &+p {
          font-size: 10px;
          color: #ccc;
        }
      }
      }
    }
  }
  