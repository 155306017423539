@import "../styles/colors.scss";

@media (max-width: 768px) {
    .main-container {
        margin-left: 90px;
        padding-bottom: 70px;
        
    }
    .main-header {
        padding-left: 80px !important;
        border-bottom: 0;
        box-shadow: 0px 1px 0px #9a9acc1a;
    }
    .scroll-btn {
        bottom: 12px;
        min-width: 45px;
        width: 45px;
    height: 45px;
    font-size: 20px !important;
    }
    .sidebar-wrapper {
        width: 80px;
        top: 63px;
        padding-top: 0;
        .sidebar-header {
            width: 80px;
            border: 0;
            .desktop-logo {
                display: none;
            }
            .mobile-logo {
                display: inline-block;
            }
        }
    
        .sidebar {
            ul {
                li.menu-item {
                    .menu-label {
                        display: none !important;
                        font-size: 13px !important;
                    }
                    .menu-arrow {
                        display: none;
                        top: 9px !important;
                    }
                    .sub-menu {
                        display: none;
                        &.collapse.show {
                            display: none;
                        }
                        .menu-label {
                            font-size: 12px;
                        }
                    }
                    a {
                        padding: 8px 15px 8px 20px;
                     
                    }
                }
            }
        }
    }

    .sidebar-toggled {
        // .main-container {
        //     margin-left: 290px;
        // }
        // .main-header {
        //     padding-left: 280px !important;
        // }
        .sidebar-wrapper {
            width: 280px !important;
            .sidebar-header {
                width: 280px !important;
            }
            .sidebar {
                ul {
                    li.menu-item {
                        .menu-label {
                            display: inline-block !important;
                        }
                        .menu-arrow {
                            display: inline-block !important;
                        }
                        .sub-menu {
                            &.collapse.show {
                                display: block;
                            }
                        }
                    }
                }
            }
            &.sidebar-hovered {
                .sidebar .sidebar-header {
                    width: 80px !important;
                    .mobile-logo {
                        display: inline-block !important;
                    }
                    .desktop-logo {
                        display: none !important;
                    }
                }
            }
        }
    }

    //// modal

    .modal {
        .modal-dialog {
            max-width: 100%;
        }
    }
    .footer {
        display: none;
    }
    .tab-container .nav-item .nav-link {
        padding: 6px 9px;
        font-size: 12px;
        line-height: 1.4;
        margin-bottom: 7px;
    }

    .print-content {
        width: 100%;
        overflow: hidden;
        overflow-x: auto;
    }

    .table-adjustment-footer {
        .gridjs .gridjs-footer {
            .invoice-total-row {
                flex-wrap: wrap;
                span {
                    margin-top: 5px;
                    margin-bottom: 5px;
                    width: 100%;
                }
                &.amount-add {
                    padding-right: 5px !important;
                }
                .right-add-amt {
                    padding-top: 24px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .custom-email-notif-checkboc-title {
        &::after {
            content: attr(data-title) !important;
        }
        .custom-email-notif-checkbox {
            display: inline;
            margin-right: 0.5rem;
        }
    }

    .modal .edit-template-page {
        height: auto !important;
        .template-wrap {
            flex-direction: column !important;
    
            .right-box {
                .modal-content-card {
                    padding: 0 !important;
                    margin-bottom: 0 !important;
                    .label-font-size  {
                        margin-bottom: 5px !important;
                    }
                }
                .accordion-link {
                    font-size: 12px;
                    padding: 5px 10px;
                }
                .accordion .checkList-container ul li {
                    font-size: 12px;
                    margin: 10px 7px 15px;
                }
                .accordion-button {
                    padding: 5px 10px;
                    &::after {
                        background-size: 70%;
                    }
                }
            }
    
            .right-box .service-details-row {
                .service-column.buttons-column {
                    flex: 0 0 30%;
                    margin-top: 22px;
                    margin-left: 0.5rem;
                }
                .service-column {
                    .btn {
                        min-width: 20px !important;
                        padding: 4px 7px;
                    }
                    .u-btn {
                        top: -2px;
    right: 12px;
                    }
                    .td-column {
                        margin-left: 5px !important;
                    }
                    &.img-column {
                        flex: 0 0 70px !important;
                    }
                }
            }
        }
    }

    .add-inspection-template {
        .template-details-wrap {
            flex-direction: column;
        }
        .custom-accordion {
            padding: 0;
            .accordion .card .accordion-collapse {
                padding-left: 5px;
            }
            .card .accordion-collapse {
                padding-left: 10px;
            }
            .checkList-container ul li input {
                margin-right: 7px !important;
            }
           
        }
    }

    .custom-table-header {
        .filter {
            flex-wrap: wrap;

            .break-coonections-btn {
                margin-left: 0 !important;
                margin-top: 1rem;
            }
        }
    }

    .cleaner-appointmnet-list.assign-staff-list {
        #no-more-tables {
            .gridjs-wrapper .gridjs-table .gridjs-tbody {
                .gridjs-tr td {
                    padding: 10px 10px !important;
                }
            }
        }
    }

    .appointment-list.workorders-list .gridjs.sub-table .gridjs-wrapper {
        .gridjs-tbody tr {
            padding-left: 12px !important;
        }

        .notes-wrap-workorder {
            position: relative;
            padding-left: 10px;
        }
        .action-with-icon {
            position: absolute !important;
            top: 10px;
            right: 10px;
        }

        .last-note-tr .last-note-td {
            padding-bottom: 0 !important;
        }
    }

    #no-more-tables td:before {
        position: unset;
    }

    .schedule-appt-btn {
        margin-bottom: 1rem;
        margin-left: 0 !important;
    }

    .edit-workorder-heading {
        flex-wrap: wrap;
        .edit-workorder-details {
            flex-wrap: wrap;
            margin-top: 0.5rem;

            .icon-on-modal {
                margin-left: 0 !important;
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
            }
        }
    }

    .property-config-table .property-config-form {
        .form-details {
            display: block;

            .css-b62m3t-container {
                min-width: 300px;
            }
        }
    }

    .search-container {
        .card {
            .card-body {
                form {
                    .group-by-appt {
                        .btn-group {
                            .btn {
                                min-width: 0px;
                            }
                        }
                    }
                }
            }
        }
    }

    .appt-tasklist-tb {
        .gridjs-wrapper {
            .gridjs-thead {
                th.gridjs-th:last-child {
                    width: 120px !important;
                }
            }
            .gridjs-tbody {
                .gridjs-tr {
                    td.gridjs-td:last-child {
                        position: relative !important;
                    }
                    .gridjs-td:first-child {
                        padding-right: 10px !important;
                    }
                }
            }
        }
    }

    .service-automation table tbody tr td:first-child {
        padding-bottom: 10px !important;
    }

    .internal-service table tbody tr td:first-child {
        padding-bottom: 10px !important;
    }

    .modal-dialog .modal-content .modal-body .dndTable table .gridjs-tbody tr td:nth-child(2) {
        .all-btns {
            display: inline-block;
        }
    }

    .edit-workorder-items-row .service-column.items-column2 {
        flex: 0 0 95%;
    }

    .edit-workorder-items-row .service-column.desc-column {
        flex: 0 0 95%;
    }

    .Subtask-list .gridjs-container .gridjs-wrapper .gridjs-tbody tr.gridjs-tr {
        padding-left: 10px !important;

        td .internal-services {
            left: -10px;
        }
    }
    .customize-schedule-staff-table .custom-table-header {
        flex-wrap: wrap;
        padding: 10px 0;
        .btns-actions {
            justify-content: space-between;
            width: 100%;
        }
        .show-sort {
            display: none;
        }
        .cal-btns {
            margin-left: 0 !important;
            margin-top: 10px;
            width: 100%;
            display: flex;
        }
    }
    .s-buttons-column {
        margin-top: 5px;
    }
    .a-btns.schedule-staff-list-btn-on-device {
        width: 35px;
        display: flex;
        flex-direction: row;
        margin-left: 5px;
        position: absolute;
        right: 14px;
        top: 8px;
        .btn {
            margin: 0 !important;
            margin-left: 5px !important;
        }
    }

    .responsive-list-2 {
        .custom-table-header {
            padding-top: 0;
            .filter {
                margin-top: 0 !important;
            }

            .admin-view-in-btns {
                .btn {
                    width: 42%;
                }
            }
        }
    }

    .status-center-table {
        .manager-status-center tr td {
            padding-left: 22px !important;
        }
     
    }
  

    .chart.chart-50 {
        .chart-box {
            width: 100% !important;
        }
    }
    .pd-lr-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .invoice-details {
        .invoice-from {
            border: 1px solid var(--menu-border-color);
            padding: 5px 10px;
        }
        .invoice-to {
            border: 1px solid var(--menu-border-color);
            padding: 5px 10px;
        }
    }

    .edit-invoice-mobile {
        .invoice-wrapper {
            .invoice-left {
                .form-details {
                    border: 1px solid var(--menu-border-color);
                    margin: 10px 0;
                    .form-group {
                        flex: 0 0 100% !important;
                        .title-l {
                            width: 100%;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .invoice-total-row.amount-add {
            flex-wrap: wrap;
            justify-content: flex-start !important;
            .add-note-col {
                width: 95%;
                margin-bottom: 7px !important;
            }
            input {
                width: 60px !important;
                text-align: center;
                margin-left: 10px !important;
            }
        }
    }
    .admin-create-invoice-list,
    .manager-wo-edit-invoice-list {
        .invoice-total-row {
            flex-wrap: wrap;
            padding: 5px 10px;
            .d-flex {
                width: 100%;
            }
            span {
                width: auto;
            }
            .adjustment-total-col {
                margin: 10px 0;
                width: auto;
            }
        }
        .btn.button-icon.direct-action {
            height: 24px;
        }
    }

    .manager-wo-edit-invoice-list {
            .form-details {
                border: 1px solid #afafaf !important;
                margin: 5px 0;
                .form-group {
                    flex: 0 0 100% !important;
                    &.desc-flex {
                        flex: 0 0 100% !important;
                    }
                    &.date-flex {
                        flex: 0 0 100% !important;
                    }
                    &.price-flex {
                        flex: 0 0 100% !important;
                    }
                    .form-label.title-l {
                        width: 100%;
                    }
                }
        }
    }
    .btn.button-icon.direct-action {
        height: 24px;
    }

    .service-checklist-table .table tr {
        td, th {
            width: 100% !important;
            
        }
        td:before {
            padding-left: 5px !important;
        }
    }
}

@media (max-width: 441px) {
    .search-container {
        .card {
            .card-body {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }

    .m-w-100 {
        width: 100% !important;
    }
    .main-container {
        margin-left: 0 !important;
        padding-right: 0;
        padding-top: 100px;
    }
    .main-header {
        padding-left: 0px !important;
        .mobile-center-logo {
            img {
                width: 80%;
            }
        }
        .navbar-nav-icons {
            background: var(--white-bg);
            width: 100%;
            margin: 0;
            position: fixed;
            left: 0px;
            right: 0;
            top: 0px;
            border: 0;
            transition: all 0.15s linear;
            padding: 0px;
            padding-right: 0px;
            top: 64px;
            height: 0;
            overflow: hidden;
            opacity: 0;
            z-index: -1;
            justify-content: flex-end;
            border-top:1px solid var(--menu-border-color);
            .help-header-icon {
                padding: 0 10px;
                svg {
                    font-size: 22px;
                }
            }
            &.show-header-menu {
                height: 50px;
                overflow: visible;
                opacity: 1;
                border-bottom: 1px solid var(--menu-border-color);
            }
        }
    }
    .sidebar-toggled .main-header {
        padding-left: 0px !important;
    }
    .sidebar-wrapper {
        width: 0;
        left: -1px;
        .sidebar-header {
            display: none !important;
        }
    }

    // btns css
    .btn {
        padding: 6px 9px;
        font-size: 12px;
        line-height: 1.4;
    }
    // modal css
    .modal {
        .modal-header {
            padding: 10px 13px;
            .btn-close {
                font-size: 10px;
            }
            .modal-title {
                font-size: 15px !important;
                margin-bottom: 0;
            }
        }
    }

    .page-title {
        font-size: 16px;
    }
    .form-btns {
        .form-group {
            margin-bottom: 0;
        }
    }
    .list-header-right {
        margin-bottom: 15px;
    }

    .service-details-row {
        .service-column {
            flex: auto;
            margin-right: 0px;
            &.buttons-column {
                flex: 0 0 100%;
            }
        }
    }

    .manage-cleaner {
        .service-details-row {
            .service-column {
                &.buttons-column {
                    flex: 0 0 100px;
                }
            }
        }
    }

    .upload-image {
        .image-item {
            height: 136px;
            width: 136px;
        }
    }

    .upload-clients .upload-image .btn.btn-outline-primary {
        font-size: 16px;
        min-height: 150px;
    }
    .assign-wrap {
        align-items: center;
        margin-bottom: 20px;
        .btn {
            padding: 6px 9px !important;
            font-size: 12px !important;
            line-height: 1.4 !important;
        }
    }

    .property-config-table {
        .property-config-form {
            .form-details {
                .form-group {
                    flex-basis: 100%;
                    &.select-updated {
                        height: auto;
                        width: 100%;
                    }
                }
            }
            .form-action {
                position: static;
                right: 0;
                padding: 10px 0;
                width: 100%;
                div {
                    flex-direction: row;
                    justify-content: flex-end;
                }
            }
        }
    }

    .service-page-config {
        .property-config-form {
            background: #fff;
            box-shadow: 0px 0px 2px 1px #e7e7e7;
            margin-bottom: 15px;
            border-radius: 4px;
            .form-details {
                .form-group {
                    margin-bottom: 10px;
                    .form-label {
                        display: flex;
                        line-height: 2;
                        &.default {
                            font-weight: 500;
                            color: var(--body-text-color);
                        }
                    }
                }
                .form-group.readonly-text {
                    flex-basis: 100%;
                }
            }
        }
    }
    .config-pages {
        .custom-table-header {
            padding: 0px 0 15px;
            .order-0 {
                margin-bottom: 10px;
            }
            .btn {
                margin: 3px !important;
            }
        }
    }
    .manager-price-config {
        .arriv-depar-wrap {
            .property-config-form {
                .form-action {
                    padding-bottom: 0;
                }
            }
        }
    }
    .manager-price-config.property-config-table
        .accordion
        .card
        .accordion-collapse
        .card-body
        .arriv-depar-wrap
        .property-config-form {
        border-bottom: 1px solid #aeb5e6 !important;
    }
    .manager-price-config .default-wrap .property-config-form .form-action {
        padding-bottom: 0 !important;
    }

    .property-config-table.edit-appointment {
        .minfont .form-details {
            .form-group {
                flex-basis: 100% !important;
            }
            .readonly-text {
                margin-bottom: 10px !important;
            }
        }
        .content .edit-appt-btns {
            bottom: 0 !important;
        }
        .card .card-header {
            flex-wrap: wrap;
            .appointment-list-icons {
                margin-bottom: 5px;
            }
        }
    }
    .responsive-list {
        .appointment-list-icons {
            padding: 3px 0;
        }
        .group-by-appt {
            margin-bottom: 20px !important;
        }
        .custom-table-header {
            .order-0 {
                width: 100%;
            }
            .btn {
                margin-bottom: 5px !important;
                width: 47%;
                &.button-icon {
                    width: auto !important;
                }
            }
            .filter {
                display: flex;
                width: 100%;
            }
        }
        &.grid-total-row .gridjs .gridjs-footer .total-row {
            justify-content: space-between;
            flex-wrap: wrap;
            .cost-col {
                width: 45%;
                margin-bottom: 10px;

                span {
                    display: block;
                }
            }
        }

        .content .custom-table-header {
            padding: 15px 0px;

            .apt-sub-btn {
                justify-content: space-between;

                button {
                    margin-left: 0 !important;
                    margin-bottom: 12px !important;
                    width: 49%;
                    padding: 0.5rem 0.25rem !important;
                }
            }

            .filter {
                margin-top: 1rem;
            }
        }

        &.appointment-list .gridjs .gridjs-tbody .gridjs-tr {
            td:nth-last-child(2) {
                padding-bottom: 0.5rem !important;
            }
        }

        .top-section-appointment {
            flex-wrap: wrap;
            padding: 0;
            margin-bottom: 0;
            padding-bottom: 0;
            .apt-icon-btn {
                padding: 10px 0;
            }
            .sub-apt {
                padding: 0.8rem;
                width: 100%;
                border-bottom: 1px solid #e4e4e4;
                border-collapse: collapse;
                margin-right: 0rem;
            }
        }
    }
    // appointments icons modal z-index changed
    .modal {
        z-index: 1080 !important;

        .all-btns {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            button {
                margin-left: 0rem !important;
                width: 40% !important;
                margin-bottom: 1rem;
            }
        }
    }

    .view-edit-report-modal {
        .item-wrapper {
            flex-wrap: wrap;
            .item-image {
                width: 20%;
            }
            .item-details {
                width: 80%;
            }
            .item-btns {
                width: 100%;
                margin-top: 7px;
                .mx-3 {
                    margin-left: 0 !important;
                }
                .btn-group {
                    min-width: 150px;
                    margin-left: auto;
                }
            }
        }
    }
    .responsive-show-last-col {
        .gridjs .gridjs-tbody .gridjs-tr {
            td:last-child {
                position: relative !important;
            }
            td:last-child:before {
                content: attr(data-title) !important;
                text-transform: capitalize;
            }
        }
    }
    .integration-wrapper {
        flex-wrap: wrap;
        .integration-left {
            width: 100%;
            .list-config-form {
                .form-details {
                    background-color: #fff;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    padding: 10px;
                    .form-group {
                        flex: 0 0 100%;
                        margin-bottom: 0;
                        .form-label {
                            width: 100%;
                            &.title-l {
                                font-weight: 500;
                            }
                        }
                        &.automated-link {
                            flex: 0 0 100%;
                        }
                    }
                }
                &.list-content {
                    background-color: transparent;
                }
            }
        }
        .integration-right {
            width: 100%;
        }
    }
    .property-owner-details-block {
        .radios {
            justify-content: space-between;
            margin-bottom: 10px !important;
        }
    }
    .edit-wo-invoice-details {
        .card-body {
            flex-wrap: wrap;
        }
        .invoice-from {
            flex: 0 0 100%;
        }
    }

    .cleaner-appointmnet-list.assign-staff-list #no-more-tables .gridjs-wrapper .gridjs-table .gridjs-tbody .gridjs-tr td {
        padding: 5px 10px !important;
        padding-left: 20px !important;
        &[data-column-id="column-1"] {
            position: static;
        }
        .form-group {
            margin-bottom: 0 !important;
        }
    }

    //inspection view-report-modal 
    .apt-info-inspection-tab {
        .card-header {
            padding: 5px;
        }
    .item-wrapper {
        display: block;
        padding: 0;
        .item-image {
            width: 100%;
        }
        .item-details {
            width: 100%;
        }
        .item-btns {
            width: 100%;
            .btn-group {
                min-width: 140px;
            }
        }
    }
}
}

.remove-flex {
    display: inline-block !important;
}

// @keyframes slideFromTop {
//     from {
//         height: 0px; opacity: 0;  overflow: hidden;
//     }
//     to {

//         height: 60px; opacity: 1;  overflow: visible;
//     }
//   }

@media only screen and (max-width: 441px) {
    .no-action-column {
        .gridjs-wrapper {
            .gridjs-tbody {
                .gridjs-tr {
                    td.gridjs-td:last-child {
                        position: relative !important;
                        padding-bottom: 10px !important;
                        &::before {
                            content: attr(data-title) !important;
                        }
                    }
                }
            }
        }
    }
    .custom-toast-conatiner {
        width: 100% !important;
    }
    .settings-my-account .form-group .form-label {
        width: 80%;
    }

    .appointment-pages {
        &.property-config-table {
            float: left;
            width: 100%;
            padding-bottom: 65px;
        }
    }

    .assignment-progress-wrapper {
        flex-wrap: wrap;
        .search-container {
            width: 100%;
        }
    }
    .assignment-progress-btn {
        right: 115px;
    }
}



.edit-workorder-items-row .service-column.items-column.item-flex-grow-15 {
    flex: 0 0 15%;
}

.flex-grow-25 {
    flex: 0 0 25%;
}

.flex-grow-15 {
    flex: 0 0 15%;
}

.flex-grow-75 {
    flex: 0 0 75%;
}

.flex-grow-40 {
    flex: 0 0 40%;
}

.flex-grow-50 {
    flex: 0 0 50%;
}

.flex-grow-60 {
    flex: 0 0 60%;
}

.flex-grow-none {
    flex: none !important;
}

.custom-email-notif-checkbox .form-check-input {
    width: 1.3em;
    height: 1.3em;
    border: 1.5px solid rgba(0, 0, 0, 0.25);
}



// css for mac safari

.is-safari {
    .manager-appointment-list { 
        .gridjs .gridjs-wrapper .last-note-tr {
            position: sticky; 
            .last-note-td .notes-wrap-workorder {
                top:63px
            }
        }
    }
    .staff-list-page, .client-list-column {
        a {
            word-break: break-all;
        }
    }
    .appointment-list.cl-appointment-list .gridjs-wrapper .gridjs-tbody .gridjs-tr .gridjs-td:first-child {
        position: relative !important;
    }

    // assign staff grid css
    .assign-staff-list { 
        .gridjs-tr {
            .gridjs-td {
                &[data-column-id="column-22"], &[data-column-id="column-23"], &[data-column-id="column-24"], &[data-column-id="column-25"], &[data-column-id="column-26"] {
                    width: 200px !important;
                }
            }

            .gridjs-td {
                &[data-column-id="column-2"] {
                    .appointment-list-icons {
                flex-wrap: wrap !important;
                    }
                }
            }
        }
    }

}


@media (max-width: 668px) {
    .unit-status-view-table {
      
        
        .auto-left {
            margin-left: auto;
        }
        .today-btn {
            padding: 10px 8px !important;
            flex: none;
        }
        .event-details {
            max-width: 130px;
            .edit-link-blue {
                display: inline-block;
                white-space: normal;
            }
        }
    }
 }

 @media (max-width: 441px) {
    .table-calendar {
        position: relative;
        .calender-overlay-mobile {
            display: flex;
            background-color: #000000b3;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 49;
    width: 100%;
    height: 100%;
    align-items: center;
    font-size: 21px;
    color: #fff;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    text-align: center;
    img {
        width: 80px;
        margin-bottom: 20px;
    }
        }
    }
 }

 .inactive-dropdown-item {
    color:grey !important;
 }