@import '../../styles/colors.scss';

.toggle-switch {
  position: relative;
  margin-right: 10px;
  width: 65px;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  .toggle-switch-checkbox {
    display: none;
  }
  .toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid var(--menu-border-color);
    border-radius: 20px;
    margin: 0;
    &:focus {
      outline: none;
      > span {
        box-shadow: 0 0 2px 5px var(--bs-danger);
      }
    }
    > span:focus {
      outline: none;
    }
  }
  .toggle-switch-inner{
    display: block;
    width: 200%;
    margin-left: -100%;
    //transition: all 1s ease-in-out;
    animation: marginleftanimation  0.3s ease-in-out;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 30px;
      padding: 0;
      line-height: 30px;
      font-size: 12px;
      color: var(--text-white);
      font-weight: 400;
      box-sizing: border-box;
    }
    &:before {
      content: attr(data-yes);
      padding-left: 10px;
      background-color: var(--primary-bg-color);
      color:var(--text-white);
    }
  }
 .toggle-switch-disabled {
    background-color: var(--text-grey-color);
    cursor: not-allowed;
    &:before {
      background-color:  var(--text-grey-color);
      cursor: not-allowed;
    }
  }
  .toggle-switch-inner:after {
    content: attr(data-no);
    padding-right: 10px;
    background-color:  var(--text-grey-color);;
    color:var(--text-white);
    text-align: right;
  }
  .toggle-switch-switch {
    display: block;
    width: 20px;
    margin: 5px;
    background:var(--text-white);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 35px;
    border: 0 solid var(--text-grey-color);;
    border-radius: 20px;
    //transition: all 0.3s ease-in 3s;
    animation: leftanimation  0.3s ease-in-out;
  }
  .toggle-switch-checkbox:checked + .toggle-switch-label {
    .toggle-switch-inner {
      margin-left: 0;
      animation: marginanimation  0.3s ease-in-out;
    }
    .toggle-switch-switch {
      right: 0px;
      animation: rightanimation  0.3s ease-in-out;
    }
  }
  &.small-switch {
    width: 40px;
    .toggle-switch-inner {
      &:after,
      &:before {
        content: "";
        height: 20px;
        line-height: 20px;
      }
    }
    .toggle-switch-switch {
      width: 16px;
      right: 20px;
      margin: 2px;
    }
  }
  @media screen and (max-width: 991px) {
    transform: scale(0.9);
  }
  // @media screen and (max-width: 767px) {
  //   transform: scale(0.825);
  // }
  // @media screen and (max-width: 575px) {
  //   transform: scale(0.75);
  // }
}

.price-toggle-switch {
  .toggle-switch {
    width: 86px;
    .toggle-switch-inner:after {
      padding-right: 20px;
    }
    .toggle-switch-switch {
      right: 55px;
      animation: lefttwoanimation  0.3s ease-in-out;
    }
    .toggle-switch-checkbox:checked + .toggle-switch-label {
      .toggle-switch-inner {
        margin-left: 0;
        animation: marginanimation  0.3s ease-in-out;
      }
      .toggle-switch-switch {
        right: 0px;
        animation: righttwoanimation  0.3s ease-in-out;
      }
  }
}
&.week-toogle {
  .toggle-switch .toggle-switch-inner:after {
    padding-right: 8px;
}
}
}


.show-hide-toggle {
  .toggle-switch {
    width: 75px;
    .toggle-switch-inner:after {
      padding-right: 15px;
    }
    .toggle-switch-switch {
      right: 46px;
      animation: hideanimation  0.3s ease-in-out;
    }
    .toggle-switch-checkbox:checked + .toggle-switch-label {
      .toggle-switch-inner {
        margin-left: 0;
        animation: marginanimation  0.3s ease-in-out;
      }
      .toggle-switch-switch {
        right: 0px;
        animation: showanimation  0.3s ease-in-out;
      }
  }
}
}

.date-toggle{
  .toggle-switch {
    width: 120px;
    .toggle-switch-inner:after {
      padding-right: 30px;
    }
    .toggle-switch-switch {
      right: 90px;
      animation: leftdateanimation  0.3s ease-in-out;
    }
    .toggle-switch-checkbox:checked + .toggle-switch-label {
      .toggle-switch-inner {
        margin-left: 0;
        animation: marginanimation  0.3s ease-in-out;
      }
      .toggle-switch-switch {
        right: 0px;
        animation: rightdateanimation  0.3s ease-in-out;
      }
  }
}
&.sutask-type {
  .toggle-switch-inner:after {
    padding-right: 14px;
  }
}
&.apt-type-toggle {
  .toggle-switch-inner:after {
    padding-right: 14px;
  }
}
}

.language-select {
  border-top: 1px solid var(--header-border-color) !important;
  .menu-icon {
    font-size: 17px;
    margin-right: 10px;
    width: 24px;
    text-align: center;
    color: var(--header-icon-color);
    svg {
      font-size: 21px;
      color: var(--header-icon-color);
    }
  }
  .toggle-switch {
    width: 80px;
    margin-right: 0;
    .toggle-switch-inner:before {
      height: 27px;
    line-height: 27px;
    padding-left: 8px;
    }
    .toggle-switch-inner:after {
      padding-right: 12px;
      height: 27px;
    line-height: 27px;
    }
    .toggle-switch-switch {
      right: 55px;
      width: 16px;
    height: 16px;
      animation: lefttwoanimation  0.3s ease-in-out;
    }
    .toggle-switch-checkbox:checked + .toggle-switch-label {
      .toggle-switch-inner {
        margin-left: 0;
        animation: marginanimation  0.3s ease-in-out;
      }
      .toggle-switch-switch {
        right: 0px;
        animation: righttwoanimation  0.3s ease-in-out;
      }
  }
}
}

@keyframes marginanimation {
  0% {
    margin-left: -100%;
  }
  100% {
    margin-left: 0px;
  }
}

@keyframes marginleftanimation {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -100%;
  }
}


@keyframes rightanimation {
  0% {
   right:35px
  }
  100% {
    right:0px
  }
}

@keyframes leftanimation {
  0% {
   right:0px
  }
  100% {
    right:35px
  }
}

@keyframes righttwoanimation {
  0% {
   right:55px
  }
  100% {
    right:0px
  }
}

@keyframes lefttwoanimation {
  0% {
   right:0px
  }
  100% {
    right:55px
  }
}

@keyframes rightdateanimation {
  0% {
   right:90px
  }
  100% {
    right:0px
  }
}

@keyframes leftdateanimation {
  0% {
   right:0px
  }
  100% {
    right:90px
  }
}

@keyframes showanimation {
  0% {
   right:46px
  }
  100% {
    right:0px
  }
}

@keyframes hideanimation {
  0% {
   right:0px
  }
  100% {
    right:46px
  }
}


