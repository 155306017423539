@import '../../styles/colors.scss';


.breadcrumb {
    margin-bottom: 10px;
    .breadcrumb-item {
        font-size: 13px;
        line-height: 20px;
        a {
            font-size: 14px;
            text-decoration: none;
            line-height: 20px;
            color: var(--menu-icon-color);
            &:hover {
                color: var(--black-text-color) !important;
            }
        }
        &.active {
            color: var(--primary-bg-color);
        }
        &::before {
            content: none !important;
        }
        &.icon {
            color: var(--menu-icon-color) !important;
        }
    }
}