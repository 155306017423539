.property-config-table {
    .property-config-form {
        display: flex;
        flex-wrap: wrap;
        //background: #f9fbfb;
        //border-bottom: 1px solid #dfe3ff;
        margin-bottom: 0px;
        padding: 10px 10px 0;
        position: relative;
       
        .conf-tilte {
            font-size: 14px;
            line-height: 22px;
            font-weight: 500;
            flex: 0 0 100%;
        }
        .form-details {
            // display: grid;
            // grid-template-rows:repeat(2, 45px);
            // grid-auto-flow: column;
            // column-gap:1rem;
            // row-gap:10px;
            // width: 90%;

            display: flex;
            flex-wrap: wrap;
            column-gap: 6px;
            width: 100%;
            .form-group {
                margin-bottom: 5px;
                flex-basis: calc(11.6% - 0px);
                position: relative;
                &.appointment-note {
                    //flex-basis: calc(50% - 0px);
                    //flex: 0 0 auto;
                    flex: 1 !important;
                    //flex-basis: auto !important;
                    textarea {
                        min-height: 27.6px !important;
                    }
                    &.appointment-note-textarea {

                    }
                }
                &.only-checkbox {
                    flex-basis: calc(3% - 0px);
                }
                .form-label {
                    color: var(--sidebar-icon-color);
                    font-size: 11px;
                    line-height: 14px;
                    margin-bottom: 0;
                    position: relative;
                    letter-spacing: -0.1px;
                    .field-loader2 {
                        position: absolute;
                        right: 0;
                        top:0
                    }
                    .tick {
                        position: absolute;
                        right: -14px;
                        top:0
                    }
                }
                .form-control {
                    font-size: 12px;
                    line-height: 22px;
                    font-weight: 500;
                    border: 1px solid var(--menu-border-color) !important;
                    padding: 2px 7px;
                    &:focus {
                        background-color: #fff !important;
                        box-shadow: none !important;
                        outline: none !important;
                    }
                }
                .form-control[readonly]  {
                    background-color: var(--readonly) !important;
                    &:focus {
                      background-color: var(--readonly) !important;
                    }
                  }
                  
                  

                .select__control {
                    min-height: 28px !important;
                    //height: 28px !important;
                    .select__input {
                        margin: 0 !important;
                        padding-top: 0 !important;
                        padding-bottom: 0 !important;
                    }
                }
                .select__menu {
                    .select__option {
                        padding: 5px 8px;
                        font-size: 12px;
                    }
                }
                .select__input-container {
                    margin: 0 !important;
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                }
                .select__value-container {
                    height: 24px !important;
                    min-height: 24px !important;
                    &.select__value-container--is-multi  {
                        height:auto !important;
                    }
                }
                .select__multi-value {
                    margin: 0 !important;
                    top: -1px !important;
                    padding: 1px !important;
                }
                .select__multi-value__label {
                    padding: 0px !important;
                }
                .select__indicator,
                .css-1gtu0rj-indicatorContainer {
                    padding: 0 !important;
                }
                .select__placeholder {
                    font-size: 12px;
                }
                .select__single-value {
                    color: var(--input-text-color) !important;
                    font-size: 12px;
                }

                .select__single-value--is-disabled {
                    opacity: 0.6;
                }
                &.filed-updated {
                    .field-loader {
                        margin-left: 5px;
                        &.check {
                            color: green;
                            font-size: 14px;
                        }
                    }

                    .form-control {
                        border-color: #b5e5b2 !important;
                        background-color:#b5e5b2 !important;
                        &:focus {
                            border-color: #b5e5b2 !important;
                            background-color: #b5e5b2 !important;
                        }
                    }
                    .select__control  {
                        border-color: #b5e5b2 !important;
                        background-color: #b5e5b2 !important;
                    }
                    .react-datepicker__input-container input  {
                        border-color: #b5e5b2 !important;
                        background-color: #b5e5b2 !important;
                    }
                    .form-check-input[type="checkbox"] {
                        border-color: #b5e5b2 !important;
                        background-color: #b5e5b2 !important;
                    }
                    .rc-time-picker input {
                        border-color: #b5e5b2 !important;
                        background-color: #b5e5b2 !important;
                    }
                }

                &.filed-not-updated {
                    .field-loader {
                        margin-left: 5px;
                        &.check {
                            color: var(--text-danger);
                            font-size: 14px;
                        }
                    }
                    .form-control {
                        border-color: #fdd0da !important;
                        background-color:#fdd0da !important;
                        &:focus {
                            border-color: #fdd0da !important;
                            background-color: #fdd0da !important;
                        }
                    }
                    .select__control  {
                        border-color: #fdd0da !important;
                        background-color: #fdd0da !important;
                    }
                    .react-datepicker__input-container input  {
                        border-color: #fdd0da !important;
                        background-color: #fdd0da !important;
                    }
                    .form-check-input[type="checkbox"] {
                        border-color: #fdd0da !important;
                        background-color: #fdd0da !important;
                    }
                    .rc-time-picker input {
                        border-color: #fdd0da !important;
                        background-color: #fdd0da !important;
                    }
                }

                &.select-updated {
                    .select__control {
                        border-color: #b5e5b2 !important;
                        background-color: #b5e5b2;
                    }
                    .select__indicator {
                        color: #525151;
                    }
                    .tick {
                        color: green;
                        font-size: 14px;
                        margin-left: 5px;
                    }
                    .tick-red {
                        color: var(--text-danger);
                        font-size: 14px;
                        margin-left: 5px;
                    }
                }

                .react-datepicker__input-container {
                    input {
                        padding: 2px 7px;
                        line-height: 22px;
                        font-size: 12px;
                    }
                }
                .rc-time-picker {
                    input {
                        padding: 2px 7px;
                        line-height: 22px;
                        font-size: 12px;
                    }
                    .rc-time-picker-clear {
                        top: 3px;
                    }
                }
            }
        }
        .form-action {
            width: 5%;
            text-align: right;
            display: flex;
            flex-direction: column;
            padding-top: 9px;
            position: absolute;
            right: 10px;
            div {
                display: flex;
                flex-direction: column;
            }
            span {
                font-size: 20px;
                margin: 0 5px;
            }
        }
        &:last-of-type {
            border-bottom: 0 !important;
        }
    }
    .accordion {
        .card {
            margin-bottom: 10px;
            border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
            border-top-left-radius: 0.25rem !important;
            border-top-right-radius: 0.25rem !important;
            .card-header {
                display: flex;
                padding: 0;
                background-color: var(--white-bg);
                .accordion-link {
                    flex: 0 0 auto;
                    margin-right: 20px;
                    font-size: 14px;
                    padding: 10px;
                    color: var(--primary-link-color);
                    cursor: pointer;
                    span {
                        color: var(--text-dark-color);
                        font-size: 13px;
                    }
                }
                .accordion-wrap {
                    flex: 0 0 auto;
                    margin-right: 0px;
                    font-size: 14px;
                    padding: 10px;
                }
                .accordion-button {
                    font-size: 14px;
                    padding: 10px;
                    border: 0;
                    background-color: transparent;
                    box-shadow: none;

                    &:focus {
                        box-shadow: none;
                    }
                    &:after {
                        transition: transform 0.5s ease-in-out;
                    }
                    &[aria-expanded="false"]::after {
                        background-image: url("../../assets/img/sorting-arrow.svg");
                        transform: rotate(-180deg);
                    }

                    &[aria-expanded="true"]::after {
                        background-image: url("../../assets/img/sorting-arrow.svg");
                        transform: rotate(0deg);
                    }
                }
            }

            .accordion-collapse {
                .card-body {
                    font-size: 13px;
                    padding: 0;

                    .default-wrap {
                        //padding-bottom: 10px;
                        //background: #f9fbfb;
                        background: var(--white-bg);
                        border-bottom: 2px solid #dfe3ff;
                        border-bottom-left-radius: 4px;
                        border-bottom-right-radius: 4px;
                        &:last-of-type {
                            border-bottom: 0 !important;
                        }
                        .form-action {
                            padding-top: 0;
                            top:0;
                            right: 7px;
                            height: 100%;
                            
                            div {
                                // flex-direction: row;
                                // flex-wrap: wrap;
                                flex-flow: column wrap;
                                height: 100%;
                                padding-left: 15px;
                            }
                            span {
                                margin: 0 3px;
                            }
                        }
                    }

                    .arriv-depar-wrap {
                        border-bottom: 1px solid #ccc;
                        border-top: 1px solid #ccc;
                        padding-bottom: 10px;
                        //background-color: #f8fcff;
                        background-color: #ccdae4;
                        padding-left: 15px;
                        .property-config-form {
                            margin-bottom: 0;
                            border-bottom: 1px solid #dfe3ff;
                        }
                        &:last-of-type {
                            border-bottom: 0 !important;
                        }
                    }
                }
            }
        }
    }
}

.manager-price-config {
    .accordion .card {
        .card-header {
            .accordion-button {
                &[aria-expanded="false"]::after {
                    background-image: url("../../assets/img/sorting-arrow-dsc-p.svg");
                    transform: rotate(-180deg);
                }

                &[aria-expanded="true"]::after {
                    background-image: url("../../assets/img/sorting-arrow-dsc-p.svg");
                    transform: rotate(0deg);
                }
            }
        }
        .accordion-collapse .card-body .cleaner-name {
            padding: 5px 10px;
            font-size: 14px;
            margin: 0 0px;
            line-height: 1.4;
            font-weight: 500;
            background: #eee;
        }
    }
    .default-wrap {
        .property-config-form {
            padding: 18px 10px 18px 25px !important;
            .form-action {
                top: -8px;
                span {
                    font-size: 20px;
                    margin: 0 5px;
                    line-height: 1.4;
                }
            }
        }
    }
    .arriv-depar-wrap {
        .property-config-form {
            padding: 8px 10px !important;
            .form-action {
                top: 15px;
                span {
                    font-size: 20px;
                    margin: 0 5px;
                    line-height: 1.4;
                }
            }
        }
    }
    .internal-services {
        .accordion .card {
            .card-header {
                .accordion-button {
                    &[aria-expanded="false"]::after {
                        background-image: url("../../assets/img/sorting-arrow.svg");
                        transform: rotate(-180deg);
                    }

                    &[aria-expanded="true"]::after {
                        background-image: url("../../assets/img/sorting-arrow.svg");
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }
}

// placeholder color
.form-details .form-control::-webkit-input-placeholder {
    color: var(--grey-text-color);
    opacity: 0.6;
    font-weight: 400;
    font-size: 12px;
}
.form-details .form-control:-ms-input-placeholder {
    color: var(--grey-text-color);
    opacity: 0.6;
    font-weight: 400;
    font-size: 12px;
}

.form-details .form-control::placeholder {
    color: var(--grey-text-color);
    opacity: 0.6;
    font-weight: 400;
    font-size: 12px;
}

.lds-dual-ring {
    display: inline-block;

    animation: lds-dual-ring 1.1s ease-out;
}
//   .lds-dual-ring:after {
//     content: " ";
//     display: block;
//     width: 64px;
//     height: 64px;
//     margin: 8px;
//     border-radius: 50%;
//     border: 6px solid #000;
//     border-color: #000 transparent #000 transparent;
//     animation: lds-dual-ring 1.2s linear infinite;
//   }

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

.field-loader {
    // position: absolute;
    // top:0;
    width: 20px;
    height: 20px;
    img {
        width: 20px;
    }
}

.field-loader2 {
    // position: absolute;
    // top:0;
    width: 20px;
    height: 20px;
    img {
        width: 20px;
    }
}

.select-updated {
    // position: absolute;
    // top:0;
    width: 20px;
    height: 20px;
    img {
        width: 20px;
        margin-left: 5px;
    }
}

.manager-price-config {
    .outside-services {
        .accordion {
            .card {
                background-color: #277da2;
                border: 1px solid #277da2;
                border-top: 1px solid #277da2 !important;
                .card-header {
                    background-color: #277da2;
                    //border-bottom: 1px solid #ccecff;
                    .accordion-link {
                        color: var(--text-white);
                        span {
                            color: var(--text-white);
                            font-size: 13px;
                        }
                    }
                }
                .accordion-collapse .card-body {
                    padding: 0 5px 5px;
                    background: #f1faff;
                    .cleaner-name {
                        background: #efefef;
                        color: var(--text-white);
                    }
                    .arriv-depar-wrap {
                        background-color: #ccecff;
                    }
                }
            }
        }
    }
    // .internal-services {
    //     .accordion {
    //         .card {
    //             background-color: #d689ae;
    //             border: 1px solid #d689ae;
    //             border-top: 1px solid #d689ae !important;
    //             .card-header {
    //                 background-color: #d689ae;
    //                 //border-bottom: 1px solid #ffdced;
    //                 .accordion-link {
    //                     color: var(--text-white);
    //                 }
    //             }
    //             .accordion-collapse .card-body {
    //                 padding: 0;
    //                 background: #ffdced;
    //                 .arriv-depar-wrap {
    //                     background-color: #ffdced;
    //                 }
    //             }
    //         }
    //     }
    // }
}

.service-page-config {
    .property-config-form {
        //background-color: #eef0f5;
        border-bottom: 1px solid #e9e6e6;
        padding: 5px 10px;
        .form-details {
            align-items: center;
            .form-group {
                flex-basis: calc(12% - 0px);

                .form-label {
                    font-size: 12.5px;
                    align-items: center;
                }
                .form-control[readonly] {
                    background: #d9d9d9;
                }
                &.readonly-text {
                    flex-basis: 8%;
                }
                .form-check-input {
                    font-size: 16px;
                    margin: 0 5px 0 0;
                }
                &.loading-wrap {
                    position: relative;
                    .loading-span {
                        position: absolute;
                        right: 7px;
                        .load-img {
                            position: absolute;
                            right: 4px;
                        }
                    }
                }
            }
            &.service-config-form-details {
                justify-content: space-evenly !important;
                .form-group {
                    flex: 1 !important;
                    &.only-checkbox {
                        flex: 0 0 2% !important;
                    }
                    &.property-id-readonly{
                        flex: 0 0 6% !important;
                    }
                }
            }
        }
        &.config-form-heading {
            padding: 7px 10px;
            background-color: #eaeaea;
            .form-group {
                .form-label {
                    font-weight: 600;
                    font-size: 11.5px;
                    color: var(--form-edit-label-color);
                }
            }
        }
    }
}

.config-page-footer {
    background-color: var(--white-bg);
    border-top: 1px solid #eeeeee;
    padding: 10px 0;
}

.appointment-pages {
    &.property-config-table {
        .config-form-heading {
            padding: 0;
            background-color: #eaeaea;
            .form-details {
                padding: 4px 10px;
                width: 98%;

                .form-group {
                    margin-bottom: 5px;
                    flex-basis: calc(11.6% - 0px);
                    position: relative;
                    .form-label {
                        color: var(--form-edit-label-color);
                        font-size: 11.5px;
                        font-weight: 600;
                    }
                    &.action-heading {
                        width: 5%;
                        text-align: right;
                        display: flex;
                        flex-direction: column;
                        padding-top: 9px;
                        position: absolute;
                        right: 10px;
                    }
                }
            }
        }
        .property-config-form {
            .form-details {
                width: 97%;
                column-gap: 13px;
            }
        }
    }
    .schedule-content {
        //background-color: #b8cce4;
        background-color: #b8cce4;
        position: relative;
        float: left;
        width: 100%;
        overflow-y: auto;
        height: 100%;
        max-height: calc(100vh - 220px);
        .schedule-btns {
            position: sticky;
            bottom: -9px;
            width: 100%;
            padding: 7px 0 !important;
            background: #b8cce4;
            //background: #ffff;
        }
    }
    &.single-schedule-day {
        .schedule-content {
            max-height: calc(100vh - 330px);
        }
    }
}

.upload-property-content {
    .card {
        background: #ccc;
        .card-header {
            background-color: #53809a !important;
            color: var(--text-white);
            .accordion-link {
                color: var(--text-white) !important;
                padding: 6px 10px !important;
                .count {
                    align-items: center;
                    background-color: #fff;
                    border-radius: 50px;
                    color: #000;
                    display: inline-flex !important;
                    font-size: 11px !important;
                    font-weight: 400;
                    justify-content: center;
                    line-height: 10px;
                    margin-left: 5px;
                    min-width: 18px;
                    height: 18px;
                    padding: 4px !important;
                    text-align: center;
                    white-space: nowrap;
                    z-index: 9999;
                    border: 1px solid #b5c4d6;
                }
            }
        }
        .property-config-form {
            border-bottom: 1px solid #e3d6d6 !important;
            background-color: #b5c4d6;
            padding: 0;
            .form-details {
                width: 100%;
                background-color: #fff;
                padding: 10px 10px;
                column-gap: 8px;
                .form-group {
                    flex-basis: 7.5%;
                    //overflow: hidden;
                    &.minwidth1 {
                        flex-basis: 4.5%;
                    }
                    &.minwidth2 {
                        flex-basis: 10%;
                    }
                    .select__control {
                        min-height: 24px !important;
                        height: 24px !important;
                        margin-bottom: 5px;
                        width: 100px;
                    }
                    .select__value-container {
                        height: 24px !important;
                        min-height: 24px !important;
                        padding: 2px;
                    }
                    &.multi-value {
                        .form-label {
                            min-height: 24px;
                            margin-bottom: 5px;
                        }
                    }
                }
                // .form-group {
                //     flex-basis: 120px;

                //     &.minwidth1 {
                //         flex-basis: 30px;
                //     }
                //     &.minwidth {
                //         flex-basis: 50px;
                //     }

                //     &.minwidth2 {
                //         flex-basis: 70px;
                //     }
                // }
            }
            .entry-content {
                display: flex;
                flex-basis: 100%;
                padding: 5px;
                background-color: #e3e6e7;
                font-size: 12px;
                .font-weight-bold {
                    font-weight: 600;
                }
                &.address {
                    background-color: #ffffff;
                    font-style: italic;
                    border-top: 1px solid #f8f8f8;
                    span {
                        margin-right: 10px;
                    }
                }
            }
        }
        .header-title-details {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            background-color: #e3e6e7;
            padding: 0px 10px 5px;
            column-gap: 8px;
            .form-group {
                flex-basis: 7.5%;
                margin: 0;
                &.select-all-check {
                    padding-top: 2px;
                }
                .form-check-input {
                    font-size: 14px;

                    margin-right: 5px;
                }
                .form-label {
                    font-size: 11px;
                    font-weight: 500;
                    margin: 0;
                }
                &.minwidth1 {
                    flex-basis: 4.5%;
                }
                &.minwidth2 {
                    flex-basis: 10%;
                }
                // &.minwidth {
                //     flex-basis: 50px;
                // }

                // &.minwidth2 {
                //     flex-basis: 70px;
                // }
            }
        }
    }
}

.manager-upload-property-content {
    .card .header-title-details .form-group {
        flex-basis: 8.5%;
    }
    .card .property-config-form .form-details .form-group {
        flex-basis: 8.5%;
    }
}

.manager-schedule {
    .form-details {
        width: 100% !important;
    }
    .appointment-note {
        //flex-basis: calc(37% - 0px) !important;
        //flex: 0 0 auto;
        flex-basis: auto !important;
    }
}

.small-select {
    .select__control {
        height: 28px !important;
        min-height: 28px !important;
    }
    .select__value-container {
        height: 28px !important;
        min-height: 28px !important;
    }
    .select__dropdown-indicator {
        padding: 2px;
    }
    .select__input-container {
        margin: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .select__indicator {
        padding: 0 !important;
    }
    .select__placeholder {
        font-size: 12px;
    }
    .select__single-value {
        color: var(--input-text-color) !important;
        font-size: 12px;
    }
}

.small-input {
    .form-control {
        padding: 1px 7px;
        font-size: 12px;
    }
}


//edit appointment page
.property-config-table {
    &.edit-appointment {
        .content {
            background-color: #b8cce4;
            .accordion {
                //background-color: var(--white-bg);
                background-color: #b8cce4;
            }
            .edit-appt-btns {
                position: sticky;
                bottom: 57px;
                background-color: #b8cce4;
                //border-top:1px solid var(--menu-border-color)
            }
        }
        .minfont {
            .form-details {
                column-gap: 10px;
                .readonly-text {
                    display: block;
                    color: #525151;
                    font-size: 12px;
                    line-height: 1;
                    margin-top: 5px;
                }
                .form-group {
                    flex-basis: calc(11.5% - 0px);
                    &.appt-group {
                        flex-basis: 9%;
                    }
                    &.maxwidth-group {
                        flex-basis: 15%;
                    }
                    &.maxwidth-group2 {
                        flex-basis: 19%;
                    }
                    &.only-checkbox {
                        flex-basis: calc(3% - 0px) !important;
                    }
                    // &.date-time {
                    //     flex-basis: 14%;
                    // }
                }
            }
        }
        .appointment-list-icons {
            span {
                width: 18px;
                height: 18px;
                font-size: 12px;
                margin: 2px 5px 2px 0;
            }
        }
        .card {
            background-color: var(--body-bg-color);
            .card-header {
                //background-color: var(--body-bg-color) !important;
                .accordion-link {
                    padding: 5px !important;
                    //color: var(--text-dark-color) !important;
                    cursor:pointer;
                    font-size: 13px;
                }
            }
        }
        &.edit-apt-back-btn {

        }
    }
   
}

.schedule-property-inspection {
    .content {
        background-color: #b8cce4;
        .accordion {
            //background-color: var(--white-bg);
            background-color: #b8cce4;
        }
        .edit-appt-btns {
            position: sticky;
            bottom: 50px;
            background-color: #b8cce4;
            //border-top:1px solid var(--menu-border-color)
        }
    }
    .property-config-form{
        padding: 5px 10px 5px !important;
        .form-details {
           
            .form-group {
                flex-basis: calc(23.6% - 0px);
            }
        }
        .form-action {
            padding-top: 15px;
        }
    }
    .sub-accordion {
        padding: 5px 10px 5px !important;
        background-color: var(--white-bg);
        border-radius: 4px;
        margin-bottom: 10px;
        .property-config-form {
            padding: 0 !important;
            .form-label {
                font-size: 13px !important;
                color: #000 !important;
            }
        }
        .service-details-row .service-column .td-column {
            margin-top: 2px !important;
        }
    }
}

.property-config-form {
    .select__menu {
        z-index: 1;
    }
}

.property-config-table {
    .gridjs {
        position: relative;
        z-index: 0;
    }
}

.countNew {
    display: inline-flex !important;
    padding: 4px !important;
    font-size: 11px;
    z-index: 9999;
    border-radius: 50px;
    line-height: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    background-color: #dc3545;
    color: #fff;
    font-weight: 400;
    min-width: 18px;
    margin-left: 5px;
}

.schedule-uploaded-list {
 
    .content {
        background-color: #b8cce4;
        .accordion {
            //background-color: var(--white-bg);
            background-color: #b8cce4;
        }
        .edit-appt-btns {
            position: sticky;
            bottom: 50px;
            background-color: #b8cce4;
            //border-top:1px solid var(--menu-border-color)
        }
    }
    .property-config-form{
        padding: 5px 10px 5px !important;
        .checkbox-column {
            width: 2%;
        }
        .form-details {
           width: 88%;
            .form-group {
                flex-basis: calc(11.6% - 0px);
            }
        }
        .appointment-note {
            flex-basis: calc(11.6% - 0px);
        }
        .form-action {
            padding-top: 5px;
            width: 10%;
            display: block;
            position: relative;
            .accept-decline-btn {
                min-width: 88px;
                width: 88px;
                margin-bottom: 5px;
            }
        }
    }
    .sub-accordion {
        padding: 5px 10px 5px !important;
        background-color: var(--white-bg);
        border-radius: 4px;
        margin-bottom: 10px;
        .property-config-form {
            padding: 0 !important;
            .form-label {
                font-size: 13px !important;
                color: #000 !important;
            }
        }
        .service-details-row .service-column .td-column {
            margin-top: 2px !important;
        }
    }
}

.property-config-table .property-config-form .form-details .form-group .select__value-container.select__value-container--is-multi {
    z-index: 0;
    }
    
    .set-recuring-schedule {
        background-color: var(--white-bg);
        padding: 10px;
        border-radius: 4px;
        h5 {
            font-size: 13px;
            text-transform: uppercase;
            margin-bottom: 10px;
        }
    }


    .manager-wo-edit-invoice-list {
       
        .form-details {
            .form-group {
                &.desc-flex {
                    flex: 0 0 30% !important;
                }
                &.date-flex {
                    flex: 0 0 5% !important;
                }
                &.price-flex {
                    flex: 0 0 7% !important;
                }
            }
        }
   
}
 
.flash-bg-color {
    animation-name: flash;
    animation-timing-function: ease;
    animation-duration: 1s;
    animation-iteration-count: 1;
}
 
@keyframes flash {
    from {
      background-color: #ffffae;
    }
  }