:root {
  //--body-bg-color: #eaf3f147;
  --body-bg-color: #f3f3f3;
  --primary-bg-color: #2a72b5;
  --primary-bg-hover: #08457c;
  --primary-bg-border: #2a72b5;
  --primary-border-color: #2a72b5;
  --danger:#f74f75;;
  --primary02: rgba(28, 213, 174, 0.2);
  --primary05: rgba(42, 114, 181, 0.502);
  --primary-transparentcolor: rgba(28, 213, 174, 0.2);
  --transparentprimary-transparentcolor: rgba(255, 255, 255, 0.05);
  --primary-header-bg: #ffffff;
  --sidebar-bg: #ffffff;
  --sidebar-border-color: #ededf5;
  --sidebar-icon-color: #7b8191;
  --sidebar-text-hover-color: #2a72b5;

  --primary-btn-bgcolor: #2a72b5;
  --primary-btn-hover: #08457c;
  --primary-btn-border: #2a72b5;
  --primary-btn-text-color: #fff;

  --primary-btn-ouline-border: #2a72b5;
  --primary-btn-ouline-text: #2a72b5;

  --secondary-btn-bgcolor: #73a9d7;
  --secondary-btn-border: #73a9d7;
  --secondary-btn-text-color: #fff;

  --btn-light-btn-bgcolor: #e6e6e6;
  --btn-light-btn-border: #e6e6e6;
  --btn-light-text-color: #fff;

  --btn-warning-btn-bgcolor: #ffc107;
  --btn-warning-btn-border: #ffc107;
  --btn-warning-text-color: #fff;

  --danger-btn-border-color:#F94045;
  --success-btn-border-color:#198754;

  --header-border-color: #ededf5;
  --header-icon-color: #7b8191;
  --header-dropdown-hover-color: #2a72b5;
  --header-dropdown-bg: #fff;
  --dropdown-title-text: #4a4a69;
  --footer-bg: #ffffff;
  --footer-text: #000000;

  --secondary-bg-color: #73a9d7;
  --secondary-border-color: #73a9d7;

  --primary-text-color: #2a72b5;
  --white-bg: #ffffff;
  --body-text-color: #4a4a69;
  --text-dark-color: #333;
  --dark-text-color: #333;
  --text-grey-color: #7b8191;
  --text-danger: #F94045;
  --text-success: #43AA8C;
  --bs-danger: #f74f75;
  --text-white: #ffffff;
  --page-title-color: #323251;

  --input-text-color: #4d5875;
  --black-text-color: #14112d;
  --input-label-color: #212529;
  --input-border-color: #e3e3e3;
  --form-label-color: #9393b5;
  --form-edit-label-color:#4f4f50;
  --grey-text-color: #a5a0b1;
  --pagination-bg: #f9f9fb;
  --pagination-color: #596882;

  --menu-icon-color: #7b8191;
  --menu-border-color: #ededf5;

  --transparent-body: #2a72b5;
  --transparent-theme: rgba(0, 0, 0, 0.1);

  --anchor-hover-color: #2a72b5;
  --input-bg:#fff;
  --breadcrumb-text-color:#7b8191;
  --breadcrumb-icon-color:#7b8191;
  --breadcrumb-active-text-color:#2a72b5;

  --modal-bg-color:#ececec;
  --modal-header-bg-color:#fff;
  --modal-card-bg:#fff;
  --validation-text-color:#f74f75;

  --red:#dc3545;

  --primary-custom-outline-btn-color:#2a72b5;
  --primary-custom-outline-btn-border:#2a72b5;
  --primary-custom-outline-btn-bg-hover:#2a72b5;
  --primary-custom-outline-btn-color-hover:#ffffff;
  
  --accordion-item-border:#ffffff;
  --accordion-btn-bg-color:#ffffff;
  --accordion-btn-color:#212529;
  --modal-close-btn-color:#212529;
  --modal-header-border-bottom:#dee2e6;

  --readonly:#e4e4e4;
  --input-readonly:#f2f2f2;
  --input-readonly-text:#808080;
  --grey-icon:#7f7f7f;
  --badge-bg:#e2e2e2;
  --white-text-color:#fff;

  --icon-light-green-color: #80C655;
--icon-dark-green-color: #276501;

--success:#38cab3;
--success-text-color:#38cab3;
--success-bg-color:#38cab3;
--success-border-color:#38cab3;

--border-color-c:#ccc;
--disabled:#e9ecef;
--form-control-disabled:  #a3a9b9;

--blue-sky:#776acf;
--blue-sky-border:#776acf;
--blue-sky-text:#776acf;
--note-table-wrapper:#f1f1f1;

--action-btn-color:#2a72b5;
--action-btn-border:#2a72b5;

--td-border-color:#e5e7eb;

--default-color:#2a72b5;
--customize-schedule-staff-table:#e6e6e6;

--primary-link-color:#2a72b5;
--yellow-color:#f7e860;
--pink-color:#ff86e1;
--blue-color:#9487ee;
--table-border-color:#efefef;
--th-color:rgb(99, 115, 129);
--td-color:rgb(116 130 143);

}

[data-theme="dark"] {
  --body-bg-color: #383d52;
  --primary-bg-color: #2a2e3f;
  --primary-bg-hover: #2a2e3f;
  --primary-bg-border: #2a2e3f;
  --primary-border-color: #2a2e3f;
  --primary02: rgba(28, 213, 174, 0.2);
  --primary05: rgba(42, 114, 181, 0.502);
  --primary-transparentcolor: rgba(28, 213, 174, 0.2);
  --transparentprimary-transparentcolor: rgba(255, 255, 255, 0.05);
  --primary-header-bg: #2a2e3f;
  --sidebar-bg: #2a2e3f;
  --sidebar-border-color: #2a2e3f;
  --sidebar-icon-color: #fff;
  --sidebar-text-hover-color: #fff;
  --sidebar-menu-label-color:#7b8191;
  --sidebar-menu-label-color:#fff;
  --primary-btn-bgcolor: #13182a;
  --primary-btn-hover: #38416a;
  --primary-btn-border: #13182a;
  --primary-btn-text-color: #fff;

  // --primary-btn-ouline-border: #fff;
  // --primary-btn-ouline-text: #fff;

  --secondary-btn-bgcolor: #2f353a;
  --secondary-btn-border: #2f353a;
  --secondary-btn-text-color: #fff;

  --header-border-color: #2a2e3f;
  --header-icon-color: #7b8191;
  --header-dropdown-hover-color: #2a72b5;
  --header-dropdown-bg: #fff;
  --dropdown-title-text: #4a4a69;
  --footer-bg: #2a2e3f;
  --footer-text: #fff;

  --secondary-bg-color: #73a9d7;
  --secondary-border-color: #73a9d7;

  --primary-text-color: #fff;
  --white-bg: #2a2e3f;
  --body-text-color: #fff;
  --text-dark-color: #fff;
  --text-grey-color: #7b8191;
  --text-danger: #f74f75;
  --text-success: #43AA8C;
  --bs-danger: #f74f75;
  --text-white: #ffffff;
  --page-title-color: #fff;

  --input-text-color: #fff;
  --black-text-color: #fff;
  --input-label-color: #fff;
  --input-border-color: #fff;
  --form-label-color: #fff;
  --grey-text-color: #fff;
  --pagination-bg: #2a2e3f;
  --pagination-color: #fff;

  --menu-icon-color: #fff;
  --menu-border-color: #fff;

  --transparent-body: #2a72b5;
  --transparent-theme: rgba(0, 0, 0, 0.1);
  --anchor-hover-color: #fff;
  --input-bg:#2a2e3f;
  --breadcrumb-text-color:#fff;
  --breadcrumb-active-text-color:#fff;

--modal-bg-color:#383d52;
  --modal-header-bg-color:#2a2e3f;
  --modal-card-bg:#2a2e3f;
  --modal-header-border-bottom:#53606e;
  
  --primary-custom-outline-btn-color:#2a72b5;
  --primary-custom-outline-btn-border:#2a72b5;
  --primary-custom-outline-btn-bg-hover:#2a72b5;
  --primary-custom-outline-btn-color-hover:#ffff;

    
  --accordion-item-border:#ffffff;
  --accordion-btn-color:#ffffff;
  --accordion-btn-bg-color:#2a2e3f;
  --modal-close-btn-color:#ffffff;

  --input-readonly:#2a2e3f;
  --input-readonly-text:#808080;

  --success:#38cab3;
--success-text-color:#38cab3;
--success-bg-color:#38cab3;
--success-border-color:#38cab3;

--border-color-c:#ccc;
--disabled:#e9ecef;
--form-control-disabled:  #a3a9b9;

--blue-sky:#776acf;
--blue-sky-border:#776acf;
--blue-sky-text:#776acf;
--note-table-wrapper:#2a2e3f;

--action-btn-color:#fff;
--action-btn-border:#fff;

--td-border-color:#40465f;
--customize-schedule-staff-table:#40465f;

--primary-link-color:#fff;
}

[data-theme="light-green"] {
  --primary-bg-color: #38cab3;
  --primary-text-color: #212529;
  --body-bg-color: #eaf3f147;
  --primary-bg-color: #38cab3;
  --primary-bg-hover: #5cd3b9;
  --primary-bg-border: #38cab3;
  --primary-border-color: #38cab3;
  --primary02: rgba(28, 213, 174, 0.2);
  --primary05: rgba(42, 114, 181, 0.502);
  --primary-transparentcolor: rgba(28, 213, 174, 0.2);
  --transparentprimary-transparentcolor: rgba(255, 255, 255, 0.05);


   --primary-btn-bgcolor: #38cab3;
  --primary-btn-hover: #5cd3b9;
  --primary-btn-border: #38cab3;
  --primary-btn-text-color: #ffffff;

  --primary-btn-ouline-border: #38cab3;
  --primary-btn-ouline-text: #38cab3;

    --secondary-btn-bgcolor: #949eb7;
  --secondary-btn-border: #949eb7;
  --secondary-btn-text-color: #ffffff;

  --primary-custom-outline-btn-color:#38cab3;
  --primary-custom-outline-btn-border:#38cab3;
  --primary-custom-outline-btn-bg-hover:#38cab3;
  --primary-custom-outline-btn-color-hover:#ffff;
  --breadcrumb-active-text-color:#38cab3;
  --sidebar-text-hover-color: #38cab3;
  --header-dropdown-hover-color: #38cab3;

  // --primary-header-bg: #38cab3;
  // --header-border-color: #ededf5;
  // --header-icon-color: #ffffff;
  // --header-dropdown-hover-color: #2a72b5;
  // --header-dropdown-bg: #fff;
  // --dropdown-title-text: #4a4a69;
  // --footer-bg: #ffffff;
  // --footer-text: #000000;

  // --sidebar-bg: #38cab3;
  // --sidebar-border-color: #38cab3;
  // --sidebar-icon-color: #fff;
  // --sidebar-text-hover-color: #fff;
  // --sidebar-menu-label-color:#fff;
  // --menu-icon-color: #fff;
  // --menu-border-color: #ededf5;

  --success:#38cab3;
--success-text-color:#38cab3;
--success-bg-color:#38cab3;
--success-border-color:#38cab3;

--border-color-c:#ccc;

--blue-sky:#776acf;
--blue-sky-border:#776acf;
--blue-sky-text:#776acf;

--action-btn-color:#38cab3;
--action-btn-border:#38cab3;

--td-border-color:#e5e7eb;
}

[data-theme="blue"] {
  --primary-bg-color: #776acf;
  --primary-text-color: #212529;
  --body-bg-color: #eaf3f147;
  --primary-bg-color: #776acf;
  --primary-bg-hover: #776acf;
  --primary-bg-border: #776acf;
  --primary-border-color: #776acf;
  --primary02: rgba(28, 213, 174, 0.2);
  --primary05: rgba(42, 114, 181, 0.502);
  --primary-transparentcolor: rgba(28, 213, 174, 0.2);
  --transparentprimary-transparentcolor: rgba(255, 255, 255, 0.05);


   --primary-btn-bgcolor: #776acf;
  --primary-btn-hover: #776acf;
  --primary-btn-border: #776acf;
  --primary-btn-text-color: #ffffff;

  --primary-btn-ouline-border: #776acf;
  --primary-btn-ouline-text: #776acf;

    --secondary-btn-bgcolor: #949eb7;
  --secondary-btn-border: #949eb7;
  --secondary-btn-text-color: #ffffff;

  --primary-custom-outline-btn-color:#776acf;
  --primary-custom-outline-btn-border:#776acf;
  --primary-custom-outline-btn-bg-hover:#776acf;
  --primary-custom-outline-btn-color-hover:#ffff;
  --breadcrumb-active-text-color:#776acf;
  --sidebar-text-hover-color: #776acf;
  --header-dropdown-hover-color: #776acf;
  // --primary-header-bg: #38cab3;
  // --header-border-color: #ededf5;
  // --header-icon-color: #ffffff;
  // --header-dropdown-hover-color: #2a72b5;
  // --header-dropdown-bg: #fff;
  // --dropdown-title-text: #4a4a69;
  // --footer-bg: #ffffff;
  // --footer-text: #000000;

  // --sidebar-bg: #776acf;
  // --sidebar-border-color: #776acf;
  // --sidebar-icon-color: #fff;
  // --sidebar-text-hover-color: #fff;
  // --sidebar-menu-label-color:#fff;
  // --menu-icon-color: #fff;
  // --menu-border-color: #ededf5;

  --success:#38cab3;
--success-text-color:#38cab3;
--success-bg-color:#38cab3;
--success-border-color:#38cab3;

--border-color-c:#ccc;

--blue-sky:#776acf;
--blue-sky-border:#776acf;
--blue-sky-text:#776acf;

--action-btn-color:#776acf;
--action-btn-border:#776acf;
--td-border-color:#e5e7eb;
}


// color variable
$white: #ffffff;
$black: #000000;
$light-black: #100c09;
$blue: #294a7f;
$grey: #8aa5c9;
$dark-grey: #4b4b4b;
$light-grey: #e8ebed;
$spice-grey: #cfcece;
$semy-grey: #bab9b9;
$semy-light-gray: #a6aeb5;

$red: #e54655;
$green: #549227;
$orange: #ee932a;
$yellow: #f2df38;
$yellow-light: #fffacc;

$dark-blue: #223056;
$light-blue: #2d66b6;

$dark-green: #b9e2c6;
$light-green: #f3e3e3;

// bootstrap css overwrites
$primary: $blue;
$success: $green;
$warning: $red;
$dark: $dark-grey;
$danger: $red;

// -----------Theme roots css here-----------
// [class='theme-light'] {

// }

// [class='theme-dark'] {

// }

// [class='theme-primary'] {

// }
